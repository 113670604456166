import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgbCarouselConfig ,NgbModal,NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
import {faCircle, faCaretDown,faNewspaper,faListUl, faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import{faClock,} from '@fortawesome/free-regular-svg-icons';
import { CmsService } from 'src/app/core/services/cms/cms.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from 'src/app/core/services/course/course.service';
import { MasterService } from 'src/app/core/services/master/master.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { UserService } from 'src/app/core/services/user/user.service';
import { LoginComponent } from '../login/login.component';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { fromEvent } from 'rxjs';
import { map, sampleTime } from 'rxjs/operators';
import { FaStackItemSizeDirective } from '@fortawesome/angular-fontawesome';
import { DownloadBrochureComponent } from '../../components/download-brochure/download-brochure.component';
import { ChartComponent } from "ng-apexcharts";
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexDataLabels
} from "ng-apexcharts";
import { resolveSanitizationFn } from '@angular/compiler/src/render3/view/template';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels:   any

};

declare var require: any;
declare var bootbox:any;
@HostListener("window:resize", [])
@HostListener('scroll', ['$event'])


@Component({
  selector: 'app-program-detail',
  templateUrl: './program-detail.component.html',
  styleUrls: ['./program-detail.component.scss'],
  providers: [NgbTabsetConfig,NgbCarouselConfig] 
})
export class ProgramDetailComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  instructors: any=[];
  code;
  status=1;
  page_load=false;
  termOptions: OwlOptions = {
		loop: true,
		autoplay:true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		navSpeed: 700,
		navText: ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
		responsive: {
		  0: {
			items: 1 
		  },
		  400: {
			items: 2
		  },
		  740: {
			items: 4
		  },
		  940: {
			items:4,
			loop:false
		  }
		},
		nav: true
    }
    learnerOptions: OwlOptions = {
      loop: true,
      autoplay:true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      navSpeed: 700,
      navText: ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
      responsive: {
        0: {
        items: 1 
        },
        400: {
        items: 1
        },
        740: {
        items: 2
        },
        940: {
        items:2,
        loop:false
        }
      },
      nav: true
      }
  faCircle = faCircle;
  faClock =  faClock;
  faCalendarAlt=faCalendarAlt;
  faCaretDown = faCaretDown;
  faNewspaper =  faNewspaper;
  faListUl=faListUl ;
  page :String ='program-details';
  program: any;
  model ={
    first_name:'',
    second_name:'',
    phone:'',
    email :'',
    country:'',
    work_experience:''
  };
  slug: any;
  details: any; 
  countries:any = [];
  pdf: any;
  country:'';
  batch_industries:any=[];
  batch_companies:any=[]
  sticky=false;
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  constructor(private cmsService:CmsService, private router:Router,
    private _activatedRoute:ActivatedRoute, private courseService:CourseService,
    private masterService:MasterService,public userService:UserService,
    public modalService:NgbModal,public sanitizer: DomSanitizer,    
		private titleService: Title,
    private metaService: Meta) { 
      this.chartOptions = {
        series: [1],
         labels: ["a"],
        chart: {
          type: "donut"
        },
      
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      };
  }


  onchangeName(){
    this.getCourseDetails();
  }
  reload($event){
    this.getCourseDetails();
  }
  public get height() {
    return window.innerHeight;
  }
  onScroll(event: any) {
    // visible height + pixel scrolled >= total height 
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
    }
}
  public get width() {
    return window.innerWidth;
  }
  goToRefer =()=> {
    this.router.navigate(['/refer&earn']);
    

  };
  goToCheckout(course){
    var _this = this;
    bootbox.confirm({
      message:"<h5>Confirm Payment!</h5><h6>Course Fees:"+course.currency+ ' '+course.fees+"</h6>", 
        buttons: {
          confirm: {
            label: 'Submit',
            className: 'success'
          },
            cancel: {            
              className: 'fail'
          }
      },
      callback:function(result){      
        if(result){
          _this.saveOrder(course)
        }  
      }   
    });
  }

  saveOrder(course){
    console.log("code",this.code)
    this.userService.getUser().subscribe(data=>{
      this.courseService.saveEnrollDetails({
        course_id:course.encrypted_id,
        documents:[],
        user_id:data.profile.id,
        fields:[],
        referral_code :this.code
      }).subscribe(async (resp: any) =>{
        console.log("res",resp) 
        var dialog = bootbox.dialog({
          message:"Thank you for applying to the course.The enrollment will be completed once the admin verifies your request. You will receive an email confirmation once the admin approves your request.",
          closeButton: true
        }); 
        this.getCourseDetails();
      }, (err: any) => {
        console.log(err);
      })
    })
  }
 
  openModal() {
		const modalRef = this.modalService.open(LoginComponent,
		  {
			scrollable: true,
			windowClass: 'myModalClass',
			// keyboard: false,
			// backdrop: 'static'
		  });
	
		let data = {
		  prop1: 'program-details',
		  prop2: this.slug,
		  prop3: 'This Can be anything'
		}
	
		modalRef.componentInstance.fromParent = data;
		modalRef.result.then((result) => {
		}, (reason) => {
		});
	  }
     
  openBrochureModal() {
		const modalRef = this.modalService.open(DownloadBrochureComponent,
		  {
			scrollable: true,
			// windowClass: 'myCustomModalClass',
			// keyboard: false,
			// backdrop: 'static'
		  });
	
		let data = {
		  prop1: 'program-details',
		  prop2: this.slug,
		  details: this.details,
      countries:this.countries
		}
	
		modalRef.componentInstance.fromParent = data;
		modalRef.result.then((result) => {
		}, (reason) => {
		});
	  }
    
  ngOnInit(): void {
  console.log("program=================")
     
    window.addEventListener('scroll', this.scroll, true); //third parameter

    this.getCountries();
    this._activatedRoute.params.subscribe(params => {
      console.log("params--------------------------------",params);      
      this.slug = params.id;
      if(params.code) this.code =params.code;
      console.log("code",this.code)
      this.getCourseDetails();
    })   
  }
  scroll = (event): void => {
    var element = document.querySelector('#banner');
    if(element){
      var position = element.getBoundingClientRect();
      // checking whether fully visible
      if(position.top >= 0 && position.bottom <= window.innerHeight) {
        this.sticky=false;
      }
    
      // checking for partial visibility
      else if(position.top < window.innerHeight && position.bottom >= 0) {
        this.sticky=false;
        document.getElementById("downloading").style.display="none";
      }
      else{
        document.getElementById("downloading").style.display="block";
        this.sticky=true;
    
      }
    } 
    //handle your scroll here
    //notice the 'odd' function assignment to a class field
    //this is used to be able to remove the event listener
  };
  selectYear(event){
    
  }
  getCourseDetails(){
    this.page_load=true;
    this.courseService.programTitle= this.slug;
    this.cmsService.getCourseDetails({slug:this.slug}).subscribe(res=>{   
      this.page_load=false;
      if(res.course){ 
        if(!res.course.is_active ) this.router.navigateByUrl('/program-details/'+res.course.master_slug)
        this.status=1;      
        this.details = res['course'];	
        this.batch_industries =this.details.batch_industries;
        this.batch_companies = this.details.batch_companies;
        let percentage = this.batch_industries.map(res=> res.percentage);
        let industries = this.batch_industries.map(res=> res.title);
        this.chartOptions.series = percentage;
        this.chartOptions.labels =industries
        console.log("perc",percentage,industries)

   
        console.log("certification----------------",this.details);			
        // this.details.duration = this.courseService.getWeken(this.details.start_date,this.details.end_date);
        console.log("course,",this.details)
        //this.courseService.programTitle= this.details.slug;
        this.instructors = this.details.faculties;
        this.titleService.setTitle(res.course.meta_title); // <-- Update the title
  
        this.metaService.updateTag({ 
        name: 'description',
        content: res.course.meta_description
      });
      this.metaService.updateTag({ 
        name: 'title',
        content: res.course.meta_title
      });
      }else  this.status=0;
    })
  }
 
  getCountries(){
		this.masterService.getCountries().subscribe(async (resp: any) =>{     
    this.countries = resp.countries
		}, (err: any) => {
			console.log(err);
		})
  }
  selectCountry(event){
    
    this.country = event.target.value;
  }
  onSubmit(form){

    let data = form.value;
    data.course_id = this.details.id;
    data.country = this.country;
    data.phone = form.value.phone?.e164Number;


  this.courseService.requestBrochure(form.value).subscribe(async (resp: any) =>{
  
    var dialog = bootbox.dialog({
      message: resp.message,
      closeButton: true
    });
    form.resetForm();
    var file = new Blob([resp.data.brochure], { type: 'application/pdf' })
          var fileURL = URL.createObjectURL(file);

    var a = document.createElement("a");
    let fileName = 'brochure';
    a.href = resp.data.brochure;
    a.target='_blank'
    a.download = resp.data.brochure;
    document.body.appendChild(a);
    a.click();
    // window.URL.revokeObjectURL(resp.data.brochure);
    // a.remove();
    this.pdf = resp.data.brochure;
    });		
  }

  public navigateToSection(section: string) {
    console.log("section",section)
    // window.location.hash = '';
    document.querySelector('#'+section).scrollIntoView({ behavior: 'smooth', block: 'end' });
    // window.location.hash = section;
  //     const selector = "#" + section;
  //       const firstElementWithError = document.querySelector(selector);
  //   firstElementWithError.scroll({
  //     top: firstElementWithError.scrollTop - 10, 
  //     left: 0,
  //     behavior: 'smooth'
  // });
    // window.scrollBy(0, 20);
  
    // section.scrollIntoView();
    // section.scrollBy(0, 20);
  

}
format (option) {
  console.log(option);
  if (!option.id) { return option.text; }
  var ob = option.text + '<img src="https://lh4.ggpht.com/wKrDLLmmxjfRG2-E-k5L5BUuHWpCOe4lWRF7oVs1Gzdn5e5yvr8fj-ORTlBF43U47yI=w64" />';	// replace image source with option.img (available in JSON)
  return ob;
};

paymentOption(){
  bootbox.alert("<h5>Payment Options!</h5><h6>Payment Mode:"+this.details.flexible_payment.payment_mode+ ' '+"</h6>"+"<h6>Advance Amount:"+this.details.currency+" " +this.details.flexible_payment.advance_amount+"</h6>", function(result){      
        
  });
}

}
