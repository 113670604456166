<div class="container-delivery mnc-bottom">
        <div class="banner row">
            <div class="col-12">
                <h2>Upcoming Webinar</h2>
                <div class="heading">{{webinar?.title}}</div> 
                
                <span>
                    <a *ngIf="webinar.is_applied"  class="btn join">Applied</a>
                    <a *ngIf="!webinar.is_applied" (click)="enterOtp(slug,'')" class="btn join">Apply Now</a></span>
                <p *ngIf="webinar?.date">{{webinar?.date|date}},{{webinar?.start_time}},{{webinar?.time_zone}}</p>
            </div>
            <div class="col-4">
               
            </div>
            
        </div>
        <div class="addm-col">
            <p [innerHTML]="webinar?.description"></p>
            
        </div>
     
        
            <h4>Agenda for the sessions</h4>
            <div [innerHTML]="webinar?.agenda">
             
            </div>
            <div *ngIf="webinar?.speakers.length">
                <h3 class="text-center learn" *ngIf="webinar.speakers.length===1">About Speaker</h3>
                <h3 class="text-center learn" *ngIf="webinar.speakers.length>1">About Speakers  </h3>
            </div>
        

            <div class="underline"></div>
            <owl-carousel-o [options]="speakerOptions" class="customOwl">
                <ng-template carouselSlide *ngFor="let speaker of webinar?.speakers">



                    <div class="row">
                        <div class="col-md-4">
                            <div class="card">
                                <div class="row detail no-gutters" style="height: 100%;">
                                    <div class="col-sm-4" >
                                        <img [src]="speaker?.image" alt="">
                                        <!-- <div class="col-exp-img exp-img" style="background: url('');">
                                        
                                        </div> -->
                                    </div>
                                    <div class="col-sm-8">
                                        <div class="detail">
                                           <div><b>  {{speaker?.name}}</b></div>
                                           <div>{{speaker?.designation.name}}</div>
                                           <div>{{speaker?.university.name}}</div>
                                           <div>
                                            <a target="_blank" href="{{speaker?.linkedin}}"><img  src="../../../../assets/images/linkedin.png" alt="" class="linkedin-icon"></a>
    
                                           </div>
                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <p [innerHTML]="speaker?.description"></p>
                        </div>
                    </div>
                    </ng-template>
                    </owl-carousel-o>
           
            <h3 class="text-center learn">You May Also Like</h3>
            <div class="underline"></div>
            <owl-carousel-o [options]="termOptions" class="customOwl">
                <ng-template carouselSlide *ngFor="let course of others">
                    <div class="col-md-12 float-left ex-card" style="cursor:pointer">
                        <div class="card pgm-card" routerLink="/webinar-details/{{course.slug}}">
                            <div class="card-image" style="position:relative" (click)="goToThis(course.slug)">
                                <img [src]="course.image" class="card-img-top" alt="">
                            </div>
                            <div class="card-body pgm-body">
                                <h6 class="card-title pgm-tit font-demi"><b>{{course?.title}}</b></h6>
                                <div>
                                    <p class="card-text" >{{course.date| date:'MMMM dd, yyy'}}</p>
                                </div>
      
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
</div>

