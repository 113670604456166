import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { CourseService } from 'src/app/core/services/course/course.service';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-refer-earn',
  templateUrl: './refer-earn.component.html',
  styleUrls: ['./refer-earn.component.scss']
})
export class ReferEarnComponent implements OnInit {
  faChevronDown=faChevronDown;
  faChevronUp=faChevronUp;
  
  selectedCourse: any={
    referal_code:''
  };
  all;
  steps:any=[];
  main:any=[];
  faqs:any=[];
  eligibility_faqs=[];
  payout_faqs:any=[];
    getReferal;
  courses=[];
  course_err: string;
  url="";
  model={
    course:'',
    code:'',
   
  }
id=245;
baseUrl;

  constructor(public  userService:UserService,private router:Router ,private courseService:CourseService) { }

  ngOnInit(): void {
    this.baseUrl=this.router['location']._platformLocation.location.origin;
    console.log('userrrrrrr',this.userService.isLoggedIn())
if(this.userService.isLoggedIn()){
  this.courseService.referAndEarn(this.id).subscribe(async (resp: any) =>{
    this.getReferal = resp;
    this.courses=[...resp.courses,...resp.free_courses]
    console.log("referal",this.getReferal) 
   
  }, (err: any) => {
    console.log(err);
  })
}

    let user =false;
    if (this.userService.isLoggedIn()) user =true;
    this.courseService.referAndEarnContent(user).subscribe(
      async (resp: any) => {
        this.steps = resp.step;
        this.main = resp.main[0];
        this.faqs = resp.faq;
        this.eligibility_faqs=resp.faq.eligibility_faq;
        this.payout_faqs=resp.faq.payout_faq;

        console.log('referal', resp);
      },
      (err: any) => {
        console.log(err);
      }
    );

  }
  onSubmit(form:NgForm) {
    console.log("form",form.value)

  }
  onCourseChange(event){
    this.selectedCourse=this.courses.filter(course=>course.id==event.target.value)[0];
    console.log("event",  this.selectedCourse)
    this.url=this.baseUrl+"/program-details/"+this.selectedCourse.master_slug+'/'+this.selectedCourse.referal_code
    this.course_err='';
  }
      // Facebook share won't work if your shareUrl is localhost:port/abc, it should be genuine deployed url 
      shareOnFacebook(shareUrl: string) {
        console.log("share---",shareUrl)
        shareUrl = encodeURIComponent(shareUrl);
        console.log("shareurl",shareUrl)
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, 'sharer');
      }
    
  
      shareOnTwitter(shareUrl: string) {
        shareUrl = encodeURIComponent(shareUrl);
        // desc = encodeURIComponent(desc);
        window.open(`https://twitter.com/intent/tweet?url=${shareUrl}`, 'sharer');
      }
      shareOnWhatsapp(shareUrl:string){
        // shareUrl = encodeURIComponent(shareUrl);
        console.log("url",shareUrl)
        window.open('https://web.whatsapp.com/send?text='+shareUrl)
      }
    
      shareOnInstagram(shareUrl: string) {
        shareUrl = encodeURIComponent(shareUrl);      
        window.open(`https://www.instagram.com/?url=${shareUrl}`, 'sharer');    
        // window.open(`https://plus.google.com/share?url=${shareUrl}`, 'sharer');
        // window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`, 'sharer');     
  
      }
      shareOnLinkedin(shareUrl: string) {
        shareUrl = encodeURIComponent(shareUrl);
        // window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`, 'sharer');     
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`, 'sharer');     
  
      }


}
