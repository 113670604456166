import { Component,  OnInit  } from '@angular/core';
  import { NgbCarouselConfig ,NgbModal,NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
  import {faRupeeSign,faUsers,faArrowRight, faDollarSign,faChevronRight,faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
  import{faClock,} from '@fortawesome/free-regular-svg-icons';
  import { ActivatedRoute, Router } from '@angular/router';
  import { MasterService } from 'src/app/core/services/master/master.service';
  import * as _ from 'lodash';
  import { CourseService } from 'src/app/core/services/course/course.service';
import * as moment from 'moment-timezone';
import { trigger, state, style, transition, animate, group } from '@angular/animations';
import { LoginComponent } from '../login/login.component';
import { UserService } from 'src/app/core/services/user/user.service';
import { Meta, Title } from '@angular/platform-browser';
declare var bootbox:any;
@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  animations: [
    trigger('flipState', [
      state('active', style({
        transform: 'rotateY(179deg)'
      })),
      state('inactive', style({
        transform: 'rotateY(0)'
      })),
      transition('active => inactive', animate('500ms ease-out')),
      transition('inactive => active', animate('500ms ease-in'))
    ])
  ]
})
export class ScheduleComponent implements OnInit {
 
    faRupeeSign = faRupeeSign;
    faChevronCircleDown = faChevronCircleDown;
    faArrowRight = faArrowRight;
    faClock = faClock;
    faUsers= faUsers;
    faChevronRight= faChevronRight;
    faDollarSign = faDollarSign;
    page :String ='program-list';
    longTerm:any;
    formattedLongTerm: any=[];
    cat:any =[];
    country:any =[];
    allCategories:any = [];
    allCountries:any = [];
    courses = [];
    offsetTmz=[];
    title:String ="All Categories";
    live_online = 0;
    params="";

    constructor(public router: Router,config: NgbCarouselConfig,configure: NgbTabsetConfig,
      private masterService:MasterService,private courseService:CourseService,public modalService:NgbModal,
      private _activatedRoute:ActivatedRoute,private userService:UserService,
      private titleService: Title,
    private metaService: Meta) {
     
      let timeZones = moment.tz.names();      
      for(var i in timeZones)
        {
          this.offsetTmz.push(" (UTC"+moment.tz(timeZones[i]).format('Z')+") " + timeZones[i]);
        }
    }
  
    ngOnInit(): void {
      this._activatedRoute.params.subscribe(params => {
        console.log(params);  
        this.params = params.id;
        if(params.id==='short-term'){
          this.courseService.getShortSchedule({}).subscribe( (res:any)=>{
            console.log("response->shorttt",res)
            this.allCategories = res.all_categories; 
            this.allCountries = res.all_countries;
            let courses = res.data;
            // courses.forEach(ele => {
				
            //   ele.duration = this.courseService.getWeken(ele.start_date,ele.end_date);
            // });
             for (const key in this.courses) {
              this.courses[key] = { ...  this.courses[key], flip: 'inactive'};
                                
             }
             this.masterService.getSeo('scheduledshortcourses').subscribe((resp: any) => { 
              console.log("resp",resp)  
              if(resp.status)    
                this.titleService.setTitle(resp.data.title); // <-- Update the title
            
                this.metaService.updateTag({ 
                name: 'description',
                content: resp.data.meta_description
              });
              this.metaService.updateTag({ 
                name: 'title',
                content: resp.data.meta_title
              });
              }, (err: any) => {
              console.log(err);
              });
             this.courses= this.itemsGroup(courses);
             console.log("group",this.courses) 
          }, (err: any) => {
            console.log(err);
          })
        } else if(params.id==='long-term'){
          this.courseService.getSchedule({}).subscribe( (res:any)=>{
            console.log("response->shorttt",res)
            this.allCategories = res.all_categories; 
            this.allCountries = res.all_countries;
            let courses = res.data;
            // courses.forEach(ele => {
				
            //   ele.duration = this.courseService.getWeken(ele.start_date,ele.end_date);
            // });
             for (const key in this.courses) {
              this.courses[key] = { ...  this.courses[key], flip: 'inactive'};
                                
             }
             this.masterService.getSeo('scheduledcourses').subscribe((resp: any) => { 
              console.log("resp",resp)  
              if(resp.status)    
                this.titleService.setTitle(resp.data.title); // <-- Update the title
            
                this.metaService.updateTag({ 
                name: 'description',
                content: resp.data.meta_description
              });
              this.metaService.updateTag({ 
                name: 'title',
                content: resp.data.meta_title
              });
              }, (err: any) => {
              console.log(err);
              });
             this.courses= this.itemsGroup(courses);
             console.log("group",this.courses) 
          }, (err: any) => {
            console.log(err);
          })
        } else{
          this.courseService.getAllSchedule({}).subscribe(res=>{
            console.log("res",res);
            this.allCategories = res.all_categories; 
            this.allCountries = res.all_countries;
            let courses = res.data;
            // courses.forEach(ele => {
				
            //   ele.duration = this.courseService.getWeken(ele.start_date,ele.end_date);
            // });
            for (const key in this.courses) {
              this.courses[key] = { ...  this.courses[key], flip: 'inactive'};

              // courses[key]['flip']='inactive';             
              
          }
          this.courses= this.itemsGroup(courses);
              console.log("group",this.courses) 
            console.log("res",this.allCategories,this.allCountries)
          })
        }  
       
      })
   

    }
    reload(event){
      window.location.reload();

    }
    saveOrder(course){
      this.userService.getUser().subscribe(data=>{
        console.log("user",data)
        this.courseService.saveEnrollDetails({
          course_id:course.id,
          documents:[],
          user_id:data.profile.id,
          fields:[]     
      
        }).subscribe(async (resp: any) =>{
          console.log("res",resp)  
          var dialog = bootbox.dialog({
            message: "Thank you for applying to the course.The enrollment will be completed once the admin verifies your request. You will receive an email confirmation once the admin approves your request.",
            closeButton: true
          }); 
          window.location.reload();

          // this.getLongTermCourses();
        }, (err: any) => {
          console.log(err);
        })
      })
    }
    chckMonth(start,end){
      if(new Date(start).getMonth()===new Date(end).getMonth())
      return true;
      else return false;
    }
  
    goToCheckout(course){
      console.log("currency",course)
      var _this = this;
      bootbox.confirm({
        message:"<h5>Confirm Payment!</h5><h6>Course Fees:"+course.currency+ ' '+course.price+"</h6>", 
          buttons: {
            confirm: {
              label: 'Submit',
              className: 'success'
            },
              cancel: {            
                className: 'fail'
            }
        },
          callback:function(result){      
          if(result){
            _this.saveOrder(course)
          }  
        }   
      });
    }
    openModal(slug) {
      const modalRef = this.modalService.open(LoginComponent,
        {
        scrollable: true,
        windowClass: 'myModalClass',
        // keyboard: false,
        // backdrop: 'static'
        });
    
      let data = {
        prop1: 'program',
        prop2: slug,
        prop3: 'This Can be anything'
      }
    
      modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
        console.log(result);
      }, (reason) => {
      });
      }
  
    getSchedule(cat,country,live_online){
        if(this.params=='short-term'){
          this.courseService.getShortSchedule({categories:cat,locations:country,live_online:live_online}).subscribe(res=>{
            console.log("res",res);  
            if(res.data){
              let courses = res.data;
              // courses.forEach(ele => {
          
              //   ele.duration = this.courseService.getWeken(ele.start_date,ele.end_date);
              // });
              for (const key in this.courses) {
                this.courses[key] = { ...  this.courses[key], flip: 'inactive'};
              }
              this.courses= this.itemsGroup(courses);
              console.log("group",this.courses) 
            } else this.courses =[];    
   
          })
        }else if(this.params=='long-term'){
 this.courseService.getSchedule({categories:cat,locations:country,live_online:live_online}).subscribe(res=>{
          console.log("res",res);  
          if(res.data){
            let courses = res.data;
            // courses.forEach(ele => {
				
            //   ele.duration = this.courseService.getWeken(ele.start_date,ele.end_date);
            // });
            for (const key in this.courses) {
              this.courses[key] = { ...  this.courses[key], flip: 'inactive'};
              console.log("key",key,this.courses[key]);  
                     
            }
            this.courses= this.itemsGroup(courses);
            console.log("group",this.courses) 
          } else this.courses =[];    
 
        })

        }else{
          this.courseService.getAllSchedule({categories:cat,locations:country,live_online:live_online}).subscribe(res=>{
                   console.log("res",res);  
                   if(res.data){
                     let courses = res.data;
                     // courses.forEach(ele => {
                 
                     //   ele.duration = this.courseService.getWeken(ele.start_date,ele.end_date);
                     // });
                     for (const key in this.courses) {
                      console.log("key",key,this.courses[key]);  

                      this.courses[key] = { ...  this.courses[key], flip: 'inactive'};
                    }
                     this.courses= this.itemsGroup(courses);
                     console.log("group",this.courses) 
                   } else this.courses =[];    
          
                 })
         
                 }
  
       
     
    }
    itemsGroup=(items)=>{

const grouped =items.reduce((accumulator, date) => {
  const parsed = new Date(date.start_date);
  const year = parsed.getFullYear();
  const month = parsed.getMonth();
  const groupKey = `${month},${year}`;
  accumulator[groupKey] = accumulator[groupKey] || {dates: []};
  accumulator[groupKey].dates.push(date);
  return accumulator;
}, {});

const result = Object.entries(grouped).map(([key, dates]) => {
  const parts = key.split(',');
  return {
    month: parts[0],
    year: parts[1],
    dates: dates
  };
});
result.sort(
  function(a, b) {          
     if (a.year === b.year) {
        return b.month > a.month ? -1:1;
     }
     return a.year > b.year ? 1 : -1;
  });
console.log("result---->",result);
return result
    }
    getMonthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber);
    
      // Using the browser's default locale.
      return date.toLocaleString([], { month: 'short' });
    }
    
     itemsGroupedByMonth = (items)=> {
      let monthLabels = ["Jan", "Feb", "March",
      "April", "May", "June",
      "July", "Aug", "Sep",
      "Oct", "Nov", "Dec"];
          let groups = [[], [], [], [], [], [], [], [], [], [], [], [],], 
          itemGroupedByMonths = [];
      if(items){
        for (var i = 0; i < items.length; i++) {
        
          groups[new Date(items[i].start_date).getMonth()].push(items[i]);
        }
        for (var i = 0; i < groups.length; i++) {
          if (groups[i].length) {
            console.log("get",groups[i][0].start_date)
  
            itemGroupedByMonths.push({
              month: monthLabels[i]+' '+new Date(groups[i][0]['start_date']).getFullYear(),
              items: groups[i]
            });
    
          }
        }
      }
    
      return itemGroupedByMonths;
    };
    toggleFlip(course,status) {
      // course.flip = (course.flip == 'inactive') ? 'active' : 'inactive';
      course.flip = status;
    }
    selectCat(event,title){
      this.cat= [];
      console.log("cat",event,title);
      if(event !=="")
      this.cat.push(event)
      this.getSchedule(this.cat,this.country,this.live_online)
  
    }
    selectCountry(event,title){
      this.country = [];
      console.log("cat",event.target.value,title);
      if(event.target.value!=="")
      this.country.push(event.target.value)
      this.getSchedule(this.cat,this.country,this.live_online)
    }
    setLive(){
      console.log("online",this.live_online);
      if(this.live_online===1)
      this.live_online = 0;
      else this.live_online=1;
      this.getSchedule(this.cat,this.country,this.live_online)
    }
  }