import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MasterService } from 'src/app/core/services/master/master.service';
import { NgbAccordion, NgbCarouselConfig ,NgbModal,NgbPanelChangeEvent,NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  providers: [NgbCarouselConfig,NgbTabsetConfig]
})
export class BlogComponent implements OnInit {

  _id: any;
  status=0;
  all:any=[];
  trending:any=[];
   list:any;
   termOptions: OwlOptions = {
    loop: true,
    autoplay:true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
    responsive: {
      0: {
      items: 1
      },
      400: {
      items: 4
      },
      740: {
      items: 4
      },
      940: {
      items: 4,
      loop:false
      }
    },
    nav: true
    }
    blogOptions: OwlOptions = {
      loop: true,
      autoplay:true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      navSpeed: 700,
      navText: ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
      responsive: {
        0: {
        items: 1
        },
        400: {
        items: 3
        },
        740: {
        items: 3
        },
        940: {
        items: 3,
        loop:false
        }
      },
      nav: true
      }
    exploreOptions: OwlOptions = {
      loop: false,
      autoplay:true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      navSpeed: 700,
      navText: ['', ''],
      items:1,

      responsive: {
        0: {
          items: 1
        },
        400: {
        items: 1
        },

      },
      nav: true
      }
  constructor(private _activatedRoute:ActivatedRoute, private masterService:MasterService,
    config: NgbCarouselConfig,configure: NgbTabsetConfig,) {
      configure.justify = 'center';
      configure.type = 'pills';
    }

  ngOnInit(): void {

          this.masterService.getBlogAll().subscribe(res=>{
            console.log("response",res)
            this.all =res.blogs;
            this.trending=res.trending_blogs;

            this.list = _.groupBy(res.blogs, function (list) {
                return list.category;
            });
            console.log("details",this.list);
     })


    }
}
