import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterService } from 'src/app/core/services/master/master.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { environment } from 'src/environments/environment';
import { LoginComponent } from '../login/login.component';
declare var bootbox:any;
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { formatCurrency } from '@angular/common';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {


  @Input() fromParent;
  agree=false;
  loading:any=false;
  @Input('page') page: string;
  register="login"
  // showModal :boolean;
  serverErrorMessages: string;
  countries:any = [];
  country='';
  user:any;
  country_err='Select a Country';
  phone_err='';
  login:boolean=false;
  email_err='';
  month='';
  years:any =[];
  year='';
  dataModel:any;
  faTimesCircle=faTimesCircle;
  months = [
  {id:1,name:"January"},
  {id:2,name:"February"},
  {id:3,name:"March"},
  {id:4,name:"April"},
  {id:5,name:"May"},
  {id:6,name:"June"},
  {id:7,name:"July"},
  {id:8,name:"August"},
  {id:9,name:"September"},
  {id:10,name:"October"},
  {id:11,name:"November"},
  {id:12,name:"December"}]
  exampleData: ({ id: string; text: string; disabled?: undefined; } | { id: string; disabled: boolean; text: string; })[];
  user_id: any;
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public router: Router,public userService:UserService, public masterService:MasterService,
    
  ) { 
    console.log("country",this.country)
    this.getCountries();  

  }

  ngOnInit(): void {
    console.log("user",this.userService.selectedUser.country,this.country_err)
    if(!this.userService.selectedUser.country)
    this.country_err='Select a Country';
    var d = new Date( "01 " + "Jan 1980");
    let first = d.getFullYear();

    var s = new Date();
    let second = s.getFullYear();

   for(let i = first; i <= second; i++) this.years.push(i);

  }

  config = {
    displayFn:(item: any) => { return item.name; } ,//to support flexible text displaying for each item
    displayKey:"name", //if objects array passed which key to be displayed defaults to description
    search:true ,//true/false for the search functionlity defaults to false,
    height: '150px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder:'Select Country', // text to be displayed when no item is selected defaults to Select,
    customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo:0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
    moreText: 'more' ,// text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!' ,// text to be displayed when no items are found while searching
    searchPlaceholder:'Search' ,// label thats displayed in search input,
    searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: false ,// clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
  }
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];


	changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Canada];
	}
  goToLogin =()=> {
    // console.log("login",this.login,this.showModal)
    // this.showModal = false;
    // this.login =true;
 
  }

  onChange($event) {
    this.agree = $event.target.checked;
  }
  getCountries(){
		this.masterService.getCountries().subscribe(async (resp: any) =>{
     console.log("resp",resp);
     this.countries = resp.countries
		}, (err: any) => {
			console.log(err);
		})
  }

  selectCountry(event){
    console.log("country",event.value,this.userService.selectedUser.country)
    this.country = event.value.id;
    if(event.value.name!=='')
    this.country_err = '';
  }
  selectMonth(event){
    this.month = event.target.value;
  }
  selectYear(event){
    this.year = event.target.value;
  }
  goToPolicy(form:NgForm){
    // this.router.navigate(['/academic-policies'])
    this.router.navigate([]).then(result => {  window.open('/terms-of-service', '_blank'); });

  }

  onSubmit(form: NgForm) {
    console.log("form--->",form); 
    if(form.valid && this.country && this.agree){
      this.loading=true;
      this.email_err = '';
      this.phone_err='';     
      this.serverErrorMessages = '';
      let data = form.value;
      data.phone = form.value.phone?.e164Number;
      form.value.country = data.country;
      data.country = this.country;
      console.log("formdata------>",data)
      if(data.country===''){
          this.country_err ="Select a Country";
          let el = document.getElementById('country');
          el.scrollIntoView();     
        }else{
          this.country_err =''
          data.month_graduation = this.month;
          data.year_graduation=this.year;
          this.user = data;
          this.userService.register(form.value).subscribe(
            res => {
            this.loading=false;
              console.log("res",res)
              this.user_id = res.user_id,
              form.resetForm();
              let that = this;
              this.closeModal("dismiss")
              this.enterOtp(form,"")
            },
            err => {
              this.loading=false;
              console.log("err",err.error.errors)            
              if (err.status === 422) {
                if(err.error.errors.email )
                this.email_err = err.error.errors.email[0];
                if(err.error.errors.phone)              
                this.phone_err = err.error.errors.phone[0];
                this.serverErrorMessages ="Registration error!"
                let el = document.getElementsByClassName('email');
                  el[0].scrollIntoView();     
              }  if (err.status === 500) {
                this.serverErrorMessages ="Connection error!"
            }
          }
          );
        }
    }

  }
  enterOtp(form,err){
    let that = this;
    bootbox.prompt({
      title: "Enter Verification Code", 
      message:err?err:'Please check your email for the verification code!',
      centerVertical: true,
      callback: function(result){ 
        if(result){
          console.log(result); 
          that.userService.verifyOtp({
            otp:result,
            user_id:that.user_id
          }).subscribe(
            res => {
              console.log("res--->",res)
              if(res.status==1){  
                that.loginSubmit(form)              
                var dialog = bootbox.dialog({
                  message: "Success!!",
                  closeButton: true
                });               
              }else if(res.status==2){                
                var dialog = bootbox.dialog({
                  className: 'block',
                  message: "You have exceeded the limit of verification code tries. Please contact Admin !!",
                  closeButton: true
                });     
              }else
                that.enterOtp(form,"<span style='color: red;'>Verification failed. Try again!</span>");
            },
            err => {
              console.log("err", err)
            });
   
        }else{

          bootbox.hideAll();
            
          if(result!==null)
          that.enterOtp(form,"<span style='color: red;'>Enter valid verification code. Try again!</span>");
          return false;
          }
   
         
      }
  });
  }


  loginSubmit(form) {
    console.log(form.value)
    this.userService.login({
      grant_type: 'password',
      client_id: environment.appClientId,
      client_secret: environment.appClientSecret,
      username: this.user.email,
      password: this.user.password,
      scope: '*'
    }).subscribe(
      res => {     
        this.userService.setToken(res.token); 
        this.activeModal.close('dismiss');
       
     
   
      if(this.fromParent.prop1==='home'){
        window.location.href = '/program-details/'+this.fromParent.prop2;
      }
      else if(this.fromParent.prop1==='program-details'){
        window.location.href = '/program-details/'+this.fromParent.prop2;
      }
      else if(this.fromParent.prop1==='program' ){  
        window.location.href = '/program-details/'+this.fromParent.prop2;
      }
      else{               
        this.router.navigate(['/dashboard']);    
      }
       
        // this.router.navigate(['/dashboard']);
      },
      err => {
        console.log("err", err)
      }
    );
  } 
  closingModal(sendData,form){
    form.resetForm(); 
    this.closeModal(sendData);
   }
  closeModal(sendData) {
    console.log("send",sendData)
    this.activeModal.close(sendData);
    
  }
  openModal() {
    this.closeModal('dismiss')
    const modalRef = this.modalService.open(LoginComponent,
      {
        scrollable: true,
        windowClass: 'myCustomModalClass',
        // keyboard: false,


      });
     

    let data = {
      prop1: 'Some Data',
      
      prop2: 'From Parent Component',
      prop3: 'This Can be anything'
    }
    
    if(this.fromParent.prop1==='course' ||this.fromParent.prop1==='program-details'|| this.fromParent.prop1==='program'|| this.fromParent.prop1==='home'){
      data.prop1=this.fromParent.prop1;
      data.prop2 = this.fromParent.prop2;
    }

    modalRef.componentInstance.fromParent = data;
    
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
  }
}
