import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { CourseService } from 'src/app/core/services/course/course.service';
import { MasterService } from 'src/app/core/services/master/master.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { NavComponent } from 'src/app/core/nav/nav.component';
import { UtilService } from 'src/app/core/services/util/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/internal/operators/map';
declare var bootbox;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild('NavComponent', {static : false}) navbar: NavComponent;

  user:any;
  profile_pic:any;
  image:any;
  orders:any;
  url:any;
  gender;
  points:any=0;
  address = [];
  country;
  edit_profile;
  years=[];
  countries;
  point_details:any;
  approved_courses = [];
  applied_courses = [];
  approved_webinar =[];
  approved_bootcamp =[];
  months = [
    {id:1,name:"January"},
    {id:2,name:"February"},
    {id:3,name:"March"},
    {id:4,name:"April"},
    {id:5,name:"May"},
    {id:6,name:"June"},
    {id:7,name:"July"},
    {id:8,name:"August"},
    {id:9,name:"September"},{id:10,name:"October"},{id:11,name:"November"},{id:12,name:"December"}];
  faTimesCircle = faTimesCircle;
  includedFields: any;
  now: Date = new Date();
minDate: any;
maxDate: any;
course_count:any;

//If you want to disable past dates from current date, you can set mindate to current date.

  constructor(private userService:UserService,private courseService:CourseService,
    private masterService:MasterService,public utilService:UtilService,
    public route:ActivatedRoute
    ) {
      this.maxDate = { year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate() };
      let currentYear: number = this.now.getFullYear();
       this.minDate = new Date(currentYear-100);
      console.log("max",this.minDate)
     }

  ngOnInit(): void {
    // console.log('value received ', this.utilService.edit_profile);
    this.route.queryParams.subscribe((data) => {
      if (data && data['refresh']) {      
          console.log('here can start your work and call init method');
      }
    });   
    this.getCountries();
    this.getUser();
  

  }
  goToLink(course){
    window.location.href=course.link;
  }
  selectMonth(event){
    this.user.month_graduation = event.target.value; 
  }
  getUser(){

    this.userService.getUser().subscribe(data=>{
      console.log(data)
      this.course_count= data.course_count;
      this.country = data.profile?.country_detail.short_name;
      this.user = data.profile;
      this.points=data.user_credit_points.creditpoint_total
      this.point_details = data.user_credit_points.creditpoint_details;
      if(this.user.address)
      this.address = this.user.address.split(','); 
      this.applied_courses = data.applied_courese;
      this.approved_courses = data.approved_courses;
      this.approved_bootcamp=data.applied_bootcamp;
      this.approved_webinar=data.applied_webinar;

    
    
      if(this.user.gender==0)        
        this.gender = "Male";      
      else if(this.user.gender==1 )
        this.gender ="Female";
      else this.gender =""
      
      for (let key in this.user) {
        if(this.user[key]=='null'){
         this.user[key] ='';
        }
      }
  

      this.courseService.getOrders(this.user.id_encrypted).subscribe(data=>{
        console.log("orders",data)
     })
    })  
  
  }
  edit(){
   this.edit_profile = !this.edit_profile;
    var d = new Date( "01 " + "Jan 1980");
    let first = d.getFullYear();

    var s = new Date();
    let second = s.getFullYear();

   for(let i = first; i <= second; i++) this.years.push(i);
  }
  selectCountry(event){
    console.log("country",event.target.value)
    this.user.country = event.target.value;
  }
 
  
  selectYear(event){
    this.user.year_graduation = event.target.value;
  }
  getCountries(){
		this.masterService.getCountries().subscribe(async (resp: any) =>{
    console.log("resp",resp);
    this.countries = resp.countries;
		}, (err: any) => {
			console.log(err);
		})
  }

  onImageChange(event){
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.image= file;
     
      console.log("file",this.user)
      const reader = new FileReader();
      reader.onload = e => this.profile_pic = reader.result as string;
      reader.readAsDataURL(file); 
      reader.onload = (_event) => { 
        this.url = reader.result; 
        this.user.image = reader.result;
    }   
    //   this.userService.changeImage(this.image).subscribe(
    //   res => {
    //     console.log("res",res)
  
    // })
  }
  }
  cancel(){
   this.edit_profile=false;
  }
  validateInput(event){
    return event.keyCode !== 69 && event.keyCode !== 189;
  } 
  onSubmit(form:NgForm){
    console.log("values",form.value);
    console.log("user",this.user)


    this.userService.updateUser(this.user,this.image).subscribe(data=>{
      console.log("data",data)
      var dialog = bootbox.dialog({
        message: "User Profile Updated Successfully",
        closeButton: true,
        onEscape: function() {
          location.href = location.href;
          // you can do anything here you want when the user dismisses dialog
      }
      });
      this.getUser();
      let el = document.getElementsByClassName('container-delivery');
      el[0].scrollIntoView();    
     
     this.edit_profile = !this.edit_profile;    
    })
  }
 



}
