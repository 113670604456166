<div class="container jumbotron text-center" style="font-size:14" *ngIf="!status">
    <span>This Course is not yet published!!</span>

    <div style="cursor:pointer" routerLink="/" class="backome">Home </div>

</div>
<div class="status" *ngIf="status">
    <div class="row banner" id="banner">
        <!-- <div class="col-md-1"></div>
        <div class="col-md-10"> -->
        <app-banner [page]="page" (childBanner)="reload($event)" [course]="details" [slug]="slug"></app-banner>
        <app-course-apply (childEvent)="reload($event)" #apply></app-course-apply>
        <app-course-package #package [course]="details"></app-course-package>
        <app-flexible-payment #flexible [course]="details"></app-flexible-payment>
        <app-instructor #instruct [course]="details"></app-instructor>
        <!-- </div>
        <div class="col-md-1"></div> -->
    </div>
    <div class="row text-center pgm-det-col" id="pgm-det-col" [ngClass]="{'sticky' : sticky}" style="position: sticky ;background:#fff;top: 0;z-index: 999;">
        <div class="container-delivery tabdet" style="margin:20px 0px">
            <ul style=" margin-top: 15px;cursor: pointer;padding:0px">
                <li style="display:inline-block;padding: 0px 3px; " *ngIf="details?.university_data">
                    <img src="{{details?.university_data?.logo}}" style="width: 60px;" alt="{{details?.university_data?.logo_alt}}">
                </li>
                <li style="display:inline-block;padding: 0px 3px;">
                    <a fragment="overview" (click)="navigateToSection('overview')" style="text-decoration:none;">Program Overview</a>
                </li>
                <li style="display:inline-block;padding: 0px 3px;" *ngIf="details?.learning_path_description||details?.learning_paths.length">
                    <a fragment="path" (click)="navigateToSection('path')" style="text-decoration:none;">Learning Path</a>
                </li>
                <li style="display:inline-block;padding: 0px 3px;"  *ngIf="details?.skills.length">
                    <a fragment="skills" (click)="navigateToSection('skills')" style="text-decoration:none;">Skills Covered</a>
                </li>
                <li style="display:inline-block;padding: 0px 3px;" *ngIf="details?.tools.length">
                    <a fragment="tools" (click)="navigateToSection('tools')" style="text-decoration:none;">Tools Covered</a>
                </li>
                <li style="display:inline-block;padding: 0px 3px;"   *ngIf="details?.industry_projects.length">
                    <a fragment="industry" (click)="navigateToSection('industry')" style="text-decoration:none;">Industry Projects</a>
                </li>
                <li style="display:inline-block;padding: 0px 3px;"   *ngIf="details?.industry_projects.length">
                    <a fragment="capstone" (click)="navigateToSection('capstone')" style="text-decoration:none;">Capstone Projects</a>
                </li>
                <!-- <li style="display:inline-block;padding: 0px 3px;" *ngIf="instructors.length">
                    <a fragment="instructor" (click)="navigateToSection('instructor')" style="text-decoration:none;">Instructors</a>
                </li> -->
                <li style="display:inline-block;padding: 0px 3px;" *ngIf="details?.learning_experiences.length">
                    <a fragment="experience" (click)="navigateToSection('experience')" style="text-decoration:none;">Learning Experience</a>
                </li>
                <li style="display:inline-block;padding: 0px 3px;" *ngIf="details?.admission_steps.length">
                    <a fragment="admission" (click)="navigateToSection('admission')" style="text-decoration:none;">Admission & Fees</a>
                </li>
                <li style="display:inline-block;padding: 0px 3px;" *ngIf="details?.learners_review.length">
                    <a fragment="reviews" (click)="navigateToSection('reviews')" style="text-decoration:none;">Learners Review</a>
                </li>
                <li style="display:inline-block;padding: 0px 3px;" *ngIf="details?.related_articles.length">
                    <a fragment="articles" (click)="navigateToSection('articles')" style="text-decoration:none;">Related Articles</a>
                </li>
                <li style="display:inline-block;padding: 0px 3px;" *ngIf="details?.faqs.length">
                    <a fragment="faq" (click)="navigateToSection('faq')" style="text-decoration:none;">FAQs</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="col-md-12 brochure" id="overview" #overview>
        <div class="container-delivery">
            <div class="row" style="margin:0px">
                <div class="col-lg-7 col-md-6 dey-brc-col">
                    <h4>Program Overview</h4>
                    <div class="underline--"></div>
                    <p>{{details?.overview}}</p>
                </div>
                <div class="col-lg-5 col-md-6 dey-brc-col none767" *ngIf="width> 767">
                    <div class="card-sec sec-padd">
                        <div class="card-body">
                            <!-- <form class="form" #courseForm="ngForm" (ngSubmit)="courseForm.form.valid && onSubmit(courseForm)" novalidate>
                                <h4 class="card-title"><b>Get Your Brochure</b></h4>
                                <div class="row">
                                    <div class="col-md-6 ">
                                        <input type="text" class=" text" [(ngModel)]="model.first_name" name="first_name" #first_name="ngModel" placeholder="First Name" pattern="[a-zA-Z\s]*$" required (change)="model.first_name = model.first_name.trim()">
                                        <span class="text-danger" *ngIf="(first_name.touched || courseForm.submitted) && first_name.errors?.required">
                                        First Name is required
                                    </span>
                                        <span class="text-danger" *ngIf="(first_name.touched || courseForm.submitted) && first_name.errors?.pattern">
                                    Only Alphabets are allowed
                                </span>
                                    </div>
                                    <div class=" col-md-6 ">
                                        <input type="text" class=" text" [(ngModel)]="model.second_name" name="second_name" #second_name="ngModel" placeholder="Second Name" pattern="[a-zA-Z\s]*$" required (change)="model.second_name = model.second_name.trim()">
                                        <span class="text-danger" *ngIf="(second_name.touched || courseForm.submitted) && second_name.errors?.required">
                                        Second Name is required
                                    </span>
                                        <span class="text-danger" *ngIf="(second_name.touched || courseForm.submitted) && second_name.errors?.pattern">
                                    Only Alphabets are allowed
                                </span>
                                    </div>
                                    <div class=" col-md-12 ">
                                        <input type="text" class=" text" [(ngModel)]="model.email" name="email" #email="ngModel" placeholder="Email" required email (change)="model.email = model.email.trim()">
                                        <span class="text-danger" *ngIf="(email.touched || courseForm.submitted) && email.errors?.required">
                                        Email is required
                                    </span>
                                        <span class="text-danger" *ngIf="email.touched && email.errors?.email">
                                        Enter a valid email address
                                    </span>
                                    </div>
                                    <div class=" col-md-12 " style="margin: 2px 0px;">
                                        <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                            [selectedCountryISO]="CountryISO.India" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National" name="phone" #phone="ngModel" required [(ngModel)]="model.phone">
                                        </ngx-intl-tel-input>
                                        <span class="text-danger" *ngIf="(phone.touched || courseForm.submitted) && phone.errors?.required">
                                        Phone is required
                                    </span>
                                    <span class="text-danger" *ngIf="(phone.touched || courseForm.submitted) &&!phone.errors?.required &&  phone.errors?.validatePhoneNumber?.valid===false">
                                                Invalid Phone number
                                    </span>
                                    </div>
                                    <div class=" col-md-12 ">
                                        <select class="text" name="Country" style="background-color: #fff;" [(ngModel)]="model.country" #country (change)=selectCountry($event)>
                                        <option value="" disabled selected> Country</option>
                                        <option *ngFor="let country of countries" [value]="country.name">
                                        <div>
                                            <img [src]="country.image" alt="{{country?.image_alt}}"> {{country.name}}
                                        </div>
                                        </option>
                                    </select>
                                    </div>
                                    <div class=" col-md-12 ">
                                        <select class="text" name="work_experience" style="background-color: #fff;" [(ngModel)]="model.work_experience" #work_experience (change)=selectYear($event)>
                                    <option value="" disabled selected> Work Experience</option>
                                    <option value="0-1 year">0-1 Year </option>
                                    <option value="2 years">2 Years </option>
                                    <option value="3 years">3 Years </option>
                                    <option value="4 years">4 Years </option>
                                    <option value="5 years">5+ Years </option>
                                </select>

                                    </div>
                                </div>
                                <div>
                                    <b>
                                    <p class="terms">By Clicking the button below, you agree to receive communications from
                                        Edubex about this program and other relevant programs. Privacy Policy
                                        Edubex's Terms
                                    </p>
                                </b>
                                    <div>
                                        <input type="button" (click)="openBrochureModal()" class="btn download" value="Download Brochure">
                                    </div>
                                    <div>
                                        <input type="button" class="btn apply" *ngIf="userService.isLoggedIn() && !details?.is_applied && (details?.documents.length||details?.formfields.length)" (click)="apply.show(details.documents,details.formfields,details?.encrypted_id)" value="Apply Now">
                                        <input type="button" class="btn apply" *ngIf="userService.isLoggedIn() && !details?.is_applied && !details?.formfields.length  &&  !details?.documents.length " (click)="goToCheckout(details)" value="Apply Now">
                                        <input type="button" class="btn apply" disabled *ngIf="userService.isLoggedIn() && details?.is_applied" value="Applied">
                                        <input type="button" class="btn apply" *ngIf="!userService.isLoggedIn()" (click)="openModal()" value="Apply Now">
                                    </div>
                                </div>
                            </form> -->
                            <div>
                                <input type="button" (click)="openBrochureModal()" class="btn download edu-br download-br" value="Download Brochure">
                            </div>
                            <div>
                                <input type="button" class="btn apply edu-br" *ngIf="userService.isLoggedIn() && !details?.is_applied && (details?.documents.length||details?.formfields.length)" (click)="apply.show(details.documents,details.formfields,details?.encrypted_id,code)" value="Apply Now">
                                <input type="button" class="btn apply edu-br" *ngIf="userService.isLoggedIn() && !details?.is_applied && !details?.formfields.length  &&  !details?.documents.length " (click)="goToCheckout(details)" value="Apply Now">
                                <input type="button" class="btn apply edu-br" disabled *ngIf="userService.isLoggedIn() && details?.is_applied" value="Applied">
                                <input type="button" class="btn apply edu-br" *ngIf="!userService.isLoggedIn()" (click)="openModal()" value="Apply Now">
                            </div>
                            <div class="side-box" (click)="goToRefer()">
                                <img src="../../../../assets/gift.png" style="width: 100%;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-fix-pack dis767" style="display:none">
                    <input type="button" class="btn download edu-br" id="downloading" style="padding: 6px;" (click)="package.show('hai')" value="View Course Package">
                </div>
                <div class="panel-group dis767" *ngIf="width <= 767" id="accordion" style="display: none;width:100%">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <h4 class="panel-title btn" style="margin: 20px 0px 0px!important;padding: 0px;color:#fff;width: 100%;">
                                <div data-toggle="collapse" data-parent="#accordion" data-target="#brochure" aria-expanded="false" aria-controls="collapseOne" style="text-align:left">
                                    <span class="btn download" style="margin: 0px!important;font-size: smaller !important;">Click Here To Get Your Brochure</span>
                                </div>
                            </h4>
                        </div>

                        <div id="brochure" class="panel-collapse collapse in">
                            <div class="panel-body">

                                <div class="card-body" style="border: 1px solid #ccc;border-radius: 6px;padding:10px 10px!important;">
                                    <form class="form" #courseForm="ngForm" (ngSubmit)="courseForm.form.valid && onSubmit(courseForm)" novalidate>
                                        <div class="row">
                                            <div class="col-md-6 ">
                                                <input type="text" class=" text" [(ngModel)]="model.first_name" name="first_name" #first_name="ngModel" (change)="model.first_name = model.first_name.trim()" placeholder="First Name" pattern="[a-zA-Z\s]*$" required>
                                                <span class="text-danger" *ngIf="(first_name.touched || courseForm.submitted) && first_name.errors?.required">
                                            First Name is required
                                        </span>
                                                <span class="text-danger" *ngIf="(first_name.touched || courseForm.submitted) && first_name.errors?.pattern">
                                        Only Alphabets are allowed
                                    </span>
                                            </div>
                                            <div class=" col-md-6 ">
                                                <input type="text" class=" text" [(ngModel)]="model.second_name" name="second_name" #second_name="ngModel" (change)="model.second_name = model.second_name.trim()" placeholder="Second Name" pattern="[a-zA-Z\s]*$" required>
                                                <span class="text-danger" *ngIf="(second_name.touched || courseForm.submitted) && second_name.errors?.required">
                                            Second Name is required
                                        </span>
                                                <span class="text-danger" *ngIf="(second_name.touched || courseForm.submitted) && second_name.errors?.pattern">
                                        Only Alphabets are allowed
                                    </span>
                                            </div>
                                            <div class=" col-md-12 ">
                                                <input type="text" class=" text" [(ngModel)]="model.email" name="email" #email="ngModel" placeholder="Email" required email (change)="model.email = model.email.trim()">
                                                <span class="text-danger" *ngIf="(email.touched || courseForm.submitted) && email.errors?.required">
                                            Email is required
                                        </span>
                                                <span class="text-danger" *ngIf="email.touched && email.errors?.email">
                                            Enter a valid email address
                                        </span>
                                            </div>
                                            <div class=" col-md-12 " style="margin: 2px 0px;">
                                                <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                                    [selectedCountryISO]="CountryISO.India" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National" name="phone" #phone="ngModel" required
                                                    [(ngModel)]="model.phone">
                                                </ngx-intl-tel-input>

                                                <span class="text-danger" *ngIf="(phone.touched || courseForm.submitted) && phone.errors?.required">
                                            Phone is required
                                        </span>

                                                <span class="text-danger" *ngIf="(phone.touched || courseForm.submitted) &&!phone.errors?.required &&  phone.errors?.validatePhoneNumber?.valid===false">
                                                    Invalid Phone number
                                        </span>
                                            </div>
                                            <div class=" col-md-12 ">
                                                <select class="text" name="Country" style="background-color: #fff;" [(ngModel)]="model.country" #country (change)=selectCountry($event)>
                                            <option value="" disabled selected> Country</option>
                                            <option *ngFor="let country of countries" [value]="country.name">
                                            <div>
                                                <img [src]="country.image" alt="{{country?.image_alt}}"> {{country.name}}
                                            </div>
                                            </option>
                                            </select>
                                            </div>
                                            <div class=" col-md-12 ">
                                                <select class="text" name="work_experience" style="background-color: #fff;" [(ngModel)]="model.work_experience" #work_experience (change)=selectYear($event)>
                                                    <option value="" disabled selected> Work Experience</option>
                                                    <option value="0-1 year">0-1 Year </option>
                                                    <option value="2 years">2 Years </option>
                                                    <option value="3 years">3 Years </option>
                                                    <option value="4 years">4 Years </option>
                                                    <option value="5 years">5+ Years </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div>
                                            <b>
                                                <p class="terms">By Clicking the button below, you agree to receive communications from
                                                    Edubex about this program and other relevant programs. Privacy Policy
                                                    Edubex's Terms
                                                </p>
                                            </b>
                                            <div>
                                                <input type="submit" class="btn download udu-br" value="Download Brochure">
                                            </div>
                                            <div>
                                                <input type="button edu-br" class="btn apply" *ngIf="userService.isLoggedIn() && !details?.is_applied && (details?.documents.length||details?.formfields.length)" (click)="apply.show(details.documents,details.formfields,details?.encrypted_id)" value="Apply Now">
                                                <input type="button edu-br" class="btn apply" *ngIf="userService.isLoggedIn() && !details?.is_applied && !details?.formfields.length  &&  !details?.documents.length " (click)="goToCheckout(details)" value="Apply Now">
                                                <input type="button edu-br" class="btn apply" disabled *ngIf="userService.isLoggedIn() && details?.is_applied" value="Applied">
                                                <input type="button edu-br" class="btn apply" *ngIf="!userService.isLoggedIn()" (click)="openModal()" value="Apply Now">
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-delivery starts-col">
        <div class="row time edu-br">
            <div class="col-md-4" style="border-right:1px solid #d6ebdbb5">
                <h4 class="font-demi">Starts On </h4>
                <h5 style="margin:0px"> {{details?.start_date| date:'MMMM dd, yyy'}}</h5>

            </div>
            <div class="col-md-4" style="border-right:1px solid #d6ebdbb5">
                <h4 class="font-demi">Duration</h4>
                <h5 style="margin:0px">{{details?.duration}} <span *ngIf="details?.is_online==='1' || details?.is_online==='2'"> Online</span></h5>
                <!-- <div>(8-10 hours per week)</div> -->
            </div>
            <div class="col-md-4">
                <h4 class="font-demi">Course Fee</h4>
                <h5 style="margin:0px;"> {{details?.currency}} {{details?.fees}}</h5>
                <div class="flexi-pay">
                    <i class="fa fa-info-circle" style="margin-right:5px" style="cursor:pointer" (click)="flexible.show('new')"></i> <i><u  style="cursor:pointer" (click)="flexible.show('new')">Flexible Payment Available</u></i>
                </div>

            </div>
            <!-- <div class="col-md-3" style="border:0px" >
            <h4>Eligibility</h4>
            <h5 style="margin:0px">{{details?.eligibility}}</h5>

        </div> -->
            <!-- (click)="paymentOption()" -->
        </div>
    </div>
    <div class="container-delivery learn-col">
        <div class="row learn-" id="path" #path>
            <div class="col-md-12" *ngIf="details?.learning_path_description||details?.learning_paths.length">
                <h4 class="text-center">Learning Path</h4>
                <div class="underline"></div>
                <p>
                    {{details?.learning_path_description}}
                </p>
                <div class="row">
                    <div class="accordion" id="dataScienceParent">
                        <div *ngFor="let learning of details?.learning_paths;let i=index">
                            <div class="row" style="cursor: pointer;">
                                <div class="col-1">
                                    <fa-icon class="float-right" data-toggle="collapse" [attr.data-target]="'#test_' + learning.id" aria-expanded="true" aria-controls="{{learning.id}}" style=" color: #095e37; font-size:medium;padding-top: 10px ;" [icon]="faCircle">
                                    </fa-icon>
                                </div>
                                <div class="col-11">
                                    <h5 style="margin-top:10px">{{learning.title}}</h5>
                                </div>
                            </div>
                            <div class="row collapse show" [id]="'test_'+learning.id" aria-labelledby="{{learning.id}}">
                                <div class="col-1">
                                    <div class="connect" *ngIf="i!==details?.learning_paths.length-1"></div>
                                </div>
                                <div class="col-11">
                                    <div>
                                        <p class="path-paragraph">{{learning.description}}</p>
                                        <div class="accordion" id="BigData">
                                            <div *ngFor="let det of learning.details">
                                                <div class="card card-collrn" style="margin-bottom:5px;border: none; cursor: pointer;">
                                                    <div class="card-header learningbtn" id="{{det.id}}">
                                                        <div class="row">
                                                            <div class="col-md-4" data-toggle="collapse" [attr.data-target]="'#_test'+det.id" aria-expanded="false" aria-controls="collapseOne" style="text-align:left">{{det.title}}
                                                            </div>
                                                            <div class="col-md-3" data-toggle="collapse" [attr.data-target]="'#_test'+det.id" aria-expanded="false" aria-controls="collapseOne" style="text-align:center">
                                                                <fa-icon [icon]="faClock"></fa-icon>
                                                                {{det.quize}} quizzes
                                                            </div>
                                                            <div class="col-md-4" data-toggle="collapse" [attr.data-target]="'#_test'+det.id" aria-expanded="false" aria-controls="collapseOne" style="text-align:right">
                                                                <fa-icon [icon]="faNewspaper"></fa-icon> {{det.project}} Projects/Assignments
                                                            </div>
                                                            <div class="col-md-1" data-toggle="collapse" [attr.data-target]="'#_test'+det.id" aria-expanded="false" aria-controls="collapseOne" style="text-align:right">
                                                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                                                                <i class="fa fa-angle-up" aria-hidden="true" style="display:none"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div [id]="'_test'+det.id" class="collapse" aria-labelledby="headingOne">
                                                        <div class="card-body" style="padding: 20px 20px;background: #e3fffc;">
                                                            {{det.description}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-delivery cert-col">
        <div *ngIf="details?.certification.length">
            <div style="height:auto;padding: 20px;">
                <div class="row cert-rw" >
                    <div class="col-lg-9 col-md-8 dis767" style="display:none;padding:0px 15px 10px">
                        <h4 class="text-left">Certification</h4>
                        <div class="underline" style="margin-left:0px!important"></div>
                    </div>
                    <div class="col-lg-12 col-md-12 none767" style="padding:30px 30px 0px;display: inline-block;">
                        <h4 class="text-center">Certification</h4>
                        <div class="underline text-center"></div>
                    </div>
                    <div class="col-lg-6 col-md-6" *ngFor="let cert of details?.certification">
                        <div><img class="certificate" src="{{cert.image_certification}}" style="height:356px ;"/></div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="container-delivery cert-col skills-container"  id="skills" #skills>
        <div *ngIf="details?.skills.length">
            <h4 class="text-center">Skills Covered</h4>
            <div class="underline"></div>


            <div style="height:auto;padding: 20px;">
                <div class="row">
                    <div class="col-4 skills-col" *ngFor="let skill of details?.skills">
                        <i class="fa fa-check" style="color:#095e37"></i> {{skill.title}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-delivery cert-col"  id="tools" #tools>
        <div *ngIf="details?.tools.length">
            <h4 class="text-center">Tools Covered</h4>
            <div class="underline"></div>


            <div style="height:auto;padding: 20px;">
                <div class="row">
                    <div class="col-lg-2 col-md-3 col-sm-6 col-6 tools-logo" *ngFor="let tool of details?.tools">
                        <img src="{{tool?.file}}" alt="" class="img-fluid tools-covered">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-delivery instr-col industry-container mt-5">
        <div class="row learn-" *ngIf="details?.industry_projects.length">
            <div class="col-md-12"  id="industry" #industry>
                <h4 class="text-center">Industry Projects</h4>
                <div class="underline"></div>
                <owl-carousel-o [options]="termOptions" class="customOwl">
                    <ng-template carouselSlide *ngFor="let project of details?.industry_projects;  ">
                        <div class="card industry-card">
                            <div class="project-title">
                                <p>Project 1</p>
                                <div class="font-demi"> {{project.title}}</div>
                            </div>
                            <div class="card-body">
                                <p><span>{{project.description}}</span></p>
                                <img src="{{project.image}}" alt="" class="img-fluid">
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
    <div class="container-delivery instr-col capstone-container mt-5">
        <div class="row learn-" *ngIf="details?.industry_projects.length">
            <div class="col-md-12"  id="capstone" #capstone>
                <h4 class="text-center">Capstone Projects</h4>
                <div class="underline"></div>
                <owl-carousel-o [options]="termOptions" class="customOwl">
                    <ng-template carouselSlide *ngFor="let project of details?.capstone_projects;  ">
                        <div class="card capstone-card">
                            <div class="card-image" style="position:relative">
                                <img src="{{project.image}}" class="card-img-top" alt="{{project.image_alt}}">
                            </div>
                            <div class="card-body">
                                <p><span>{{project.description}}</span></p>
                                <!-- <span class="footer float-right"><b>{{project.created_at|date}}</b> -->
                                <!-- </span> -->
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
    <div class="container" *ngIf="details?.program_cohort?.is_scheduled===1">
        <div class="container-fluid"  id="cohorts" #cohorts>
            <h4 class="text-center">Program Cohorts</h4>
            <div class="underline"></div>
            <div style="background-color:#ccc;padding:10px">
                <div class="row">
                    <div class="col-12">
                        <h6>
                            {{details?.title}}
                        </h6>
                    </div>
                </div>
            </div>
            <div class="card-body" style="border-radius: 6px;
            box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
            padding: 30px;
            ">
                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <td></td>
                            <td>
                                <fa-icon style="color: rgb(10, 72, 15) ; margin:2px;" [icon]="faCalendarAlt"></fa-icon>
                                Date</td>
                            <td>
                                <fa-icon style="color: rgb(10, 72, 15) ; margin:2px;" [icon]="faClock"></fa-icon>Time</td>
                            <td>
                                <fa-icon style="color: rgb(10, 72, 15) ; margin:2px;" [icon]="faListUl"></fa-icon>Batch Type</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Program Induction</td>
                            <td>{{details?.program_cohort?.program_start_date|date}}</td>
                            <td>{{details?.program_cohort?.program_start_time}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Regular Classes</td>
                            <td>{{details?.program_cohort?.regular_start_date|date}}-{{details?.program_cohort?.regular_end_date|date}}</td>
                            <td>{{details?.program_cohort?.regular_start_time}}</td>
                            <td>{{details?.program_cohort?.regular_batch}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <!-- <div class="unicols">
        <div class="container-delivery univ-col" *ngIf="details?.university_progression">
            <div class="row sunbcol" style="margin:0px 15px">
                <div class="col-md-12 subn-col">
                    <div class="row ">
                        <div class="col-md-7">
                            <div class="">
                                <div class="card-text">
                                    <h4 style="margin-top:50px">Examples of University Progression</h4>
                                    <div class="underline" style="margin-left:0px!important"></div>
                                    <ul>
                                        <div class="col-md-12" style="padding:0px">
                                            <div class="list" [innerHTML]="details?.university_progression"></div>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <img src="{{details?.university_progression_image}}" class="card-img" alt="{{details?.university_progression_image_alt}}">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="container-delivery instr-col" #instructor id="instructor">
        <div class="row learn-" *ngIf="instructors.length">
            <div class="col-md-12">
                <h4 class="text-center">Instructors</h4>
                <div class="underline"></div>
                <owl-carousel-o [options]="termOptions">
                    <ng-template carouselSlide *ngFor="let instructor of instructors;  ">
                        <div class="item cardblock" style="height: 530px;overflow:hidden">
                            <div class="inst-img">
                                <img class="float-left instructor" src="{{instructor.image}}" alt="{{instructor?.image_alt}}">
                            </div>
                            <div class="instruct">
                                <h4 style="height: 26px;">{{instructor.name}} </h4>
                                <div>{{instructor.designation}}</div>
                                <div class="instr_logo">
                                    <img class="center" [src]="instructor?.logo" alt="{{instructor.logo_alt}}"/>
                                </div>
                                <div>
                                    <div style="height:50px !important;
                                    overflow: hidden;">{{instructor?.short_description}}</div>
                                    <div style="cursor:pointer;font-size:11px; color:#e45b0b" (click)="instruct.show(instructor)">Read More</div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div> -->

    <div class="container-delivery batch-container mt-5" *ngIf="batch_companies.length">
        <div class="container-fluid">
            <h4 class="text-center">Batch Profile</h4>
            <div class="underline"></div>
            <p [innerHTML]="sanitizer.bypassSecurityTrustHtml(details?.batch_profile_description)" class="batch-description"></p>
            <div class="batch-header">
                <div class="row header-row">
                    <div class="col-6">
                        <h6 class="font-demi ml-5 mb-0">
                            Industry
                        </h6>
                    </div>
                    <div class="col-6">
                        <h6 class="font-demi  mb-0">
                            Companies
                        </h6>
                    </div>
                </div>
            </div>
            <div class="card-body " style="border-radius: 6px;box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);padding: 30px; margin-top: -5px;">
                <div class="row">
                    <div class="col-6">
                        <div class="text-left">
                            <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis" [title]="chartOptions.title" [labels]="chartOptions.labels"></apx-chart>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-3" *ngFor="let company of batch_companies">
                                <img src="{{company?.file}}" style="max-width:100%; filter: grayscale(100%);" alt="" class="company-img">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="container-delivery mt-5" #experience id="experience">
        <div class="learning-experience" *ngIf="details?.learning_experiences.length">
            <h4 class="text-center">Learning Experience</h4>
            <div class="underline"></div>
            <div class="row" style="margin-top: 30px;">
                <div class="col-md-4 col-sm-6 float-left padd-learn" style="margin:0 auto" *ngFor="let experience of details?.learning_experiences">
                    <div class="col-img-lrn">
                        <img class="exp-image" style="color:#000;" src="{{experience.image}}" alt="{{experience?.image_alt}}">
                    </div>
                    <p class="font-demi">{{experience.title}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-5" id="admission" #admission *ngIf="details?.admission_steps.length||details?.eligibility">
        <div class="col-md-12">
            <div class="container-delivery ">
                <div class="row no-gutters">
                    <div class="col-md-7 col-sm-5" style="border-radius: 6px;
                    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);">
                        <div class="card-body">
                            <div class="card-text">
                                <!-- <h4 *ngIf="details?.eligibility">
                                    Admission Process
                                </h4>
                                <div *ngIf="details?.eligibility" class="underline" style="margin-left:0px!important"></div>
                                <div>{{details?.eligibility}}</div> -->
                                <h4>{{details?.admission_steps[0]?.title}}</h4>
                                <div *ngIf="details?.admission_steps.length" class="underline" style="margin-left:0px!important"></div>
                                <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(details?.admission_steps[0]?.description)"></div>
                                <!-- <div *ngIf="page==='program-details'"> -->
                                <a *ngIf="details?.admission_steps.length" (click)="flexible.show('new')" class="btn join edu-br">Flexible Payment Available</a>
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 col-sm-7">
                        <img *ngIf="details?.admission_steps.length" src="{{details?.admission_steps[0]?.image}}" class="card-img w-100 h-100" style="box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);" alt="{{details?.admission_steps[0]?.image_alt}}">
                        <img *ngIf="!details?.admission_steps.length" src="../../../../assets/images/005.jpg" class="card-img w-100 h-100" style="box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);" alt="...">
                    </div>
                </div>
            </div>
            <!-- <app-page-details [page]="page"></app-page-details> -->
        </div>
    </div>
    <div class="container-delivery instr-col mt-5 learner-container" *ngIf="details?.learners_review.length">
        <div class="row learn-"  id="reviews" #reviews>
            <div class="col-md-12">
                <h4 class="text-center">Learner Reviews</h4>
                <div class="underline"></div>
                <owl-carousel-o [options]="learnerOptions">
                    <ng-template carouselSlide *ngFor="let article of details?.learners_review;  ">
                        <div class="card pgm-card article">
                            <div class="row">
                                <div class="col-3">
                                    <img src="{{article.image}}" class="" alt="{{article.image_alt}}">
                                </div>
                                <div class="col-8">
                                    <div class="font-demi">{{article.name}}</div>
                                    <div>{{article.designation}}</div>
                                    <div>{{article.company_name}}</div>
                                    <img style=" width: 50px;" src="{{article.company_logo}}" class="img-fluid">
                                    <span *ngFor="let rating of [].constructor(article?.star_rate) ">
                                    <i class="fa fa-star" style="color:#085e37"></i>
                                </span>
                                </div>
                                <div class="col-12" style="margin-top:20px">
                                    <p class="story">{{article?.story}}</p>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
    <div class="container-delivery instr-col related-container" *ngIf="details?.related_articles.length">
        <div class="row learn-"  id="articles" #articles>
            <div class="col-md-12">
                <h4 class="text-center">Related Articles</h4>
                <div class="underline"></div>
                <owl-carousel-o [options]="termOptions" class="customOwl">
                    <ng-template carouselSlide *ngFor="let article of details?.related_articles;  ">
                        <div class="card pgm-card">
                            <div class="card-image" style="position:relative">
                                <img src="{{article.image}}" class="card-img-top" alt="{{article.image_alt}}">
                            </div>
                            <div class="card-body pgm-body">
                                <p><span routerLink="/program-details/{{article.name}}" class="dx-text">{{article.name}}</span></p>
                                <span class="footer float-right"><b>{{article.created_at|date}}</b>
                                </span>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
    <div>

        <div class="container-delivery faq-col faq-container">
            <div class="row faq" id="faq" #faq *ngIf="details?.faqs.length">
                <div class="col-md-12">
                    <div>
                        <h4 style="margin-top:30px;">FAQs</h4>
                        <div class="underline-"></div>
                    </div>
                    <div class="accordion" id="accordionExample">
                        <div class="cards" *ngFor="let faq of details?.faqs">
                            <div class="card-header cardheader" id="{{faq.id}}">
                                <div class="row faqt collapsed" data-toggle="collapse" [attr.data-target]="'#news' + faq.id" aria-expanded="false" aria-controls="collapseTwo">
                                    <div class="col-11 float-left" style="padding:0px">
                                        <h5 class="quest" style="cursor: pointer;">{{faq.question}}</h5>
                                    </div>
                                    <div class="col-1" style="cursor: pointer;text-align:right;padding:0px;margin-top: 5px;">
                                        <span class=" float-right plus1" data-toggle="collapse" [attr.data-target]="'#new' + faq.id" aria-expanded="false" aria-controls="collapseTwo" style="font-size:16px">
                                        +
                                    </span>
                                        <span class=" float-right minus1" data-toggle="collapse" [attr.data-target]="'#new' + faq.id" aria-expanded="false" aria-controls="collapseTwo" style="font-size:16px;">
                                    -
                                </span>
                                    </div>
                                </div>
                                <!-- <p class="faq-under-line"> </p> -->
                            </div>
                            <div [id]="'news'+faq.id" class="collapse " aria-labelledby="headingOne" data-parent="#accordionExample" style="width: 100%;">
                                <div class="card-body">
                                    <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(faq.answer)"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row container-delivery prgdet mt-5">
            <div class="col-md-12">
                <app-find-career *ngIf="details" [page]="'programs-details'" [details]="details" (changeName)="onchangeName()"></app-find-career>
            </div>
        </div>
        <div class="container-delivery">
            <div class="col-fixed">
                <div class="bottom">
                    <app-bottom-bar></app-bottom-bar>
                </div>
            </div>
        </div>
    </div>
    <ngx-loading [show]="page_load" [config]="{  fullScreenBackdrop:true,backdropBackgroundColour:'#edecec80' }" [template]="customLoadingTemplate"></ngx-loading>

    <ng-template #customLoadingTemplate id="load">
        <div class="custom-class">
        </div>
    </ng-template>
