import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { CourseService } from 'src/app/core/services/course/course.service';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-credit-points',
  templateUrl: './credit-points.component.html',
  styleUrls: ['./credit-points.component.scss']
})
export class CreditPointsComponent implements OnInit {

  @Output() childEvent = new EventEmitter();
  @Input('point') point: any;
  @Input('point_details') point_details: any;


  err: string='';
  course_id: any;
  user_id: any;
  advance:any;
  unsubcribe: any;
 
  separateDialCode = false;

 
  constructor(private courseService:CourseService,private userService:UserService) { 
     console.log("field",this.fields)

  }


  ngDistroy() {
    this.unsubcribe();
  }

  list = [];
  documents=[];
  fields = [];
  faTimesCircle=faTimesCircle;
  formFields = [];
  showModal1:boolean
  ngOnInit(): void {
    // this.getEnroll();
   
   if(this.userService.isLoggedIn()) {
      this.userService.getUser().subscribe(data=>{
        this.user_id = data.profile.id
      })
    }   
  }



  
  
  

  show(event){
    console.log("data");
    
    this.list = [];
    this.showModal1 =true;
   
    // this.course_id =id;
  }
  hide(){
    this.showModal1 = false;
    this.err='';
    this.fields=[];
    this.list=[];
  }
}
