<div class="container-fluid banner" style="padding:0px">
    <!-- <iframe width="100%" height="100%" frameBorder="0" [src]="urlSafe"></iframe> -->

    <div class="row" style="width:100%;position: relative;margin: 0px;">
        <div class="card-image">
            <app-banner [page]="'contact-us'"></app-banner>
        </div>
        <div class="row container-delivery hirpt">
            <div class="card-sec sig-form">
                <div class="card-body">
                    <h5>Contact Us</h5>
                    <form class="form" #signupform="ngForm" (ngSubmit)="onSubmit(signupform)" novalidate>

                        <div class="row">
                            <div class="col-md-12 ">
                                <input type="text" class=" text" [(ngModel)]="model.name" name="name" #name="ngModel" placeholder="First Name *" pattern="[a-zA-Z\s]*$" required (change)="model.name = model.name.trim()">
                                <span class="text-danger" *ngIf="(name.touched || signupform.submitted) && name.errors?.required">
                      First Name is required
                    </span>
                                <span class="text-danger" *ngIf="(name.touched || signupform.submitted) && name.errors?.pattern">
                    Only Alphabets are allowed
                  </span>
                            </div>
                            <div class=" col-md-12 ">
                                <input type="text" class=" text" pattern="[a-zA-Z\s]*$" [(ngModel)]="model.last_name" name="last_name" #last_name="ngModel" placeholder="Last Name *" required (change)="model.last_name = model.last_name.trim()">
                                <span class="text-danger" *ngIf="(last_name.touched || signupform.submitted) && last_name.errors?.required">
                      Last Name is required
                    </span>
                                <span class="text-danger" *ngIf="(last_name.touched || signupform.submitted) && last_name.errors?.pattern">
                      Only Alphabets are allowed
                    </span>
                            </div>
                            <div class=" col-md-12 ">
                                <input type="text" class=" text" [(ngModel)]="model.email" name="email" #email="ngModel" placeholder="Email *" required email (change)="model.email = model.email.trim()">
                                <span class="text-danger" *ngIf="(email.touched || signupform.submitted) && email.errors?.required">
                      Email is required
                    </span>
                                <span class="text-danger" *ngIf="email.touched && email.errors?.email">
                      Enter a valid email address
                    </span>
                            </div>


                            <div class="col-md-12">
                                <textarea class="text" name="message" [(ngModel)]="model.message" placeholder="Additional Information" style="height:100px;resize: none;"></textarea>
                            </div>
                        </div>
                        <div>
                            <input type="submit" class="btn join" value="Submit">
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
    <!-- <div class="banner-item">   
      <div [ngStyle]="{ 'background-image': 'url(' + banner?.image + ')'}">
          
                <div class="col-md-12" style="position: unset;">
                    <div class="heads row">
                        <div class="col-md-7 contact" >
                            <h2>
                                Have questions about Edubex? Connect with us.
                            </h2>
                        </div>
                          
                        
                     
                            <div class="col-md-4 signup" >
                               
                                
                                
                              </div>  
                       
                    </div>
                </div>
        
        </div>
      
    </div> -->
    <div class="container-delivery">
        <div class="suce-col">
            <h3> Campus-Specific Inquiries</h3>
            <div class="underline-"></div>
            <h5>
                If you have questions about Edubex, please contact us using the form above.

            </h5>
            <div class="row address">
                <div class="col-md-6" style="margin-bottom:20px">
                    <div class="col-conts">
                        <div><b style="margin-bottom:10px;display: block;"> UK </b></div>
                        1 & 3 Kings Meadow<br> Osney Mead Oxford<br> OX2 0DP
                    </div>
                </div>
                <div class="col-md-6" style="margin-bottom:20px">
                    <div class="col-conts">
                        <div><b style="margin-bottom:10px;display: block;"> India </b></div>
                        Drishya Building<br> Kinfra Film and Media Park Animation & Gaming SEZ
                        <br> Near Sainik School<br>PO Kazhakootam, Thirivananthapuram, Kerala
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="col-conts" style="text-align:center">
                        <div><b style="margin-bottom:10px;"> Email : </b> <a style=" color: #000;
                            " href="mailto:info@example.com">admissions@edubex.com</a></div>
                        <div><b style="margin-bottom:10px;"> Call us : </b><a style=" color: #000;
                          " href="tel:+98765433210">+44 208 151 8188</a> </div>
                        <div><b style="margin-bottom:10px;"> For Program-related Queries : </b> admissions@edubex.com</div>
                        <div><b style="margin-bottom:10px;"> For Business-related Queries : </b> partnership@edubex.com</div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>