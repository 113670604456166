<div *ngIf="router.url !== '/login'" class="container-fluid header rounded-0">
    <div class="row top-row top-head">
        <div class="col-lg-12 col-md-12 right-col">
            <p class="header-top-sec country" style="float:right!important" (click)="countryClick()">
                <span *ngIf="this.courseService.country==102" class="country"><span style="margin-right:2px;" class="flag-icon flag-icon-in flag-icon-squared country"></span> IND
                <fa-icon class="country" [icon]="faCaretDown"></fa-icon>
                </span>
                <span *ngIf="this.courseService.country==235" class="country"><span  style="margin-right:2px;" class="flag-icon flag-icon-gb flag-icon-squared country"></span> UK
                <fa-icon class="country" [icon]="faCaretDown"></fa-icon>
                </span>
                <span *ngIf="this.courseService.country===236" class="country"><span style="margin-right:2px;" class="flag-icon flag-icon-us flag-icon-squared country"></span> USA
                <fa-icon class="country" [icon]="faCaretDown"></fa-icon>
                </span>
                <span *ngIf="this.courseService.country==234" class="country"><span style="margin-right:2px;" class="flag-icon flag-icon-ae flag-icon-squared country"></span> UAE
                <fa-icon class="country" [icon]="faCaretDown"></fa-icon>
                </span>

                <!-- <select class="browser-default custom-select">
                                <option selected></option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                        </select> -->
            </p>

            <p class="header-top-sec none991" *ngIf="userService.isLoggedIn()" (click)="logout()">Sign Out</p>
            <p class="header-top-sec" *ngIf="!userService.isLoggedIn() && !router.url.includes('/course-payment') " (click)="openModal()">Sign In</p>
            <p class="header-top-sec" *ngIf="!userService.isLoggedIn() && !router.url.includes('/course-payment') " (click)="openRegister()">Sign Up</p>
            <!-- <p class="header-top-sec info"><span class="info-package-count">0</span>Info Packages</p> -->
            <p class="header-top-sec">
                <img *ngIf="userService.isLoggedIn() && width<992" (click)="getDetails($event)" src="{{user_details?.image|| '../../../../assets/images/blank-profile-picture.png'}}" class="pic dis991" style="display: none;"  alt="{{user_details?.image_alt }}">

            </p>

            <p class="header-top-sec dis991" style="position:relative;display: none;">
                <span *ngIf="notifications.length && userService.isLoggedIn()" (click)="getNotification()" class="badge  badge-warning dis991" style="cursor: pointer;
                position: absolute;
                right: 25%;
                background-color: #e86a23;
                color: #fff;
                width: 15px;
                line-height: 15px;
                height: 15px;
                top: -5px;
                padding: 0px;
                text-align: center;
                font-size: 7px;
                border-radius: 100%;">{{notifications.length}}</span>
                <fa-icon *ngIf="userService.isLoggedIn()" (click)="getNotification()" [icon]="faBell" class="bell dis991" style="display:none;cursor:pointer;">

                </fa-icon>
            </p>

            <p class="header-top-sec phone" (click)="bookCall()"><span class="learning-advisor-icon">
                <fa-icon [icon]="faPhone"></fa-icon>
                </span> Speak to Learning Advisor</p>

            <div #usermenu class="country_details country" *ngIf="click">
                <div style="margin-top:0px">
                    <a class="dropdown-item country" style="padding:0px" role="button" (click)="selectCountry('IN')"> <span style="margin-right:2px;" class="flag-icon flag-icon-in flag-icon-squared country"></span> IND </a>
                    <a class="dropdown-item country" style="padding:0px" role="button" (click)="selectCountry('UK')"><span style="margin-right:2px;" class="flag-icon flag-icon-gb flag-icon-squared country" ></span> UK </a>
                    <a class="dropdown-item country" style="padding:0px" role="button" (click)="selectCountry('US')"><span style="margin-right:2px;" class="flag-icon flag-icon-us flag-icon-squared country"></span> USA </a>
                    <a class="dropdown-item country" style="padding:0px" role="button" (click)="selectCountry('UAE')"><span style="margin-right:2px;" class="flag-icon flag-icon-ae flag-icon-squared country"></span> UAE </a>

                </div>
            </div>
        </div>
        <div *ngIf="user_click && userService.isLoggedIn()" class="details">
            <div style="margin-top:0px">
                <a class="dropdown-item" role="button" (click)="goToProfile()">My Profile</a>
                <a class="dropdown-item" role="button" (click)="goToTransaction()">Transaction History</a>
                <a class="dropdown-item" role="button" (click)="goToApproved()">Approved Courses</a>
                <a class="dropdown-item" role="button" (click)="goToApplied()">Applied Courses</a>
                <a class="dropdown-item" role="button" (click)="goToChange()">Change Password</a>
                <a class="dropdown-item" role="button" (click)="logout()">Sign Out</a>
            </div>
        </div>
    </div>
    <div class="row top-row mar-row d-none" *ngIf="show && news.length">
        <div class="col-md-12 marq-col">
            <!-- <marquee width = "100%"  scrollamount="3" style="display: block;">
	<div  *ngFor="let notification of news"  >{{notification.news}}</div>	
</marquee> -->
            <div width="100%" style="display: block;text-align:center">
                <div *ngFor="let notification of news">{{notification.news}}</div>
            </div>
            <div class="close">
                <fa-icon (click)="hide()" [icon]="faTimesCircle"></fa-icon>
            </div>
        </div>
    </div>