<div class="banner-item">
    <div>

        <div class="col-md-12" style="position: unset;background-image:url('../../../../assets/images/banner/testimonials.png')">

            <div class="heads row suuc-row">
                <div class="col-12 " style="color: #fff;">
                    <div>
                        <p style="color: #e86a22 !important;font-weight: 900;">STUDENT PROFILE</p>
                    </div>
                    <div>
                        <h1>{{story?.name}}</h1>
                    </div>
                    <div>
                        <h5>{{story?.designation}}</h5>
                    </div>


                </div>
                <div class="col-md-12 dis767" style="display: none;">
                    <img src="{{story?.image}}" alt="{{story?.image_alt}}" style="width:100%">
                </div>
                <div class="col-md-5">
                    <div class="col-md-12 col-12-success" style="
                    background-color:#fff;padding:30px">
                        <div style="color:rgb(167, 150, 150)">
                            <div class="text txt-dark"> PROGRAM TAKEN </div>
                            <div class="text">{{story?.course_title}}</div>
                            <div class="text txt-dark"> PROGRAM TYPE </div>
                            <div class="text">{{story?.course_type}}</div>

                            <div class="text txt-dark"> KEY SKILLS LEARNED </div>
                            <div class="text">
                                <ul *ngFor="let skills of story?.skills_learned">
                                    <li>{{skills}}</li>

                                </ul>
                            </div>
                            <div class="text">
                                <input type="button" routerLink="/program-details/{{story?.course_slug}}" class="btn btn-login" value="View Program Details">

                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="cover" style=" background-image: url({{story?.image}});"></div>
                </div>
            </div>
        </div>

    </div>

</div>
<div class="container-deliverys " style="margin-bottom:25px">
    <div class="suce-col">
        {{story?.story}}
    </div>
    <div *ngFor="let questionnair of story?.questionnair" class="suce-col" style="margin:15px 0px 0px">
        <h5>{{questionnair.question}}</h5>
        <p>{{questionnair.answer}}</p>
    </div> 
</div>
<div class="container">
    <div class="container-fluid">
        <div class="row">
            <!-- <div class="col-6">
                <h3 class="float-right">
                Want to share?
                </h3>
            </div> -->
            <div class="col-12" style="font-size:35px;margin-top: 30px;">
                <div class="float-right">
                    <span class="instagram" >
                        <fa-icon [icon]="faInstagram"  (click)="shareOnInstagram(baseUrl+'/'+router.url)"></fa-icon>
                       </span>
                       <span  class="instagram" (click)="shareOnFacebook(baseUrl+'/'+router.url)">
                           <fa-icon [icon]="faFacebook"></fa-icon>
                          
                       </span>
                       <span class="instagram"(click)="shareOnTwitter(baseUrl+'/'+router.url)">
                         <fa-icon [icon]="faTwitter"></fa-icon>
                        </span>           
                    <span  class="instagram" (click)="shareOnLinkedin(baseUrl+'/'+router.url)" >
                        <fa-icon [icon]="faLinkedin"></fa-icon>
                    </span>
                </div>
             
               
            </div>     
        </div>
    </div>
</div>

<div class="jumbotron" style="margin-bottom:0px">
    <div class="container-delivery red-mrsucess">
        <h3 style="margin-left:15px;cursor:pointer" class="more" routerLink="/list/stories">Read More Success Stories</h3>
        <!-- <div class="row"> -->
            <owl-carousel-o [options]="termOptions">
                <ng-template carouselSlide *ngFor="let stand of list">
                    <div class="col-md-12 col-sm-12"  (click)=goToStory(stand.id) >
                        <div class="col-test">
                            <img src="{{stand.image}}" alt="{{stand?.image_alt}}" style="cursor:pointer;width:100%" class="img-fluid">
                            <div class="col-md-12 " style="margin-top:15px">
                                <h3 style="margin:0px 0px 5px!important;cursor:pointer">{{stand.name}} <span style="font-size:22px"> <i class="fa fa-angle-right" aria-hidden="true" style="padding-left: 7px;color: #f2743d;position: relative;top: -1px;"></i></span></h3>
                                <p style="cursor:pointer">{{stand.description}}</p>
                            </div>
                        </div>
                    </div>
                    </ng-template>
                    </owl-carousel-o>
          

        <!-- </div> -->
    </div>

</div>
<div class="container-fluid caro-section" style="margin-bottom:30px" >
    <div class="container">
        <h3 class="text-center learn">Explore Our Courses</h3>
        <div class="underline"></div>
        <owl-carousel-o [options]="termOptions">
            <ng-template carouselSlide *ngFor="let course of all;  ">
                <div class="col-md-12 float-left">
                    <div class="card pgm-card">
                        <div class="card-image" style="position:relative">
                            <img src="{{course.poster}}" class="card-img-top" alt="{{course.poster_alt}}">
                        </div>
                        <div class="card-body pgm-body">        
                            <h6 class="card-title pgm-tit"><span routerLink="/program-details/{{course.master_slug}}"><b>{{course.title}}</b></span></h6>
                            <div >
                                <p class="card-text terms-text">{{course.overview}}</p>
                            </div>
                            <div class="float-right" style=" width: 100%; margin: 0px -1%; float: none!important;display: flex; ">
                                <a class="view  float-left" routerLink="/program-details/{{course.master_slug}}">Know More</a>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>