import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseService } from 'src/app/core/services/course/course.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { LoginComponent } from '../../pages/login/login.component';
declare var bootbox:any;

@Component({
  selector: 'app-find-career',
  templateUrl: './find-career.component.html',
  styleUrls: ['./find-career.component.scss']
})
export class FindCareerComponent implements OnInit {
  @Input('page') page: string;
  @Input ('details') details:any;
  @Output() changeName = new EventEmitter();
  constructor(public userService:UserService, public courseService:CourseService, public modalService:NgbModal) { 
  }

  reload($event){
    console.log("reload",$event)
    this.changeName.emit();
    // window.location.reload(); 
    // this.getCourseDetails();
  }

  ngOnInit(): void {
    console.log("details",this.details,this.page)
  }

  goToCheckout(course){
    var _this = this;
    bootbox.confirm({
      message:"<h5>Confirm Payment!</h5><h6>Course Fees:"+course.currency+ ' '+course.price+"</h6>", 
        buttons: {
          confirm: {
            label: 'Submit',
            className: 'success'
            },
          cancel: {            
            className: 'fail'
          }
      },
        callback:function(result){      
        if(result){
          _this.saveOrder(course)
        }  
      }   
    });
  }

  saveOrder(course){
    this.userService.getUser().subscribe(data=>{
      console.log("user",data)
      this.courseService.saveEnrollDetails({
        course_id:course.encrypted_id,
        documents:[],
        user_id:data.profile.id,
        fields:[]  
      }).subscribe(async (resp: any) =>{
        console.log("res",resp) 
        var dialog = bootbox.dialog({
          message: "Thank you for applying to the course.The enrollment will be completed once the admin verifies your request. You will receive an email confirmation once the admin approves your request.",
          closeButton: true
        }); 
        this.changeName.emit();
        // window.location.reload(); 

      }, (err: any) => {
        console.log(err);
      })
    })
  }

  openModal() {
		const modalRef = this.modalService.open(LoginComponent,
		  {
			scrollable: true,
			windowClass: 'myModalClass',
			// keyboard: false,
			// backdrop: 'static'
		  });
	
		let data = {
		  prop1: 'program-details',
		  prop2: this.page,
		  prop3: 'This Can be anything'
		}
	
		modalRef.componentInstance.fromParent = data;
		modalRef.result.then((result) => {
		  console.log(result);
		}, (reason) => {
		});
	  }

}
