<div class="row banner">
    <div class="col-md-12" style="width:100%;position: relative;margin: 0px;">
        <div class="card-image">
            <app-banner [page]="'corporate-training'"></app-banner>
        </div>
        <div class="row container-delivery hirpt">
            <div class="card-sec sig-form">
                <div class="card-body">
                    <app-signup-teach-edubex></app-signup-teach-edubex>
                </div>
            </div>
        </div>   
    </div>
    <div class="container-fluid">
        <div class="container-delivery">
            <div class="row">
                <div class="col-md-12 col-corp" style="margin-top:50px">
                    <app-page-details [page]='page'></app-page-details>                
                </div>
            </div> 
            <div class="row">
                <div class="col-md-12">
                    <div class="row"  style="margin-top:0px;">
                        <div class="col-md-5">
                            <img src="{{expect?.image}}"  class="card-img" alt="{{expect?.image_alt}}">
                        </div>
                        <div class="col-md-7">
                            <div class="">
                                <div class="card-text">
                                    <h4 style="margin-top:30px;margin-left: 0px;">{{expect?.title}}</h4>
                                    <div class="underline-" style="margin:0px 0px 25px"></div>
                                    <div class="row" style="font-size: 16px;">
                                        <div class="col-md-12">
                                            <ul style="list-style-type:square;padding:0px">
                                                <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(expect?.description)"></div>                                        
                                            </ul> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row apply colsaply" style="margin-top:50px">               
                <div class="col-md-7" style="margin-top: 20px;color:#fff;">
                    <h1>Ready to Empower Your Workforce </h1>
                    <div class="underline-"></div>
                    <!-- <h3>Looking forward to working with you! </h3> -->
                </div>
                <div class="col-md-5 colsng">
                    <div class="col-md-12 signup">
                        <app-signup-teach-edubex></app-signup-teach-edubex>                     
                    </div>
                </div>
            </div>        
        </div>
    </div>
</div>
<div class="container-delivery">
<div class="col-fixed">
    <div class="bottom" >
        <app-bottom-bar></app-bottom-bar> 
    </div>
</div>
</div>
