<div class="modal-body ">
    <div class="close" (click)="closeModal('dismiss')">
        <fa-icon style=" margin:5px;cursor:pointer;color: #000" [icon]="faTimesCircle"></fa-icon>
    </div>
<div class="card-sec " style="padding:20px">
    <div class="card-body" style="border-radius: 6px;padding-top: 0px;">
        <form class="form" #courseForm="ngForm" (ngSubmit)="courseForm.form.valid && onSubmit(courseForm)" novalidate>
            <h4 class="card-title"><b>Application Form</b></h4>
            <div class="row">
                <div class="col-md-12 form-group">
                    <label>Name</label>
                    <input type="text" class=" text" [(ngModel)]="model.name" name="name" #name="ngModel" placeholder="Name" pattern="[a-zA-Z\s]*$" required (change)="model.name = model.name.trim()">
                    <span class="text-danger" *ngIf="(name.touched || courseForm.submitted) && name.errors?.required">
                    Name is required
                </span>
                    <span class="text-danger" *ngIf="(name.touched || courseForm.submitted) && name.errors?.pattern">
                Only Alphabets are allowed
            </span>
                </div>
                <div class=" col-md-12 form-group">
                    <label for="gender">Gender</label>
                    <div class="row">
                        <div class="col-lg-3">
                            <input id="male" type="radio" value="0" name="gender" [(ngModel)]="model.gender" selected>
                            <label for="male"> Male</label>
                        </div>
                        <div class="col-lg-3">
                            <input id="female" type="radio" value="1" name="gender" [(ngModel)]="model.gender">
                            <label for="female"> Female</label>
                        </div>
                    </div>
                </div>
                <div class=" col-md-12 form-group">
                    <label>DOB</label>
                  
                    <input type="date" class="text" [(ngModel)]="model.dob" name="dob" [min]="minDate|date:'yyyy-MM-dd'" [max]="now|date:'yyyy-MM-dd'" #dob="ngModel" required>
                    <span class="text-danger" *ngIf="(dob.touched || courseForm.submitted) && dob.errors?.required">
                        DOB is required
                    </span>
                </div>
                <div class=" col-lg-12 form-group">
                    <label>Address</label>
                    <textarea class="form-control" [(ngModel)]="model.address" name="address" #address="ngModel" required></textarea>
                    <span class="text-danger" *ngIf="(address.touched || courseForm.submitted) && address.errors?.required">
                        Address is required
                    </span>
                </div>
                <div class=" col-md-12 form-group">
                    <label>Email</label>

                    <input type="text" class=" text name" [(ngModel)]="model.email" name="email" #email="ngModel" placeholder="Email" required email (change)="model.email = model.email.trim()">
                    <span class="text-danger" *ngIf="(email.touched || courseForm.submitted) && email.errors?.required">
                    Email is required
                </span>
                    <span class="text-danger" *ngIf="email.touched && email.errors?.email">
                    Enter a valid email address
                </span>
                </div>
                <div class=" col-md-12 form-group" style="margin: 2px 0px;">
                    <label>Phone</label>

                    <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                        [selectedCountryISO]="CountryISO.India" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National" name="phone" #phone="ngModel" required [(ngModel)]="model.phone">
                    </ngx-intl-tel-input>
                    <!-- <input type="text" class=" text"  pattern="^[- +()0-9]+$" [(ngModel)]="model.phone" name="phone" #phone="ngModel"
                    placeholder="+91" required phone> -->
                    <span class="text-danger" *ngIf="(phone.touched || courseForm.submitted) && phone.errors?.required">
                    Phone is required
                </span>
   
                    <span class="text-danger" *ngIf="(phone.touched || courseForm.submitted) &&!phone.errors?.required &&  phone.errors?.validatePhoneNumber?.valid===false">
                            Invalid Phone number
                </span>
                </div>
                <div class=" col-md-12 form-group">
                    <label>Country</label>

                    <select class="text" name="country" style="background-color: #fff;" [(ngModel)]="model.country" #country (change)=selectCountry($event)>
                    <option value="" disabled selected> Country</option>
                    <option *ngFor="let country of countries" [value]="country.name">
                    <div>
                        <img [src]="country.image" alt="{{country?.image_alt}}"> {{country.name}}
                    </div>  
                    </option>
                </select>
                </div>
                <div class=" col-md-12 form-group">
                    <label>Job Title</label>
                    <input type="text" class="text" required placeholder="Job Title" required [(ngModel)]="model.job_title" name="job_title" #job_title="ngModel">
                    <span class="text-danger" *ngIf="(job_title.touched || courseForm.submitted) && job_title.errors?.required">
                        Job is required
                    </span>
                </div>
                <div class=" col-md-12 form-group">
                    <label>Qualification</label>
                    <input type="text" class="text"  placeholder="Qualification" required [(ngModel)]="model.qualification" name="qualification" #qualification="ngModel">
                    <span class="text-danger" *ngIf="(qualification.touched || courseForm.submitted) && qualification.errors?.required">
                        Qualification is required
                    </span>
                </div>
                <div class=" col-md-12 form-group">
                    <label>Work Experience</label>

                    <select class="text" name="years_of_experience" style="background-color: #fff;" [(ngModel)]="model.years_of_experience" #years_of_experience required>
                <option value="" disabled selected> Work Experience</option>
                <option value="0-1 year">0-1 Year </option>
                <option value="2 years">2 Years </option>
                <option value="3 years">3 Years </option>
                <option value="4 years">4 Years </option>
                <option value="5 years">5+ Years </option>
            </select>
                    <!-- <input type="text" class=" text" [(ngModel)]="model.work_experience" name="work_experience"
                    #work_experience="ngModel" placeholder=" Work Experience" required work_experience>-->
        <span class="text-danger"
                    *ngIf="(years_of_experience.touched || courseForm.submitted) && years_of_experience.errors?.required">
                    Work Experience is required
                </span>
                </div>
            </div>
            <div>
                <div>
                    <input type="submit" class="btn download" value="Apply Now">
                    <!-- <a target="_blank" href="{{pdf}}" download="file_new_name.pdf">Download Brochure</a> -->

                    <!--  <a href="#" class="btn download"></a>  -->
                </div>
               
            </div>
        </form>
    </div>
</div>
</div>