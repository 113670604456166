import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { CourseService } from 'src/app/core/services/course/course.service';
import { MasterService } from 'src/app/core/services/master/master.service';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare var bootbox;

@Component({
  selector: 'app-bootcamp-enrollment',
  templateUrl: './bootcamp-enrollment.component.html',
  styleUrls: ['./bootcamp-enrollment.component.scss'],
})
export class BootcampEnrollmentComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  @ViewChild('modal')
  private modalContent: TemplateRef<BootcampEnrollmentComponent>;
  @Input() fromParent;

  slug: any;
  now: Date = new Date();
  minDate: any;
  maxDate: any;
  model = {
    name: '',
    gender: '0',
    dob: '',
    country: '',
    address: '',
    email: '',
    phone: '',
    qualification: '',
    years_of_experience: '',
    job_title: '',
  };
  faTimesCircle = faTimesCircle;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  email_err = '';
  pdf: any;
  countries: any = [];
  country: any;

  ngOnInit(): void {
    this.getCountries();
  }
  constructor(
    private activeModal: NgbActiveModal,
    private _activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    public masterService: MasterService
  ) {}

  selectCountry(event) {
    this.country = event.target.value;
  }
  getCountries() {
    this.masterService.getCountries().subscribe(
      async (resp: any) => {
        this.countries = resp.countries;
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
  onSubmit(form) {
    console.log('form', form.value);
    let data = form.value;
    data.country = this.country;
    data.bootcamp_id = this.fromParent.course.id;
    data.phone = form.value.phone?.e164Number;

    console.log('data', data);
    this.courseService
      .saveBootcampEnrollment(data)
      .subscribe(async (resp: any) => {
        var dialog = bootbox.dialog({
          message:
            'Thank you for applying to the course.The enrollment will be completed once the admin verifies your request. You will receive an email confirmation once the admin approves your request.',
          closeButton: true,
        });
        form.resetForm();
        this.childEvent.emit('success');
        this.closeModal('dismiss');
      });
  }
}
