<app-banner [page]="page"></app-banner>
<!-- <div class="banner-item">
    <div  class="picsum-img-wrapper box" style="background-color: #095e37; height :150px;color:#fff;padding:20px">
        <img src="../../../../assets/images/banner/blog.jpg" alt="">
      <h3 >Programs</h3>
        
    </div>
</div> -->

<div class="container-fluid " style="padding:0px">
    <div class="container-pgms1" style="padding:0px">
        <app-course-apply (childEvent)="reload($event)" #apply></app-course-apply>

        <!-- <div class="row banner">
            <div class="col-md-12">
                <div class="card-image">
                </div>
            </div>
        </div> -->
        <div class="row none767">
            <div class="col-md-12">
                <!-- <ngb-tabset justify="center" #tabset="ngbTabset">

                    <ngb-tab id="all">
                        <ng-template ngbTabTitle>
                            <span class="icons"> <i class="fa fa-bars" aria-hidden="true"></i></span>

                            <span class="tab-title">All</span></ng-template>
                        <ng-template ngbTabContent>
                            <div class="jumbotron" style="margin:0px;">
                                <div class="container-fluid ">
                                    <div class="container-delivery">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="head">
                                                    <h4 class="text-left">All</h4>
                                                    <div class="underline"></div>
                                                </div>
                                                <owl-carousel-o [options]="termOptions">
                                                    <ng-template carouselSlide *ngFor="let course of all;  ">
                                                        <div class="col-md-12 float-left">
                                                            <div class="card pgm-card">
                                                                <div class="card-image" style="position:relative">
                                                                    <img src="{{course.poster}}" class="card-img-top" alt="{{course.poster_alt}}">
                                                                </div>
                                                                <div class="card-body pgm-body">

                                                                    <div class="card-date1">
                                                                        <div>
                                                                            <span class="badge badge-danger bdg-col" *ngIf="course.label==='New'">NEW</span>
                                                                            <span class="badge badge-danger bdg-col" *ngIf="course.label==='Trending'">TRENDING</span>
                                                                        </div>
                                                                        <div>
                                                                            <fa-icon style=" margin:5px;color: #000" [icon]="faClock"></fa-icon>{{course.duration}}
                                                                            <div style="width:50px;height:35px;float:right" *ngIf="course.university_data">
                                                                                <img *ngIf="course.university_data" alt=" {{course?.university_data?.university_name}}" style="width:100%;height:100%;object-fit:contain;float:right" src="{{course?.university_data?.logo}}">
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <fa-icon style="color: #000 ; margin:5px;" [icon]="faCalendarAlt"></fa-icon>
                                                                            Batch Start Date :<b style="color:#ff8533;margin-left:5px"> {{course.start_date| date:'MMM dd, yyy'}}
                                                                                </b>
                                                                        </div>
                                                                    </div>
                                                                    <h6 class="card-title pgm-tit"><span routerLink="/program-details/{{course.slug}}"><b>{{course.title}}</b></span></h6>
                                                                    <div class="col-pheight">
                                                                        <p class="card-text terms-text">{{course.overview}}</p>
                                                                    </div>
                                                                    <div class="float-right" style=" width: 100%; margin: 0px -1%; float: none!important;display: flex; ">
                                                                        <a class="view  float-left" routerLink="/program-details/{{course.slug}}">View More</a>
                                                                        <a class="btn start float-right" *ngIf="!userService.isLoggedIn()" (click)="openModal(course.slug)">Apply Now</a>
                                                                        <a class="btn start float-right" *ngIf="userService.isLoggedIn() && !course.is_applied && (course.documents.length||course.formfields.length)" (click)="apply.show(course.documents,course.formfields,course.id)">Apply Now</a>
                                                                        <a class="btn start float-right" *ngIf="userService.isLoggedIn() && !course.is_applied &&  !course.documents.length && !course.formfields.length" (click)="goToCheckout(course)">Apply Now</a>
                                                                        <a class="btn start float-right" *ngIf="userService.isLoggedIn() && course.is_applied">Applied</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </owl-carousel-o>                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab *ngFor="let course of longTerm | keyvalue" id="{{course.value[0].category_slug}}">
                        <ng-template ngbTabTitle class="title">
                            <span class="icons" [innerHTML]="sanitizer.bypassSecurityTrustHtml(course?.value.icon)"></span>
                            <span class="tab-title">{{course.key}}</span>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <div class="jumbotron" style="margin:0px;">
                                <div class="container-fluid ">
                                    <div class="container-delivery">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="head">
                                                    <h4 class="text-left">{{course.key}}</h4>
                                                    <div class="underline"></div>
                                                </div>
                                                <owl-carousel-o [options]="termOptions">
                                                    <ng-template carouselSlide *ngFor="let coursedet of course.value">
                                                        <div class="col-md-12 float-left">
                                                            <div class="card pgm-card">
                                                                <div class="card-image">
                                                                    <img src="{{coursedet.poster}}" class="card-img-top" alt="{{coursedet.poster_alt}}">
                                                                </div>
                                                                <div class="card-body pgm-body">
                                                                    <div class="card-date1">
                                                                        <div>
                                                                            <span class="badge badge-danger bdg-col" *ngIf="coursedet.label==='New'">NEW</span>
                                                                            <span class="badge badge-danger bdg-col" *ngIf="coursedet.label==='Trending'">TRENDING</span>
                                                                        </div>
                                                                        <div>
                                                                            <fa-icon style=" margin:5px;color: #000" [icon]="faClock"></fa-icon> {{coursedet?.duration}}
                                                                            <div style="width:50px;height:35px;float:right" *ngIf="coursedet.university_data">
                                                                                <img *ngIf="coursedet.university_data" alt=" {{coursedet?.university_data?.university_name}}" style="width:100%;height:100%;object-fit:contain;float:right" src="{{coursedet?.university_data?.logo}}">
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <fa-icon style="color: #000 ; margin:5px;" [icon]="faCalendarAlt"></fa-icon>
                                                                            Batch Start Date :<b style="color:#ff8533"> {{coursedet.start_date| date:'MMM dd, yyy'}}</b>
                                                                        </div>
                                                                    </div>
                                                                    <h6 class="card-title pgm-tit">
                                                                        <div routerLink="/program-details/{{coursedet.slug}}"><b>{{coursedet.title}}</b></div>
                                                                    </h6>
                                                                    <div class="col-pheight">
                                                                        <p class="card-text terms-text">{{coursedet.overview}}</p>
                                                                    </div>
                                                                    <div class="float-right" style=" width: 100%; margin: 0px -1%; float: none!important;display: flex; ">
                                                                        <a class="view float-left" routerLink="/program-details/{{coursedet.slug}}">View More</a>
                                                                        <a class="btn start float-right" *ngIf="!userService.isLoggedIn()" (click)="openModal(coursedet.slug)">Apply Now</a>
                                                                        <a class="btn start float-right" *ngIf="userService.isLoggedIn() && !coursedet.is_applied && (coursedet.documents.length||coursedet.formfields.length)" (click)="apply.show(coursedet.documents,coursedet.formfields,coursedet.id)">Apply Now</a>
                                                                        <a class="btn start float-right" *ngIf="userService.isLoggedIn() && !coursedet.is_applied &&  !coursedet.documents.length && !coursedet.formfields.length" (click)="goToCheckout(coursedet)">Apply Now</a>
                                                                        <a class="btn start float-right" *ngIf="userService.isLoggedIn() && coursedet.is_applied">Applied</a>                          
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </owl-carousel-o>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset> -->
                <ul ngbNav #nav="ngbNav" [(activeId)]="active"  class="nav-tabs justify-content-center">
                    <li ngbNavItem="1">
                      <a ngbNavLink (click)="getAll()">
                        <span class="icons"> <i class="fa fa-bars" aria-hidden="true"></i></span>

                        <span class="tab-title">All</span>
                      </a>
                      <ng-template ngbNavContent>
                            <div class="jumbotron" style="margin:0px;">
                                <div class="container-fluid ">
                                    <div class="container-delivery">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="head">
                                                    <h4 class="text-left">All</h4>
                                                    <div class="underline"></div>
                                                </div>
                                                <!-- <owl-carousel-o [options]="termOptions">
                                                    <ng-template carouselSlide *ngFor="let course of all;  "> -->
                                                        <div class="col-md-4 float-left" *ngFor="let course of all| paginate: config ;  ">
                                                            <div class="card pgm-card">
                                                                <div class="card-image" style="position:relative">
                                                                    <img src="{{course.poster}}" class="card-img-top" alt="{{course.poster_alt}}">
                                                                </div>
                                                                <div class="card-body pgm-body">

                                                                    <div class="card-date1">
                                                                        <div>
                                                                            <span class="badge badge-danger bdg-col" *ngIf="course.label==='New'">NEW</span>
                                                                            <span class="badge badge-danger bdg-col" *ngIf="course.label==='Trending'">TRENDING</span>
                                                                        </div>
                                                                        <div>
                                                                            <fa-icon style=" margin:5px;color: #000" [icon]="faClock"></fa-icon>{{course.duration}}
                                                                            <div style="width:50px;height:35px;float:right" *ngIf="course.university_data">
                                                                                <img *ngIf="course.university_data" alt=" {{course?.university_data?.university_name}}" style="width:100%;height:100%;object-fit:contain;float:right" src="{{course?.university_data?.logo}}">
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <fa-icon style="color: #000 ; margin:5px;" [icon]="faCalendarAlt"></fa-icon>
                                                                            Batch Start Date :<b style="color:#ff8533;margin-left:5px"> {{course.start_date| date:'MMM dd, yyy'}}
                                                                                </b>
                                                                        </div>
                                                                    </div>
                                                                    <h6 class="card-title pgm-tit"><span routerLink="/program-details/{{course.master_slug}}"><b>{{course.title}}</b></span></h6>
                                                                    <div class="col-pheight">
                                                                        <p class="card-text terms-text">{{course.overview}}</p>
                                                                    </div>
                                                                    <div class="float-right" style=" width: 100%; margin: 0px -1%; float: none!important;display: flex; ">
                                                                        <a class="view  float-left" routerLink="/program-details/{{course.master_slug}}">View More</a>
                                                                        <a class="btn start float-right" *ngIf="!userService.isLoggedIn()" (click)="openModal(course.master_slug)">Apply Now</a>
                                                                        <a class="btn start float-right" *ngIf="userService.isLoggedIn() && !course.is_applied && (course.documents.length||course.formfields.length)" (click)="apply.show(course.documents,course.formfields,course.id)">Apply Now</a>
                                                                        <a class="btn start float-right" *ngIf="userService.isLoggedIn() && !course.is_applied &&  !course.documents.length && !course.formfields.length" (click)="goToCheckout(course)">Apply Now</a>
                                                                        <a class="btn start float-right" *ngIf="userService.isLoggedIn() && course.is_applied">Applied</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    <!-- </ng-template>
                                                </owl-carousel-o> -->
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="course.value[0].category_slug" *ngFor="let course of longTerm | keyvalue"  >
                        <a ngbNavLink (click)="getCourse(course.value)">
                            <img   [src]="course.value.icon_green" >

                            <!-- <span class="icons" [innerHTML]="sanitizer.bypassSecurityTrustHtml(course?.value.icon)"></span> -->
                            <span class="tab-title">{{course.key}}</span>
                        </a>
                      <ng-template ngbNavContent>
                            <div class="jumbotron" style="margin:0px;">
                                <div class="container-fluid ">
                                    <div class="container-delivery">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="head">

                                                    <h4 class="text-left">{{course.key}}</h4>
                                                    <div class="underline"></div>
                                                </div>
                                                <!-- <owl-carousel-o [options]="termOptions">
                                                    <ng-template carouselSlide *ngFor="let coursedet of course.value"> -->
                                                        <div class="col-md-4 float-left" *ngFor="let coursedet of course.value | paginate: config">
                                                            <div class="card pgm-card">
                                                                <div class="card-image">
                                                                    <img src="{{coursedet.poster}}" class="card-img-top" alt="{{coursedet.poster_alt}}">
                                                                </div>
                                                                <div class="card-body pgm-body">
                                                                    <div class="card-date1">
                                                                        <div>
                                                                            <span class="badge badge-danger bdg-col" *ngIf="coursedet.label==='New'">NEW</span>
                                                                            <span class="badge badge-danger bdg-col" *ngIf="coursedet.label==='Trending'">TRENDING</span>
                                                                        </div>
                                                                        <div>
                                                                            <fa-icon style=" margin:5px;color: #000" [icon]="faClock"></fa-icon> {{coursedet?.duration}}
                                                                            <div style="width:50px;height:35px;float:right" *ngIf="coursedet.university_data">
                                                                                <img *ngIf="coursedet.university_data" alt=" {{coursedet?.university_data?.university_name}}" style="width:100%;height:100%;object-fit:contain;float:right" src="{{coursedet?.university_data?.logo}}">
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <fa-icon style="color: #000 ; margin:5px;" [icon]="faCalendarAlt"></fa-icon>
                                                                            Batch Start Date :<b style="color:#ff8533"> {{coursedet.start_date| date:'MMM dd, yyy'}}</b>
                                                                        </div>
                                                                    </div>
                                                                    <h6 class="card-title pgm-tit">
                                                                        <div routerLink="/program-details/{{coursedet.master_slug}}"><b>{{coursedet.title}}</b></div>
                                                                    </h6>
                                                                    <div class="col-pheight">
                                                                        <p class="card-text terms-text">{{coursedet.overview}}</p>
                                                                    </div>
                                                                    <div class="float-right" style=" width: 100%; margin: 0px -1%; float: none!important;display: flex; ">
                                                                        <a class="view float-left" routerLink="/program-details/{{coursedet.master_slug}}">View More</a>
                                                                        <a class="btn start float-right" *ngIf="!userService.isLoggedIn()" (click)="openModal(coursedet.master_slug)">Apply Now</a>
                                                                        <a class="btn start float-right" *ngIf="userService.isLoggedIn() && !coursedet.is_applied && (coursedet.documents.length||coursedet.formfields.length)" (click)="apply.show(coursedet.documents,coursedet.formfields,coursedet.id)">Apply Now</a>
                                                                        <a class="btn start float-right" *ngIf="userService.isLoggedIn() && !coursedet.is_applied &&  !coursedet.documents.length && !coursedet.formfields.length" (click)="goToCheckout(coursedet)">Apply Now</a>
                                                                        <a class="btn start float-right" *ngIf="userService.isLoggedIn() && coursedet.is_applied">Applied</a>
                          
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    <!-- </ng-template>
                                                </owl-carousel-o> -->
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                      </ng-template>
                    </li>                  
                  </ul>
                  
                  <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
        </div>
        <div class="row  m-20" *ngIf="all.length>config.itemsPerPage">
            <div class="col-12">
                <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="config.currentPage = $event">
                    <div class="custom-pagination" style="display: flex; float: right">
                        <div *ngIf="!p.isFirstPage()" class="pagination-previous" [class.disabled]="p.isFirstPage()">
                            <a (click)="p.previous()"> <i class="fa fa-angle-left" aria-hidden="true" style="font-size: 25px;"></i>
                                </a>
                        </div>

                        <div *ngFor="let page of p.pages" class="pages" [class.current]="p.getCurrent() === page.value">
                            <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                <span>{{ page.label }}</span>
                            </a>
                            <div *ngIf="p.getCurrent() === page.value">
                                <span>{{ page.label }}</span>
                            </div>
                        </div>
                        <div *ngIf="!p.isLastPage()" class="pagination-next" [class.disabled]="p.isLastPage()">
                            <a (click)="p.next()"> <i class="fa fa-angle-right" aria-hidden="true" style="font-size: 25px;"></i> </a>
                        </div>
                    </div>
                </pagination-template>
            </div>

        </div>
        <div class="row">
            <div class="jumbotron" style="margin:0px;width:100%;padding:2rem 20px">
                <div class="container-fluid ">
                    <div class="container-delivery">
                        <div class="col-md-12">
                            <ngb-accordion class="dis767 card-collrn" style="display:none" #myaccordion="ngbAccordion" closeOthers="true" type="'success'" (panelChange)="beforeChange($event)">

                                <ngb-panel *ngFor="let det of  longTerm | keyvalue" class="explore-cat" title="{{det.key}}" id="{{det.value[0].id}}">
                                    <ng-template ngbPanelContent>
                                        <owl-carousel-o [options]="exploreOptions">
                                            <ng-template carouselSlide *ngFor="let longterm of det.value ">
                                                <div class="card" *ngFor="let longterm of det.value ">
                                                    <div class="row no-gutters" style="height: 100%;">
                                                        <div class="col-sm-6">
                                                            <div class="col-exp-img">
                                                                <img [src]="longterm.poster" alt="{{longterm.poster_alt}}">
                                                            </div>
                                                            <div class="crs-tite">
                                                                <div>{{ longterm.category}} </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-exp-cnt">
                                                            <div class="card-body">
                                                                <div style="text-align: end;">
                                                                    <span class="badge badge-danger bdg-col" *ngIf="longterm.label==='New'" style="width:auto;margin-bottom: 20px;position:relative">NEW</span>
                                                                    <span class="badge badge-warning bdg-col" *ngIf="longterm.label==='Trending'" style="width: auto;margin-bottom: 20px;position:relative;background-color: rgb(207 230 222);color: #095e37;padding: 5px;">TRENDING</span>
                                                                </div>
                                                                <div class="card-date1">
                                                                    <div>{{longterm.duration}}
                                                                        <fa-icon style=" margin:2px;color: #000" [icon]="faClock">
                                                                        </fa-icon>
                                                                    </div>
                                                                    <div>Batch Start Date :<b> {{ longterm.start_date| date:'MMM dd, yyy' }} </b>
                                                                        <fa-icon style="color: #000 ; margin:2px;" [icon]="faCalendarAlt"></fa-icon>
                                                                    </div>
                                                                </div>
                                                                <h5 class="card-title">
                                                                    <div routerLink="/program-details/{{longterm.master_slug}}">{{longterm.title}}</div>
                                                                </h5>
                                                                <div class="col-pheight">
                                                                    <p class="card-text terms-text">{{longterm.overview}}</p>
                                                                </div>
                                                                <div style="width:50px;height:35px;float:left" *ngIf="longterm.university_data">
                                                                    <img style="width:100%;height:100%;object-fit:contain;float:left" alt="{{longterm.university_data.logo_alt}}" [src]="longterm.university_data?.logo">
                                                                </div>
                                                                <a routerLink="/program-details/{{longterm.master_slug}}" class="btn start float-right" style="background-color: #095e37 ;">View More</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </owl-carousel-o>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="jumbotron" style="margin:0px;padding:0px">
    <div class="row container-delivery">
        <div class="col-md-12">
            <app-find-career [page]="'programs'"></app-find-career>
        </div>
    </div>
</div>

<div class="container-delivery">
    <div class="col-fixed">
        <div class="bottom">
            <app-bottom-bar></app-bottom-bar>

        </div>
    </div>
</div>