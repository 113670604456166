<div class="row " *ngIf="showModal1">
    <div class="col-md-3 main">
    </div>
</div>
<div [style.display]="showModal1 ? 'block' : 'none'" class="modal apply-mod" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin: 0px auto;max-width: 100%;height: 100vh;">
        <div class="modal-content" style="padding: 0px;height: 100vh!important;overflow-y: scroll!important;">
            <div class="modal-body" style="padding:0px!important ;text-align:left !important;">
                <div class="close" (click)="hide()" style="top: 5px;z-index: 999;right: 0px;">
                    <fa-icon style=" margin:5px;cursor:pointer;color: #000" [icon]="faTimesCircle"></fa-icon>
                </div>
                <div class="row">
                    <div class="col-sm-12" style="padding:0px">
                        <div class="">
                            <div class="card-body">
                                <div class="col-md-12 box" style="padding:15px 0px!important">
                                    <ngb-accordion class="dis991 card-collrn" style="display:none" #myaccordion="ngbAccordion" closeOthers="true" type="'success'" (panelChange)="beforeChange($event)">
                                        <ngb-panel *ngFor="let det of  longTerm | keyvalue" class="explore-cat" title="{{det.key}}" id="{{det.value[0].id}}">
                                            <ng-template ngbPanelContent>
                                                <owl-carousel-o [options]="exploreOptions">
                                                    <ng-template carouselSlide *ngFor="let longterm of det.value ">
                                                        <div class="card" style="margin: 10px;">
                                                            <div class="row no-gutters" style="height: 100%;">
                                                                <div class="col-sm-6 m-hide1" style="padding:0px">
                                                                    <div class="col-exp-img">
                                                                        <img [src]="longterm.poster" alt="{{longterm.poster_alt}}">
                                                                        <div class="crs-tite">
                                                                            <div>{{ longterm.category}} </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 col-exp-cnt mc-full">
                                                                    <div class="card-body">
                                                                        <div style="text-align: end;">
                                                                            <span class="badge badge-danger bdg-col" *ngIf="longterm.label==='New'" style="width:auto;margin-bottom: 20px;">NEW</span>
                                                                            <span class="badge badge-warning bdg-col" *ngIf="longterm.label==='Trending'" style="width: auto;margin-bottom: 20px;background-color: rgb(207 230 222);color: #095e37;padding: 5px;">TRENDING</span>
                                                                        </div>
                                                                        <div class="card-date1">
                                                                            <div>{{longterm.duration}}
                                                                                <fa-icon style=" margin:2px;color: #000" [icon]="faClock">
                                                                                </fa-icon>
                                                                            </div>
                                                                            <div>Batch Start Date: <b>{{ longterm.start_date| date:'MMM dd, yyy' }} </b>
                                                                                <fa-icon style="color: #000 ; margin:2px;" [icon]="faCalendarAlt"></fa-icon>
                                                                            </div>
                                                                        </div>
                                                                        <h5 class="card-title">
                                                                            <div (click)="getProgram(longterm.master_slug)">{{longterm.title}}</div>
                                                                        </h5>
                                                                        <div class="col-pheight">
                                                                            <p class="card-text terms-text">{{longterm.overview}}</p>
                                                                        </div>
                                                                        <div style="width: 50px;float: left;height: 30px;" *ngIf="longterm.university_data">
                                                                            <img [src]="longterm.university_data?.logo" style="width: 100%;height:100%;object-fit:contain;float: left;" alt="{{longterm.university_data.logo_alt}}">
                                                                        </div>
                                                                        <a (click)="getProgram(longterm.master_slug)" class="btn start float-right">View More</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </owl-carousel-o>
                                            </ng-template>
                                        </ngb-panel>


                                    </ngb-accordion>
                                    <div class="text-center" style="padding:10px">
                                        <h5><b>Didn't find what you were looking for?</b></h5>
                                        <h6>We'll help you find it.</h6>
                                        <div>
                                            <a href="https://api.whatsapp.com/send?phone=19998887878&text=Hi%20There!" class="btn start" style="background-color: #ffffff;color: #095e37;width: auto!important;padding: 10px;font-size: 15px!important;margin-top: 10px;border: 2px solid #095e37;"> <i class="fa fa-whatsapp" aria-hidden="true" style="padding-right:10px;font-size: 21px;"></i>Whatsapp Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>