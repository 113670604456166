<div class="container-fluid" style="padding:0px">
        <div class="row">
            <div class="col-md-12">
                <!-- <div class="banner-item">
                    <div  class="picsum-img-wrapper box">
                        <img src="../../../../assets/images/banner/testimonial.jpg" alt="">                      
                        
                    </div>
                </div> -->
              
                <app-banner [page]="'testimonial'"></app-banner>
            </div>
        </div>
</div>
<div *ngIf="status" class="container-delivery serv-col" style="padding-top: 25px;background: #e9ecef;margin-top: 2rem;">
    <div  class="row" style="margin:20px 0px" >
        <div routerLink="/reviews-and-testimonials/{{stand.id}}" class="col-md-4 col-sm-6" style="margin:0px 0px 20px;cursor:pointer"  *ngFor="let stand of list let i= index;let isOdd=odd;let isEven=even">
          <div class="col-test">
            <img routerLink="/reviews-and-testimonials/{{stand.id}}" src="{{stand.image}}" alt="{{stand?.image_alt}}" style="cursor:pointer" class="img-fluid" >
            <div class="col-md-12 ">
                <h3 routerLink="/reviews-and-testimonials/{{stand.id}}" style="margin:0px 0px 5px!important;cursor:pointer">{{stand.name}} <span style="font-size:22px"> <i class="fa fa-angle-right" aria-hidden="true" style="padding-left: 7px;color: #f2743d;position: relative;top: -1px;"></i></span></h3>
                <p routerLink="/reviews-and-testimonials/{{stand.id}}" style="cursor:pointer">{{stand.description}}</p>
            </div>
          </div>
        </div>
        <!-- <div class="row item">
            <div class="col-md-6 col-xl-5" *ngIf="isOdd">
                <img src="{{stand.image}}" style="width: 100%;" class="card-img" alt="...">               
            </div>
            <div class="col-xl-1" *ngIf="isOdd"></div>
            <div class="col-md-6">
                <h4>{{stand.name}}</h4> 
                <div class="underline" style="margin-left:0px!important"></div> 
                <p>{{stand.description}}</p>         
            </div>
            <div class="col-xl-1" *ngIf="isEven"></div>
            <div class="col-md-6 col-xl-5" *ngIf="isEven">
                <img src="{{stand.image}}" style="width: 100%;" class="card-img" alt="...">               
            </div>    
        </div> -->
    </div>
    <app-find-career></app-find-career>
    </div>
    <div *ngIf="!status" class="container-delivery serv-col" style="padding-top:25px">
        <div *ngFor="let stand of list let i= index;let isOdd=odd;let isEven=even">
            <div class="row item">
                <div class="col-md-6 col-xl-5" *ngIf="isOdd">
                    <img src="{{stand.image}}" style="width: 100%;" class="card-img" alt="{{stand?.image_alt}}">               
                </div>
                <div class="col-xl-1" *ngIf="isOdd"></div>
                <div class="col-md-6">
                    <!-- <h1 class="index">{{i+1 < 10 ? '0' : ''}}{{i+1}}</h1> -->
                    <h4>{{stand.name}}</h4> 
                    <div class="underline" style="margin-left:0px!important"></div> 
                    <p>{{stand.description}}</p>         
                </div>
                <div class="col-xl-1" *ngIf="isEven"></div>
                <div class="col-md-6 col-xl-5" *ngIf="isEven">
                    <img src="{{stand.image}}" style="width: 100%;" class="card-img" alt="{{stand?.image_alt}}">               
                </div>    
            </div>
        </div>
        <app-find-career></app-find-career>
        </div>