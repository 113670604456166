<!-- <span  (click)="show()"  style="color:rgba(230, 90, 11, 0.9)"> Sign In</span> -->

<div [style.display]="show ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
     
      <div class="modal-body">
        <div class="close" (click)="hide(forgetForm)">
          <fa-icon style=" margin:5px;cursor:pointer;color: #000" [icon]="faTimesCircle"></fa-icon>
        </div>
        <div class="row">
      
          <div class="col-md-5" style="padding: 0px;background: url(../assets/images/box-bg.png);background-size: 100% 100%;background-repeat: no-repeat;">
            <div class=" box">
              <h1 class=" align-self-center"><b>    Recruit Digital Saavy Professionals from Edubex</b></h1>
              </div>
          </div>
          <div class="col-md-7  align-self-center">
      
            <div class="loginbox">
              <h4 style="color: #223f31 !important;"><b>Forgot Password</b></h4>              
              <form #forgetForm="ngForm" (ngSubmit)="forgetForm.valid &#038;&#038; onSubmit(forgetForm)">
                 <input type="text"  (ngModel)="email" name="email" placeholder="Email"
                 #email="ngModel" required email>
               <div class="text-danger" *ngIf="(email.touched || forgetForm.submitted) && email.errors?.required">
                 Email is required
               </div>
               <div class="text-danger" *ngIf="email.touched && email.errors?.email">
                 Enter a valid email address
               </div>
                 <input type="submit"  class="btn btn-login"  value="Send Verification Email">
               </form>
             
            </div>
        
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
