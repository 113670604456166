<div style="height: 400px;
min-height: 1em;
overflow-y: auto;" scrollTracker (scrollingFinished)="onScrollingFinished()">
    <table class="table">
        <thead>
            <tr>
                <th>
                    Course
                </th>
                <th>
                    Amount
                </th>
                <th style="width:115px">
                    Due Date
                </th>
                <th *ngIf="dues.length">
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let due of dues">
                <td role="button" routerLink="/program-details/{{due?.course.master_slug}}"> {{due.course.title}}</td>
                <td>{{due?.course.currency}} {{due?.fee| number : '1.2-2'}}</td>
                <td> {{due?.due_date}}</td>
                <td style="width:130px;position: relative;">
                    <a class="btn start" (click)="makePayment(due)" style="position: absolute;top: 50%; transform: translateY(-50%);display: block;width: 120px!important;padding: 3px 3px!important;font-size: smaller;">Make Payment</a>
                </td>
            </tr>
        </tbody>
    </table>

</div>