    <!-- <div class="modal-header">        
        <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')"> -->
          
        <!-- </button> 
    </div> -->
    <div class="modal-body">
      <div class="close" (click)="closingModal('dismiss',registerForm)">
      <fa-icon style=" margin:5px;cursor:pointer;color: #000" [icon]="faTimesCircle"></fa-icon>
</div>
        <div class="row">    
            <div class="col-md-4" style="padding: 0px;background: url(../assets/images/box-bg.png);background-size: 100% 100%;background-repeat: no-repeat;">
              <div class=" box">
                <img width="100%" src="../../../assets/images/logo_white.svg">
              <h6></h6>
              </div>  
            </div>
            <div class="col-md-8 align-self-center">
              <div class="loginbox">
                <h4 style="color: #223f31 !important;"><b>Register</b></h4> 
                <form class="form" #registerForm="ngForm" [appMatchPassword]="['password', 'confirmPassword']"
                  (ngSubmit)="onSubmit(registerForm)" novalidate   autocomplete="false">
                  <div class="row">
                    <input type="text" style="display:none" />
                    <div class=" col-md-6 form-group">
                      <label>First Name</label><span style="color:#dc873efa">*</span>
                      <input type="text" class="form-control" pattern="[a-zA-Z\s]*$"
                        [(ngModel)]="userService.selectedUser.first_name" name="first_name" #first_name="ngModel"
                        required (change) ="userService.selectedUser.first_name = userService.selectedUser.first_name.trim()">
                      <span class="text-danger"
                        *ngIf="(first_name.touched || registerForm.submitted) && first_name.errors?.required">
                        First Name is required
                      </span>
                      <span class="text-danger"
                        *ngIf="(first_name.touched || registerForm.submitted) && first_name.errors?.pattern">
                        Only Alphabets are allowed
                      </span>    
                    </div>
                    <div class=" col-md-6 form-group">
                      <label>Last Name</label><span style="color:#dc873efa">*</span>
                      <input type="text" class="form-control" pattern="[a-zA-Z\s]*$"
                        [(ngModel)]="userService.selectedUser.last_name" name="last_name" #last_name="ngModel" required
                        (change) ="userService.selectedUser.last_name = userService.selectedUser.last_name.trim()">
                      <span class="text-danger"
                        *ngIf="(last_name.touched || registerForm.submitted) && last_name.errors?.required">
                        Last Name is required
                      </span>
                      <span class="text-danger"
                        *ngIf="(last_name.touched || registerForm.submitted) && last_name.errors?.pattern">
                        Only Alphabets are allowed
                      </span>
                    </div>
                    <div class=" col-md-6 form-group">
                      <label>Email</label><span style="color:#dc873efa">*</span>
                      <input type="text"  class="form-control email" [(ngModel)]="userService.selectedUser.email"
                        name="email" #email="ngModel" required email  (change) ="userService.selectedUser.email = userService.selectedUser.email.trim()">
                      <span class="text-danger"
                        *ngIf="(email.touched || registerForm.submitted) && email.errors?.required">
                        Email is required
                      </span>
                      <span class="text-danger" *ngIf="email.touched && email.errors?.email">
                        Enter a valid email address
                      </span>
                      <span class="text-danger" *ngIf="email_err && !email.errors?.required">
                        {{email_err}}
                      </span>
                    </div>
                    <div class=" col-md-6 form-group regform">
                      <label>Phone</label><span style="color:#dc873efa">*</span>
                    	<ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                        [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                        [phoneValidation]="true" [separateDialCode]="separateDialCode"
                        [numberFormat]="PhoneNumberFormat.National" name="phone" #phone="ngModel" required   [(ngModel)]="userService.selectedUser.phone">
                      </ngx-intl-tel-input>
                      <!-- <input type="text" class="form-control" pattern="[0-9]+" [(ngModel)]="userService.selectedUser.phone" name="phone"
                        #phone="ngModel" required> -->
                      <span class="text-danger"
                        *ngIf="(phone.touched || registerForm.submitted) && phone.errors?.required">
                        Phone number is required
                      </span>
                      <span class="text-danger" *ngIf="phone_err && !phone.errors?.required">
                        {{phone_err}}
                      </span>
                     
                    <span class="text-danger"
                    *ngIf="(phone.touched || registerForm.submitted) &&!phone.errors?.required && phone.errors?.validatePhoneNumber?.valid===false">
                    Invalid Phone number
                  </span>
                   
                    </div>
                    <div class=" col-md-6 form-group">
                      <label>Password</label><span style="color:#dc873efa">*</span>
                      <input type="password" class="form-control" [(ngModel)]="userService.selectedUser.password"
                        name="password" #password="ngModel" required appPasswordPattern>
                      <span class="text-danger"
                        *ngIf="(password.touched || registerForm.submitted) && password.errors?.required">
                        Password is required
                      </span>
                      <span class="text-danger" *ngIf="password.touched && password.errors?.invalidPassword">
                        Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
                        letter and 1 number
                      </span>
                    </div>
                    <div class="col-md-6 form-group">
                      <label>Confirm Password</label><span style="color:#dc873efa">*</span>
                      <input type="password" class="form-control" [(ngModel)]="userService.selectedUser.confirmPassword"
                        name="confirmPassword" #confirmPassword="ngModel" required>
                      <span class="text-danger"
                        *ngIf="(confirmPassword.touched || registerForm.submitted) && confirmPassword.errors?.required">
                        Confirm Password is required
                      </span>
                      <span class="text-danger"
                        *ngIf="confirmPassword.touched && confirmPassword.errors?.passwordMismatch">
                        Passwords doesnot match
                      </span>
                    </div>
    
                    <div class=" col-md-6 form-group">
                      <label>Country</label><span style="color:#dc873efa">*</span>
                      <!-- <ngx-select-dropdown (change)="selectCountry($event)" formControlName="country" [multiple]="true" 
                      [items]="countries" 
                    optionValueField="id"
                    optionTextField="name" 
                      ></ngx-select-dropdown> -->
                      <ngx-select-dropdown [config]="config"  id="country" name="country" [(ngModel)]="userService.selectedUser.country" (change)="selectCountry($event)"  [options]="countries"></ngx-select-dropdown>
                      <!-- <select class="custom-select" name="country"  
                        (change)=selectCountry($event)>
                        <option value="" disabled selected> Country</option>
                        <option *ngFor="let country of countries" [value]="country.id">
                          {{country.name}}
                        </option>
                      </select> -->
                        <span class="text-danger" *ngIf="registerForm.submitted && userService.selectedUser.country">
                          {{country_err}} </span>
                    </div>
                  </div>
                  <div class="remember">
                    <input type="checkbox" (change)="onChange($event)" [checked]="agree">I agree to all the <span
                      style="color:rgba(230, 90, 11, 0.9);cursor:pointer" target="_blank" (click)="goToPolicy(registerForm)">Terms & Privacy
                      Policy</span>
                      <div class="text-danger"
                      *ngIf="(registerForm.submitted && !agree)">
                      Please accept the terms!             
                    </div>
                    <ng-template #customLoadingTemplate>
                      <div class="custom-class">
                      </div>
                  </ng-template>
                  <ngx-loading [show]="loading" [config]="{ fullScreenBackdrop:true,backdropBackgroundColour:'#edecec80'}" [template]="customLoadingTemplate"></ngx-loading>

                    <!-- <div class="text-danger" *ngIf="serverErrorMessages">
                      {{serverErrorMessages}}
                    </div> -->
                  </div>
                  <input type="submit" class="btn btn-login" value="Create an account">
                </form>
                <div>
                  <span class="remember">Already have an account?</span>
                  <span (click)="openModal()"  style="color:rgba(230, 90, 11, 0.9);cursor:pointer"> Sign In</span>
    
                
                </div>
              </div>
            </div>
    
          </div>
    </div>
    

