<!-- <app-banner [page]="'blog'"></app-banner> -->
<!-- <div class="banner-item">
    <div  class="picsum-img-wrapper box">
        <img src="../../../../assets/images/banner/blog-details.jpg" alt="">


    </div>
</div> -->
<div class="none767 blog-banner-container">
    <div class="container-fluid">
        <div class="row slide-bn-contr">
            <div class="col-lg-7 col-md-12 sl-in" style="padding:10px">
                <img width="30%" [src]="trending[0]?.image|| 'assets/images/imagePlaceholder.jpg'" style="width:100%">
                <div class="lists" routerLink="/blog/{{trending[0]?.slug}}">
                    <span class="category-badge">{{trending[0]?.category}}</span>
                    <h1 class="font-demi">{{trending[0]?.name}}</h1>
                </div>


            </div>
            <div class="col-lg-5 col-md-12 sl-in2">
                <div class="row">
                    <div class="col-md-12" style="padding:10px">
                        <img [src]="trending[1]?.image|| 'assets/images/imagePlaceholder.jpg'" style="width:100%">
                        <div class="lists" routerLink="/blog/{{trending[1]?.slug}}">
                            <span class="category-badge ">{{trending[1]?.category}}</span>
                            <h4 style="margin:0px!important" class="font-demi">{{trending[1]?.name}}</h4>
                        </div>


                    </div>
                    <div class="col-md-6" style="padding:10px;">
                        <img [src]="trending[2]?.image|| 'assets/images/imagePlaceholder.jpg'" style="width:100%">
                        <div class="lists" routerLink="/blog/{{trending[2]?.slug}}">
                            <span class="category-badge ">{{trending[2]?.category}}</span>
                            <h6 class="font-demi">{{trending[2]?.name}}</h6>
                        </div>


                    </div>
                    <div class="col-md-6" style="padding:10px;cursor: pointer;  position: relative;">
                        <div class="show-trending">
                            <img [src]="trending[3]?.image|| 'assets/images/imagePlaceholder.jpg'" style="width:100%">
                            <div class="lists" routerLink="/blog/{{trending[3]?.slug}}">
                                <span class="category-badge ">{{trending[3]?.category}}</span>
                                <h6 class="font-demi">{{trending[3]?.name}}</h6>
                            </div>

                        </div>
                        <div class="text-box">
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5 16.25H3.75C3.41848 16.25 3.10054 16.3817 2.86612 16.6161C2.6317 16.8505 2.5 17.1685 2.5 17.5V26.25C2.5 26.5815 2.6317 26.8995 2.86612 27.1339C3.10054 27.3683 3.41848 27.5 3.75 27.5H12.5C12.8315 27.5 13.1495 27.3683 13.3839 27.1339C13.6183 26.8995 13.75 26.5815 13.75 26.25V17.5C13.75 17.1685 13.6183 16.8505 13.3839 16.6161C13.1495 16.3817 12.8315 16.25 12.5 16.25ZM11.25 25H5V18.75H11.25V25ZM26.25 2.5H17.5C17.1685 2.5 16.8505 2.6317 16.6161 2.86612C16.3817 3.10054 16.25 3.41848 16.25 3.75V12.5C16.25 12.8315 16.3817 13.1495 16.6161 13.3839C16.8505 13.6183 17.1685 13.75 17.5 13.75H26.25C26.5815 13.75 26.8995 13.6183 27.1339 13.3839C27.3683 13.1495 27.5 12.8315 27.5 12.5V3.75C27.5 3.41848 27.3683 3.10054 27.1339 2.86612C26.8995 2.6317 26.5815 2.5 26.25 2.5ZM25 11.25H18.75V5H25V11.25ZM26.25 16.25H17.5C17.1685 16.25 16.8505 16.3817 16.6161 16.6161C16.3817 16.8505 16.25 17.1685 16.25 17.5V26.25C16.25 26.5815 16.3817 26.8995 16.6161 27.1339C16.8505 27.3683 17.1685 27.5 17.5 27.5H26.25C26.5815 27.5 26.8995 27.3683 27.1339 27.1339C27.3683 26.8995 27.5 26.5815 27.5 26.25V17.5C27.5 17.1685 27.3683 16.8505 27.1339 16.6161C26.8995 16.3817 26.5815 16.25 26.25 16.25ZM25 25H18.75V18.75H25V25ZM12.5 2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V12.5C2.5 12.8315 2.6317 13.1495 2.86612 13.3839C3.10054 13.6183 3.41848 13.75 3.75 13.75H12.5C12.8315 13.75 13.1495 13.6183 13.3839 13.3839C13.6183 13.1495 13.75 12.8315 13.75 12.5V3.75C13.75 3.41848 13.6183 3.10054 13.3839 2.86612C13.1495 2.6317 12.8315 2.5 12.5 2.5ZM11.25 11.25H5V5H11.25V11.25Z" fill="white"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<h4 class="text-center font-demi">Learn concepts of Artificial Intelligence, Machine Learning, Data Science, Business Analysis and more</h4>

<div class="container-fluid " style="padding:0px">
    <div class="container-pgms1" style="padding:0px">

        <!-- <div class="row none767" *ngFor="let courselist of list| keyvalue; let i= index ;let isOdd=odd;let isEven=even;   "> -->
            <div class="row none767" *ngFor="let courselist of list| keyvalue; let i= index ">

            <div class="col-md-12 blg-cols">
                <div style="margin:0px;">
                    <div class="container-fluid ">
                        <div class="container-delivery">
                            <span class="badge badge-new cat font-demi badge-border-less">{{courselist?.key}}</span>
                            <!-- <spanclass="badge badge-dark cat font-demi badge-border-less">Others</spanclass=> -->
                            <!-- <hr *ngIf="isOdd" class="head" /> -->

                            <!-- <hr *ngIf="isOdd" class="head" /> -->
                            <!-- <hr *ngIf="isEven" class="head1" /> -->
                            <hr  class="head1" />
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="blg-rw" >
                                        <owl-carousel-o [options]="termOptions" class="customOwl">
                                            <ng-template carouselSlide *ngFor="let course of courselist?.value">
                                                <div class="col-md-12 float-left">
                                                    <div class="card pgm-card m-0">
                                                        <div class="card-image" routerLink="/blog/{{course?.slug}}">
                                                            <img src="{{course?.image}}" alt="{{course?.image_alt}}" class="card-img-top">
                                                            <span class="badge badge-dark list badge-card">{{course?.category}}</span>
                                                        </div>
                                                        <div class="card-body blg-body pgm-body" style="height:auto; padding: 10px 0; position:initial;">
                                                            <div style="cursor: pointer;"><span routerLink="/blog/{{course?.slug}}">{{course?.name}}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </owl-carousel-o>
                                    </div>
                                    <!-- <div class="blg-rw" *ngIf="isEven">
                                        <owl-carousel-o [options]="blogOptions" class="customOwl">
                                            <ng-template carouselSlide *ngFor="let course of  courselist?.value;  ">
                                                <div class="col-md-12 float-left">
                                                    <div class="card pgm-card m-0">
                                                        <div class="card-image" routerLink="/blog/{{course?.slug}}">
                                                            <img src="{{course?.image}}" alt="{{course?.image_alt}}" class="card-img-top">
                                                            <span class="badge badge-new list font-demi badge-card">{{course?.category}}</span>

                                                        </div>
                                                        <div class="card-body blg-body pgm-body" style="height:auto; padding: 10px 0; position:initial;">
                                                            <div style="cursor: pointer;"><span routerLink="/blog/{{course?.slug}}">{{course?.name}}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </owl-carousel-o>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- <div class="row none767" >
            <div class="col-md-12 blg-cols">
                <div  style="margin:0px;">
                    <div class="container-fluid ">
                        <div class="container-delivery">
                            <span class="badge badge-new cat font-demi">Interview Questions</span>

                            <hr class="head1" />
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="blg-rw">
                                    <owl-carousel-o [options]="blogOptions">
                                        <ng-template carouselSlide *ngFor="let course of all;  ">
                                            <div class="col-md-12 float-left">
                                                <div class="card pgm-card m-0">
                                                    <div class="card-image" style="position:relative;cursor: pointer;" routerLink="/blog/{{course.slug}}">
                                                        <img src="{{course.image}}" alt="{{course?.image_alt}}"
                                                            class="card-img-top" >
                                                            <span class="badge badge-new list">{{course.category}}</span>

                                                    </div>
                                                    <div class="card-body blg-body pgm-body" style="height:auto; padding: 10px 0; position:initial;">
                                                        <div  style="cursor: pointer;"><span routerLink="/blog/{{course.slug}}">{{course.name}}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </ng-template>
                                    </owl-carousel-o>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row none767" >
            <div class="col-md-12 blg-cols">
                <div  style="margin:0px;">
                    <div class="container-fluid ">
                        <div class="container-delivery">
                            <span class="badge badge-dark cat">New in Data Science</span>

                            <hr class="head" >
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="blg-rw">
                                    <owl-carousel-o [options]="termOptions">
                                        <ng-template carouselSlide *ngFor="let course of all;  ">
                                            <div class="col-md-12 float-left">
                                                <div class="card pgm-card m-0">
                                                    <div class="card-image" style="position:relative;cursor: pointer;" routerLink="/blog/{{course.slug}}">
                                                        <img src="{{course.image}}" alt="{{course?.image_alt}}"
                                                            class="card-img-top" >
                                                            <span class="badge badge-dark list">{{course.category}}</span>

                                                    </div>
                                                    <div class="card-body blg-body pgm-body" style="height:auto; padding: 10px 0; position:initial;">
                                                        <div  style="cursor: pointer;"><span routerLink="/blog/{{course.slug}}">{{course.name}}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </ng-template>
                                    </owl-carousel-o>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row none767" >
            <div class="col-md-12 blg-cols">
                <div  style="margin:0px;">
                    <div class="container-fluid ">
                        <div class="container-delivery">
                            <span class="badge badge-new cat font-demi">Others</span>
                            <hr class="head1" >
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="blg-rw">
                                    <owl-carousel-o [options]="blogOptions">
                                        <ng-template carouselSlide *ngFor="let course of all;  ">
                                            <div class="col-md-12 float-left">
                                                <div class="card pgm-card m-0">
                                                    <div class="card-image" style="position:relative;cursor: pointer;" routerLink="/blog/{{course.slug}}">
                                                        <img src="{{course.image}}" alt="{{course?.image_alt}}"
                                                            class="card-img-top" >
                                                            <span class="badge badge-new list">{{course.category}}</span>

                                                    </div>
                                                    <div class="card-body blg-body pgm-body" style="height:auto; padding: 10px 0; position:initial;">
                                                        <div  style="cursor: pointer;"><span routerLink="/blog/{{course.slug}}">{{course.name}}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </ng-template>
                                    </owl-carousel-o>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div> -->
        <div class="row dis767" style="display:none">
            <div class="jumbotron" style="margin:0px;width:100%;padding:2rem 20px">
                <div class="container-fluid ">
                    <div class="container-delivery">
                        <div class="col-md-12">
                            <ngb-accordion class=" card-collrn" #myaccordion="ngbAccordion" closeOthers="true" type="'success'">

                                <ngb-panel *ngFor="let det of list  | keyvalue" class="explore-cat" title="{{det.key}}" id="{{det.value[0].id}}">
                                    <ng-template ngbPanelContent>
                                        <owl-carousel-o [options]="exploreOptions">
                                            <ng-template carouselSlide *ngFor="let coursedet of det.value ">
                                                <div class="card">
                                                    <div class="card-image" style="position:relative;cursor: pointer;" routerLink="/blog/{{coursedet.slug}}">
                                                        <img src="{{coursedet.image}}" class="card-img-top" alt="{{coursedet?.image_alt}}">
                                                    </div>
                                                    <div class="card-body blg-body pgm-body" style="height:auto" routerLink="/blog/{{coursedet.slug}}">

                                                        <h6 class="card-title pgm-tit" style="font-family: 'Inter-Medium';cursor: pointer;"><span><b>{{coursedet.name}}</b></span></h6>
                                                        <div class="col-pheight" routerLink="/blog/{{coursedet.slug}}" style="cursor: pointer;">
                                                            <p class="card-text">{{coursedet.description}}</p>
                                                        </div>
                                                        <div class="row auth-rw">
                                                            <a class="view col-7" routerLink="/blog/{{coursedet.slug}}" style="display: flex;">
                                                                <img [src]="coursedet.author_dp" alt="{{coursedet.author_dp_alt}}"> by {{coursedet.author}}

                                                            </a>
                                                            <a class="view  col-5 dates" routerLink="/blog/{{coursedet.slug}}">{{coursedet.date|date}}</a>
                                                        </div>
                                                    </div>


                                                </div>
                                            </ng-template>
                                        </owl-carousel-o>
                                    </ng-template>
                                </ngb-panel>


                            </ngb-accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="" style="margin:0px;padding:0px">
    <div class="row container-delivery">
        <div class="col-md-12">
            <app-find-career [page]="'our-story'"></app-find-career>
        </div>
    </div>
</div>

<div class="container-delivery">
    <div class="col-fixed">
        <div class="bottom">
            <app-bottom-bar></app-bottom-bar>

        </div>
    </div>
</div>