<div class="row " *ngIf="showModal1">
    <div class="col-md-3 main">
    </div>
</div>
<div [style.display]="showModal1 ? 'block' : 'none'" class="modal apply-mod" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" style="padding:0px">
            <div class="modal-body" style="padding:0px!important">
                <div class="close" (click)="hide()">
                    <fa-icon style="margin:5px;cursor:pointer;color: #000" [icon]="faTimesCircle"></fa-icon>
                </div>
                <div class="row">
                    <div class="col-sm-12" style="padding:0px">
                        <div class="card">
                            <div class="card-body">
                                <div class="col-md-12 box" style="padding-bottom:0px">
                                    <!-- <dynamic-form-builder *ngIf="fields.length" documents="documents" (onSubmit)="submit($event)" #dynamic [fields]="getFields()"></dynamic-form-builder> -->
                                    <form (ngSubmit)="onSubmitForm(this.form.value)" *ngIf="showModal1" [formGroup]="form" class="form-horizontal">
                                        <div *ngFor="let field of fields">
                                            <field-builder [field]="field" [submitted]="submitted" [form]="form"></field-builder>
                                        </div>
                                        <div *ngFor="let document of documents ;let i = index" class="sol-inpts">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <span class="file">{{document.name}}</span>
                                                </div>
                                                <div class="col-md-4">
                                                    <span class="files btn start" (click)="UploadInput.click()"> Upload File</span>
                                                    <input type="file"  accept=".doc,.docx,.pdf,image/png, image/jpeg" class="custom-file-input" id="customFile1" name="uploadedcv" (change)="onSelect($event,document.id,i)" #UploadInput value="">
                                                    <!-- <span style="color:red;display:block" *ngIf="list[i]===undefined||list.length===0">
                              {{this.err}}  -->
                            <!-- </span> -->
                                                </div>
                                                <div class="col-md-3" style="overflow: hidden;">
                                                    {{list[i]?.file.name}} 
                                                    <fa-icon *ngIf="list[i]?.file.name" (click)="removeDoc(i)" style="margin:5px;cursor:pointer;color: #000" [icon]="faTimesCircle"></fa-icon>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-md-12" style="padding:0px 0px 5px">
                                                <button [disabled]='check_submit' *ngIf="fields.length" type="submit" class="btn upload float-right ">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="float-right">
                                        <button [disabled]='check_submit' *ngIf="!fields.length" class="submit btn upload" (click)="onSubmit([],false)">Submit </button>
                                    </div>
                                    <!-- <form #enrollForm="ngForm" class="sub-form" >                          
                <div>
                 
                </div>
                </form> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>