import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(private api: ApiService) { }

  getHomeAll(): any {
    const data = this.api.get('api/v1/scroll/all');
    return data;
  }
  getFlashNews(): any {
    return this.api.get('api/v1/news/flash');

  }
  getStoriesAll(): any {
    const data = this.api.get('api/v1/story/all');
    return data;
  }
  getSuccessStory(id): any {
    const data = this.api.get('api/v1/story/get?id='+id);
    return data;
  }
  getBlogAll(): any {
    const data = this.api.get('api/v1/blog/all');
    return data;
  }
  getBlogDetail(id): any {
    console.log("id",id)
    return this.api.get('api/v1/blog/'+id);
   
  }
  blogenquiry(data){
    return this.api.post('api/v1/blogenquiry/add',data);   

  }
  getScholarshipAll(): any {
    const data = this.api.get('api/v1/scholarship/all');
    return data;
  }
  getNewsAll(): any {
    const data = this.api.get('api/v1/news/all');
    return data;
  }
  getStoryDetails(id):any {
    return this.api.get('api/v1/story/get?id='+id);
  }
  getCareerList=()=>{
    return this.api.get('api/v1/career/list');
  }
  getCareerDetails=(id)=>{
    return this.api.post('api/v1/career/detail',id);
  }
  getCountries(){
    return this.api.get('api/v1/country/list');
  }
  getHiringPartners(){
    return this.api.get('api/v1/partner/list')
  }
  getInvoice(id): any {
    console.log("invoice",id)
    return this.api.get('api/v1/order/showInvoice/'+id);    
 }
getNotifications(status):any{
  return this.api.get('api/v1/notification/all/'+status);
}
updateNotifications():any{
  return this.api.get('api/v1/notification/update_status');
}
bannerNotifications():any{
  return this.api.get('api/v1/banner_notification/all');

}
getSeo(route){
  return this.api.get('api/v1/seo/'+route);
}
getFinancePartners(){
  return this.api.get('api/v1/financepartner/all');
}
getInstructors(){
  return this.api.get('api/v1/instructor/all');
}
getQuickGuidance(){
  return this.api.get('api/v1/quickguidance/all');
}
whatWeOffer(){
  return this.api.get('api/v1/whatweoffer/all');
}
 
 
}
