<div class="career">
  <div class="row" *ngIf="page == 'home'">
    <div class="col-lg-7 col-md 6 text career-cn">
      <h3>Got more queries? Speak to our advisor</h3>
    </div>
    <div
      class="col-lg-5 col-md-6 explore"      
    >
      <a class="btn join" routerLink="/book-call">CONTACT NOW</a>
    </div>
  </div>
  <div class="row" *ngIf="page === 'programs-details'; else other">
    <app-course-apply (childEvent)="reload($event)" #apply></app-course-apply>
    <div class="col-lg-7 col-md 6 text">
      <h3>I'm interested in this program</h3>
    </div>
    <div class="col-lg-5 col-md-6 explore">
      <a
        class="btn join"
        *ngIf="
          userService.isLoggedIn() &&
          !details?.is_applied &&
          (details?.documents.length || details?.formfields.length)
        "
        (click)="
          apply.show(
            details.documents,
            details.formfields,
            details?.encrypted_id
          )
        "
        >Apply Now</a
      >
      <a
        class="btn join"
        *ngIf="
          userService.isLoggedIn() &&
          !details?.is_applied &&
          !details?.formfields.length &&
          !details?.documents.length
        "
        (click)="goToCheckout(details)"
        >Apply Now</a
      >
      <a
        class="btn join"
        *ngIf="userService.isLoggedIn() && details?.is_applied"
        disabled
        >Applied</a
      >
      <a
        class="btn join"
        *ngIf="!userService.isLoggedIn()"
        (click)="openModal()"
        >Apply Now</a
      >
    </div>
  </div>
  <ng-template #other>
    <div class="row" *ngIf="page != 'programs-details' && page != 'home'">
      <div class="col-lg-7 col-md 6 text">
        <h5>READY TO TRANSFORM YOUR CAREER?</h5>
        <h3>Find the best career for you</h3>
      </div>
      <div
        class="col-lg-5 col-md-6 explore"
        *ngIf="page === 'programs'; else elseBlock"
      >
        <a class="btn join" routerLink="/careers">Go to Careers</a>
      </div>
      <ng-template #elseBlock>
        <div class="col-lg-5 col-md-6 explore">
          <a class="btn join" routerLink="/programs">Explore Our Programs</a>
        </div>
      </ng-template>
    </div>
  </ng-template>

  <h5></h5>
</div>
