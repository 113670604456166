import { Component, EventEmitter,  HostListener,  Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent implements OnInit {
  @Output() scrollingFinished = new EventEmitter<void>();
  @Input() dues: Array<string> = [];

  constructor() {}

  onScrollingFinished() {
    this.scrollingFinished.emit();
  }
  ngOnInit(){

  }
 
    

}
