import { Component, OnInit, ViewChild } from '@angular/core';

import { NgbAccordion, NgbCarouselConfig ,NgbModal,NgbNav,NgbPanelChangeEvent,NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
import {faRupeeSign,faUsers,faArrowRight, faNewspaper, faCalendarTimes, faCalendarWeek, faCalendarCheck, faCalendarDay,faChevronRight } from '@fortawesome/free-solid-svg-icons';
import{faClock,faCalendarAlt} from '@fortawesome/free-regular-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from 'src/app/core/services/master/master.service';
import * as _ from 'lodash';
import { CourseService } from 'src/app/core/services/course/course.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { identity } from 'lodash';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FaStackItemSizeDirective } from '@fortawesome/angular-fontawesome';
import { LoginComponent } from '../login/login.component';
import { DomSanitizer } from '@angular/platform-browser';
import { PaginationInstance } from 'ngx-pagination';

declare var bootbox:any;

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss'],
  providers: [NgbCarouselConfig,NgbTabsetConfig] 
})
export class ProgramsComponent implements OnInit {
  public config: PaginationInstance = {
    id: "custom",
    itemsPerPage: 9,
    currentPage: 1,
  };
  faRupeeSign = faRupeeSign;
  faArrowRight = faArrowRight;
  faClock = faClock;
  faUsers= faUsers;
  faChevronRight= faChevronRight;
  faCalendarAlt = faCalendarAlt;
  page :String ='program-list';
  longTerm:any;
  formattedLongTerm: any=[];
  all:any =[];
  slug:any;
  active='1';
  termOptions: OwlOptions = {
		loop: true,
		autoplay:true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		navSpeed: 700,
		navText: ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
		responsive: {
		  0: {
			items: 1 
		  },
		  400: {
			items: 2
		  },
		  740: {
			items: 3
		  },
		  940: {
      items: this.all.length<3?this.all.length:3,
      loop:false
		  }
		},
		nav: false
	  }
    exploreOptions: OwlOptions = {
      loop: true,
      autoplay:true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      navSpeed: 700,
      navText: ['', ''],
      items:1,
      responsive: {
        0: {
          items: 1
          },		
        400: {
        items: 1
        },
      
      },
      nav: false
      }
  constructor(public router: Router,config: NgbCarouselConfig,configure: NgbTabsetConfig,
    private masterService:MasterService,public courseService:CourseService,private _activatedRoute:ActivatedRoute,
    public userService:UserService,public modalService:NgbModal,public sanitizer: DomSanitizer,
    ) {
      configure.justify = 'center';
      configure.type = 'pills';	
    
   }
   getCourse(course){
     console.log("clicked",course)
     this.config = {
      id: "basicPaginate",
      itemsPerPage: 9,
      currentPage: 1,
      totalItems: course.length,
    };
   }
   getAll(){
    console.log("clicked")
    this.config = {
     id: "basicPaginate",
     itemsPerPage: 9,
     currentPage: 1,
     totalItems: this.all.length,
   };
  }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(params => {
      console.log("params",params);      
      this.slug = params.id
    })  
    this.getLongTermCourses();
  }
  
  ngAfterViewInit() {
    console.log("this,slug",this.slug)
    if(this.slug)
    this.active = this.slug;
    console.log("this,slug-------",this.slug,this.active)

    // this.tabset.activeId ='information-technology';
    // console.log("ngAfterView",this.tabset.activeId);
    // this.tabset.select('information-technology');
  }
  reload($event){
    this.getLongTermCourses();
  }

 getLongTermCourses(){
		this.courseService.getHomeLongTermCourses().subscribe(async (resp: any) =>{
      this.all = resp.data;  
    
      // for(let ele of this.all){
				
			// 	ele.duration = this.courseService.getWeken(ele.start_date,ele.end_date);
    if (this.slug) {
      let course = this.all.filter(a=>{
         return a.category_slug===this.slug
      })
      this.config = {
        id: "basicPaginate",
        itemsPerPage: 9,
        currentPage: 1,
        totalItems: course.length,
      };
			};
      console.log("this.all",this.all)
       let list_courses = this.all.filter(data=>{
        if(data.category_status===1)
        return data.category;    
      })

   
        this.longTerm = _.groupBy(list_courses, function (course) {
          if(course.category_status===1)
            return course.category;            
        });
     
        for (const key in this.longTerm) {

          if(key===this.longTerm[key][0].category){
            this.longTerm[key]['icon']=this.longTerm[key][0].category_icon;
          }
          
        }
        console.log("term",this.longTerm)
		}, (err: any) => {
			console.log(err);
		})
  }
  saveOrder(course){
    this.userService.getUser().subscribe(data=>{
      console.log("user",data)
      this.courseService.saveEnrollDetails({
        course_id:course.id,
        documents:[],
        user_id:data.profile.id,
        fields:[]     
    
      }).subscribe(async (resp: any) =>{
        console.log("res",resp)  
        var dialog = bootbox.dialog({
          message: "Thank you for applying to the course.The enrollment will be completed once the admin verifies your request. You will receive an email confirmation once the admin approves your request.",
          closeButton: true
        }); 
        this.getLongTermCourses();
      }, (err: any) => {
        console.log(err);
      })
    })
  }
  goToCheckout(course){
    console.log("currency",course)
    var _this = this;
    bootbox.confirm({
      message:"<h5>Confirm Payment!</h5><h6>Course Fees:"+course.currency+ ' '+course.price+"</h6>", 
        buttons: {
          confirm: {
            label: 'Submit',
            className: 'success'
          },
            cancel: {            
              className: 'fail'
          }
      },
        callback:function(result){      
        if(result){
          _this.saveOrder(course)
        }  
      }   
    });
  }
  openModal(slug) {
		const modalRef = this.modalService.open(LoginComponent,
		  {
			scrollable: true,
			windowClass: 'myModalClass',
			// keyboard: false,
			// backdrop: 'static'
		  });
	
		let data = {
		  prop1: 'program',
		  prop2: slug,
		  prop3: 'This Can be anything'
		}
	
		modalRef.componentInstance.fromParent = data;
		modalRef.result.then((result) => {
		  console.log(result);
		}, (reason) => {
		});
	  }
    @ViewChild('myaccordion', {static : true}) accordion: NgbAccordion;

    beforeChange($event: NgbPanelChangeEvent) {
      console.log($event.panelId);
      // if ($event.panelId === 'panelOne') {
      //   $event.preventDefault();
      // }
  
      // if ($event.panelId === 'panelTwo' && $event.nextState === false) {
      //   $event.preventDefault();
      // }
    }
  
    togglePanel(id){
      this.accordion.toggle(id);
    }  

}


