<!-- <div class="row"> -->
    <div class="card-image">
        <app-banner [page]="page" class="service-page"></app-banner>
    </div>
    <div class="row" style="margin:0px 0px 25px">
        <div class="col-md-12">
            <app-page-details [page]="page"></app-page-details>            
        </div>
    </div>
    <div class="container-delivery serv-col">
    <div *ngFor="let serv of services let i= index;let isOdd=odd;let isEven=even">
        <div class="row item">
            <div class="col-md-6 col-xl-5 dis767" style="display:none">
                <img src="{{serv?.image}}" style="width: 100%;" class="card-img" alt="{{serv?.image_alt}}">
            </div>
            <div class="col-md-6 col-xl-5 none767" *ngIf="isEven">
                <img src="{{serv?.image}}" style="width: 100%;" class="card-img" alt="{{serv?.image_alt}}">
            </div>
            <div class="col-xl-1" *ngIf="isEven"></div>
            <div class="col-md-6 col-xl-6">
                <h4>{{serv.name}}</h4>
                <div class="underline" style="margin-left: 0px!important;"></div>
                <div [innerHTML]="serv?.description"></div>
            </div>
            <div class="col-xl-1" *ngIf="isOdd"></div>
            <div class="col-md-6 col-xl-5 none767" *ngIf="isOdd">
                <img src="{{serv.image}}" style="width: 100%;" class="card-img" alt="{{serv.image_alt}}">
            </div>
        </div>
    </div>
    </div>
    <div class="container-delivery container-fluid">
        <div class="row stand vid-cols">
            <div class="col-lg-5 col-md-12 apartimg">
                    <img [src]="stand_apart?.image" style="width:100%;" alt="{{stand_apart?.image_alt}}">
                    <div class="video">
                    <!-- <vg-player>
                        <video #media [vgMedia]="media" id="singleVideo" src="{{stand_apart?.video}}" preload="auto" controls>
                        
                        
                        </video>
                    </vg-player> -->
                    <!-- <video  width="100%" height="280" class="stand-video" controlsList="nodownload"   autoplay src="{{stand_apart?.video}}" controls (click)="toggleVideo($event)" #videoPlayer>
                        
                        Browser not supported
                    </video> -->
                </div>
                <!-- <video width="350" height="220" class="stand-video" controls>
                    <source src={{stand_apart?.video}} type="video/mp4">Your browser does not support HTML5 video.
            </video> -->
                <!-- <img src="{{stand_apart?.image}}" style="width: 85%;" class="card-img" alt="..."> -->
            </div>
            <div class="col-lg-7 col-md-12 col-vid-cnt"  >
                <h3><b>
                        {{stand_apart?.title}}
                    </b>
                </h3>
                <div class="underline" style="margin-left: 0px!important;display:none"></div>
                <div [innerHTML]="stand_apart?.description"></div>
                <p class="float-left" style="margin-top: 5px;    color: #e45b0b;">Read More</p> <a routerLink="/how-we-stand-apart">
                    <!-- <i style="margin-left: 5px;font-size: 15px;color: #ffffff;margin: 0px 10px;padding: 10px;background: #e45b0b;border-radius: 100%;" class="fa fa-long-arrow-right">
                    </i> -->
                    <img  src="../../../../assets/images/arrow.png" style="padding-left: 20px;
                    width:75px;margin-top: -4px;" alt="">
                </a>
            </div>   
        </div>
    </div>

    <div class="container-delivery">
        <div class="col-fixed">
            <div class="bottom" >
                <app-bottom-bar></app-bottom-bar>        
            </div>
        </div>
    </div>
    
    <div class="how-colpad apply" style="padding-bottom:0px" >
        <div class="col-md-12 container-delivery">
        <div class="row discuss-section" style="margin:0px"> 
            <div class="col-lg-6 col-md-12" style="margin-top: 25px;">
                <p class="discuss" style="margin-left:0px">Discuss how we can help. </p>           
            </div>
            <div class="col-lg-1 col-md-1"></div>
            <div class="col-lg-5 col-md-12 ">
                <div class="signup">
                    <div class="message" id="successMessage" style="color:green">{{message}}</div> 
                    <div class="card-body">                       
                        <h5><b>  Speak with a Learning Advisor</b>
                          </h5>
                          <p style="font-size:12px">Select a date and time and a learning Advisor will be in touch</p>
                          <form class="form" #contactForm="ngForm" 
                            (ngSubmit)=" onSubmit(contactForm)" novalidate>
                            <div class="row">
                                <div class="col-md-6 ">
                                    <input type="date" class="text name" [(ngModel)]="model.date" name="date1" #date1="ngModel" required>
                                    <span class="text-danger" *ngIf="(date1.touched || contactForm.submitted) && date1.errors?.required">
                                   Date is required
                                    </span>
                                </div>
                                <div class=" col-md-6 ">
                                    <select  [(ngModel)]="model.time" class="text name
                                    " name="time" (change)=onTimeChange($event) >
                                        <option selected disabled  value="" >Select Time </option>
                                        <option  *ngFor="let time of allotted_time" [value]="time" >{{time}}</option>
                                    </select>    
                                    <span class="text-danger" *ngIf="!selectedDate">
                                        {{time_err}}
                                    </span>                          
                                </div> 
                            <div class=" col-md-12 ">
                                <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                                [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                                [phoneValidation]="true" [separateDialCode]="separateDialCode" 
                                [numberFormat]="PhoneNumberFormat.National" name="phone" #phone="ngModel" required   [(ngModel)]="model.phone">
                            </ngx-intl-tel-input>
                                <span class="text-danger" *ngIf="(phone.touched || contactForm.submitted) && phone.errors?.required">
                                Phone is required
                                </span>    
                            </div> 
                            <div class="col-md-6 ">                      
                                <input type="text" class=" text" [(ngModel)]="model.first_name" name="first_name"
                                #first_name="ngModel" placeholder="First Name" required pattern="[a-zA-Z\s]*$"  (change) ="model.first_name = model.first_name.trim()"
                                >
                                <span class="text-danger"
                                *ngIf="(first_name.touched || contactForm.submitted) && first_name.errors?.required">
                                First Name is required
                                </span>
                                <span class="text-danger"
                                *ngIf="(first_name.touched || contactForm.submitted) && first_name.errors?.pattern">
                                Only alphabets are allowed
                            </span>
                            </div>
                            <div class=" col-md-6 ">
                                <input type="text" class=" text"  [(ngModel)]="model.last_name" name="last_name"
                                #last_name="ngModel" placeholder="Second Name" required pattern="[a-zA-Z\s]*$" (change) ="model.last_name = model.last_name.trim()">
                                <span class="text-danger" *ngIf="(last_name.touched || contactForm.submitted) && last_name.errors?.required">
                                Second Name is required
                                </span>
                                <span class="text-danger"
                                *ngIf="(last_name.touched || contactForm.submitted) && last_name.errors?.pattern">
                                Only alphabets are allowed
                            </span>
                            </div>                           
                            <div class=" col-md-12 ">
                                <input type="text" class=" text" [(ngModel)]="model.email" name="email"
                                #email="ngModel" placeholder="Email" required email (change) ="model.email = model.email.trim()">
                                <span class="text-danger" *ngIf="(email.touched || contactForm.submitted) && !email_err && email.errors?.required">
                                Email is required
                                </span>
                                <span class="text-danger" *ngIf="email.touched && email.errors?.email">
                                Enter a valid email address
                                </span>
                                <span class="text-danger" *ngIf="email.touched && email_err">
                                The email has already been taken.
                                </span>
                            </div>                
                            </div>
                          
                            <div>
                                <p class="terms" >By Clicking 'Submit' I confirm that i have reviewed and I agree to Edubex's terms
                                </p>
                                <div>
                                    <input type="submit"  class="btn join"  value="Book a Call">       
                                </div>
                            </div>
                            </form>
                        <!-- <app-join-network [page]="page" (message)="messageHandler($event)"></app-join-network>              -->
                    </div>
                </div>           
            </div>
        </div>  
        </div>       
    </div>
    
    <div class="how-colpad apply" style="padding-bottom:0px;background: #34495e;" >
        <div class="col-md-12 container-delivery">
  
            <div class="change" style="margin-top:0px">
                <app-find-career></app-find-career>
            </div>  
            </div>       
        </div>
