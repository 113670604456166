import { Component, OnInit,HostListener, ViewChild, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { faAngleDown,faChevronDown, faChevronUp,faBell } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import { Routes, RouterLink, Router, ActivatedRoute } from "@angular/router";
import { UserService } from '../services/user/user.service';
import { LoginComponent } from 'src/app/modules/pages/login/login.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegisterComponent } from 'src/app/modules/pages/register/register.component';
import { CourseService } from '../services/course/course.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilService } from '../services/util/util.service';
import { MasterService } from '../services/master/master.service';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  entryComponents:[
    LoginComponent
  ], 
  host: {
    "(window:click)": "onClick()"
  }
})

export class NavComponent implements OnInit {
@ViewChild('toggleButton') toggleButton: ElementRef;
@ViewChild('menu') menu: ElementRef;
@ViewChild('toggleEvent') toggleEvent: ElementRef;
@ViewChild('eventmenu') eventmenu: ElementRef;
@ViewChild('toggleProgram') toggleProgram: ElementRef;
@ViewChild('programmenu') programmenu: ElementRef;
@ViewChild('successmenu') successmenu: ElementRef;
@ViewChild('toggleSuccess') toggleSuccess: ElementRef;
@ViewChild('usermenu') usermenu: ElementRef;
@ViewChild('moremenu') moremenu: ElementRef;
@ViewChild('toggleMore') toggleMore: ElementRef;
@ViewChild('toggleUser') toggleUser: ElementRef;
@ViewChild('modal') public loginComponent: LoginComponent

event = false;
program = false;
success = false;
more = false;
show_user=false;
user ;
user_details;
user_click =false;
showModal = false;
page="nav";
all:any=[];
longTerm:any=[];
cat:any


notifications:any=[];
scheduled_courses:any;
// showToggle= false;

  faAngleDown = faAngleDown;
  faChevronUp= faChevronUp;
  faChevronDown = faChevronDown;
  faBell = faBell;
  enterprise =false;
  constructor( public router: Router, public utilService:UtilService,
    private masterService:MasterService,
    private modalService: NgbModal,public sanitizer: DomSanitizer,
    private renderer: Renderer2 , public userService:UserService, public courseService:CourseService ) {
    this.renderer.listen('window', 'click',(e:Event)=>{ 
      if(this.toggleButton!== undefined){      
        if(// (!this.toggleEvent.nativeElement.contains(e.target) && this.eventmenu && !this.eventmenu.nativeElement.contains(e.target))||
        (!this.toggleButton.nativeElement.contains(e.target) && this.menu && !this.menu.nativeElement.contains(e.target))||
        (!this.toggleSuccess.nativeElement.contains(e.target) && this.successmenu && !this.successmenu.nativeElement.contains(e.target))||
        // (!this.toggleProgram.nativeElement.contains(e.target) && this.programmenu &&!this.programmenu.nativeElement.contains(e.target))||
        (!this.toggleMore.nativeElement.contains(e.target) && this.moremenu &&!this.moremenu.nativeElement.contains(e.target))  ) {

          //(!this.toggleUser.nativeElement.contains(e.target) && this.usermenu &&!this.usermenu.nativeElement.contains(e.target))  ) {
          // this.program=false;
          this.event=false;
          this.enterprise=false;
          this.success = false; 
          this.more = false;        
        } 
        if(this.userService.isLoggedIn() &&!this.toggleUser.nativeElement.contains(e.target) && this.usermenu &&!this.usermenu.nativeElement.contains(e.target))  {
          console.log("entered",e.target)
          this.user_click = false;
        }
      }
    });
   }
   openBookcall(){
    this.router.navigateByUrl('/book-call');
    // this.router.navigate(['/calendly']);

    if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle
    // window.location.href ="/book-call";

  }
  public get height() {
    return window.innerHeight;
  }
  onClick() {
  this.courseService.showToggle= false;
  
  }
  goCollapse(e){
   
    e.stopPropagation();
    this.courseService.showToggle= ! this.courseService.showToggle;
    this.event = false;
    this.enterprise =  false;
    this.program= false;
    this.user_click =false;
    this.success = false;
    this.more = false;

    console.log("enteredd",this.courseService.showToggle)
  }

  public get width() {
    return window.innerWidth;
  }
  ngOnInit(): void {
    this.user_click = false;
    this.getUser();
    this.getLongTermCourses();
    this.getSchedule();
    this.userService.getEmitter().subscribe((customObject) => {  
      console.log("hei")
      this.user_click = false;   
    this.getUser();
    });
 
    console.log("width",this.width);
  }
  getCategory(cat){
    this.cat = cat;
  }
  unsorted(){}
  getLongTermCourses(){
		this.courseService.getUpcomingCourses(0).subscribe(async (resp: any) =>{
      this.all = resp.data;  
      if(this.all){
        let list_courses = this.all.filter(data=>{
          if(data.category_status===1)
          return data.category;    
        })

          this.longTerm = _.groupBy(list_courses, function (course) {
            if(course.category_status===1)
              return course.category;            
          }); 
         
          let trending=list_courses.filter(a=>a.trending==="1")
          this.longTerm=Object.assign({'Trending Courses':trending}, this.longTerm);
 
          console.log("this.all",list_courses,this.longTerm)

          for (const key in this.longTerm) {
            if(key===this.longTerm[key][0].category){
              this.longTerm[key]['icon']=this.longTerm[key][0].category_icon
            }
            
          }
          // this.cat =Object.keys(this.longTerm)[0]; 
          this.cat ='Trending Courses'
          console.log("term",this.longTerm)
      }
    
		}, (err: any) => {
			console.log(err);
		})
  }
  getSchedule(){
    this.courseService.getSchedule({}).subscribe(async (resp: any) =>{
      this.scheduled_courses = resp.data;    
        console.log("scheduled",this.scheduled_courses)
		}, (err: any) => {
			console.log(err);
		})
  }

  goToProgramDetails(program){
    console.log("program",program)
    this.router.navigate(['/program-details/'+program]);
    this.program= false;
    if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle
    // window.location.href ="/program-details/"+program;

  }
  goToScheduledProgramDetails(program){
    console.log("program",program)
    this.router.navigate(['/program-details/'+program]);
    if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle
    // window.location.href ="/program-details/"+program;

    this.event= false;
  }
  goToScheduledPrograms(){
    this.router.navigate(['/schedule']);
    this.event= false;
    this.program = false;
    this.more = false;

    if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle
    // window.location.href ="/schedule";


  }
  goToChange=()=>{
    this.router.navigate(['/change-password']);
    this.user_click =false;
  }
  getUser(){
    this.user =this.userService.isLoggedIn();
    // console.log("user",this.user,this.user_click,customObject);
    if(this.user){
      
      let unread = 1;
      this.masterService.getNotifications(unread).subscribe(not=>{
       
        this.notifications = not.data;
        console.log("notiy",this.notifications,this.notifications.length);
        let unread = 1;
      })
      this.userService.getUser().subscribe(data=>{
        console.log(data)
        this.user_details = data.profile;
      })
    }
  }
  getEnterprise=()=>{
  this.enterprise =  !this.enterprise;
  this.event = false;
  this.program= false;
  this.success = false;
  this.more = false;

  this.user_click =false;
  }
  goToSuccess=()=>{
    this.success =  !this.success;
    this.more = false;
    this.event = false;
    this.enterprise = false;
    this.program= false;
    this.user_click =false;  
  }
  goToMore=()=>{
    this.more =  !this.more;
    this.success = false;
    this.event = false;
    this.enterprise = false;
    this.program= false;
    this.user_click =false;  
  }
  getEvent=()=>{
    this.event = !this.event;
    this.enterprise =  false;
    this.program= false;
    this.user_click =false;
    this.success = false;
    this.more = false;

  }
  getProgram=()=>{
    console.log("click")
    this.event = false;
    this.enterprise =  false;
    this.program= !this.program;
    this.user_click =false;
    this.success = false;
    this.more = false;
    console.log("program",this.program)

  } 
  getPrograms=()=>{
    this.event = false;
    this.enterprise =  false;
    this.program= true;
    this.user_click =false;
    this.success = false;
    this.more = false;

  } 
  closeProgram=()=>{
    this.event = false;
    console.log("enterr--------")
    this.enterprise =  false;
    this.program=false;
    this.user_click =false;
    this.success = false;
    this.more = false;
  }

   goHome=()=>{
     window.location.href ="/";
    //  this.router.navigate(['/'])
   }
   goToPrograms =()=>{
    this.router.navigate(['/programs']);

    this.program= false;
    if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle
    // window.location.href ="/programs";

   } 
   goToHiring =()=> {
    this.enterprise =  false; 
    this.router.navigate(['/hiring-partner']);
    if(this.width<991)   this.courseService.showToggle= ! this.courseService.showToggle
    // window.location.href ="/hiring-partner";

  };
  goToWebinars =()=> {
    this.more =  false; 
    this.program = false;
    this.router.navigate(['/webinars']);    if(this.width<991)   this.courseService.showToggle= ! this.courseService.showToggle
    // window.location.href ="/hiring-partner";

  };
  goToBootcamps=()=> {
    this.more =  false; 
    this.program = false;
    this.router.navigate(['/bootcamps']);    if(this.width<991)   this.courseService.showToggle= ! this.courseService.showToggle
    // window.location.href ="/hiring-partner";

  };
  goToCalendar =()=> {
    this.more =  false; 
    this.router.navigate(['/calendar']);
    if(this.width<991)   this.courseService.showToggle= ! this.courseService.showToggle
    // window.location.href ="/hiring-partner";

  };
  goToBlog =()=> {
    this.more =  false; 
    this.router.navigate(['/blog']);
    if(this.width<991)   this.courseService.showToggle= ! this.courseService.showToggle
    // window.location.href ="/hiring-partner";

  };
  goToRefer =()=> {
    this.more =  false; 
    this.program = false;
    this.router.navigate(['/refer&earn']);
    if(this.width<991)   this.courseService.showToggle= ! this.courseService.showToggle
    // window.location.href ="/hiring-partner";

  };
  goToScholarship =()=> {
    this.more =  false; 
    this.router.navigate(['/scholarship']);
    if(this.width<991)   this.courseService.showToggle= ! this.courseService.showToggle
    // window.location.href ="/hiring-partner";

  };
  goToCorporate =()=> {
    this.enterprise =  false; 
    this.router.navigate(['/corporate-training']);
    if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle
    // window.location.href ="/corporate-training";
  };
  
   goToSchedule =()=>{
     this.event =false;
     this.router.navigate(['/schedule']);
     this.program= false;
     if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle
    //  window.location.href ="/schedule";
    };

    logout(){   
      this.userService.logout().subscribe(res=>{
        console.log("resp",res)
        this.userService.deleteToken();
        this.router.navigateByUrl('/')
      })      
    }
    getNotification(){   
        this.notifications=[];      
        this.router.navigateByUrl('/notifications')           
    }
    openModal() {
      const modalRef = this.modalService.open(RegisterComponent,
        {
          scrollable: true,
          windowClass: 'myCustomModalClass',
          // keyboard: false,
          // backdrop: 'static'
        });
  
      let data = {
        prop1: 'Some Data',
        prop2: 'From Parent Component',
        prop3: 'This Can be anything'
      }
  
      modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
        console.log(result);
      }, (reason) => {
      });
    }
  
   goToProfile=()=>{
    this.user_click =false;
    console.log("url",this.router.url);

    if(this.router.url==='/dashboard')
      window.location.reload();
    else
    this.router.navigate(['/dashboard']);
    
   }

   goToTransaction = ()=>{
    this.user_click =false;
     this.router.navigate(['/transaction_history']);
     if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle
    //  window.location.href ="/transaction_history";

   }
   goToApplied = ()=>{
    this.user_click =false;
     this.router.navigate(['/applied-courses']);
     if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle
      // window.location.href ="/applied-courses";

   }
   goToFree = ()=>{
    this.user_click =false;
     this.router.navigate(['/freecourses/dashboard']);
     if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle
      // window.location.href ="/applied-courses";

   }
   goToAppliedFree =()=>{
    this.user_click =false;
    this.router.navigate(['/applied-freecourses']);
    if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle

   }
   goToApproved = ()=>{
    this.user_click =false;
     this.router.navigate(['/approved-courses']);
     if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle
    //  window.location.href ="/approved-courses";
   }
   getDetails(e){
       e.stopPropagation();
    this.courseService.showToggle= true;
     this.user_click =  !this.user_click;
     console.log("userclick",this.user_click)
   }
   getUserDetails(e){
 this.courseService.showToggle= true;
  this.user_click =  !this.user_click;
  console.log("userclick",this.user_click, this.courseService.showToggle)
}
   
   goToOurStory=()=>{
    this.router.navigate(['/our-story']);
    this.success = false;
    if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle
    // window.location.href ="/our-story";

   } 
   goToServices=()=>{
    this.router.navigate(['/our-services']);
    this.success = false;
    if(this.width<991) this.courseService.showToggle= ! this.courseService.showToggle
    //  window.location.href ="/our-services";
   } 
   goToStandApart=()=>{
    this.router.navigate(['/how-we-stand-apart']);
    this.success = false;
    if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle
    //  window.location.href ="/how-we-stand-apart";
   }
   goToCareers=()=>{
    this.router.navigate(['/careers']);
    this.success = false;
    if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle
    // window.location.href ="/careers";
   }
   goToContact=()=>{
    this.router.navigate(['/contact-us']);
    this.success = false;
    if(this.width<991) this.courseService.showToggle= ! this.courseService.showToggle
    //  window.location.href ="/book-call";
   }
   getInvolved=()=>{
    this.router.navigate(['/become-instructor']);
    this.success = false;
    if(this.width<991) this.courseService.showToggle= ! this.courseService.showToggle
    //  window.location.href ="/become-instructor";
   }
   openLoginModal() {
     this.more = false;
    const modalRef = this.modalService.open(LoginComponent,
      {
        scrollable: true,
        windowClass: 'myModalClass',
        // keyboard: false,
        // backdrop: 'static'
      });

    let data = {
      prop1: 'Some Data',
      prop2: 'From Parent Component',
      prop3: 'This Can be anything'
    }

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
  }
   
    
   

}
