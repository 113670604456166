<div class="row dashboard">
    <div class="container-delivery">
        <div class="personal dashper">
            <div class="text-center">
               <h3>Hi!! {{user_details?.first_name}} {{user_details?.last_name}}</h3> 
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="block text-center">
                        <h4 class="font-demi"> Your Overall Progress</h4>                    
                        <div>Good Start! Long way to go!</div>
                        <div class="row d-flex justify-content-center ">
                            <div class="col-md-6"> 
                                <div class="row" >
                                    <p style="margin:0 auto">
                                        <span> <app-circle-progress [progress]="progress"></app-circle-progress></span>
                                    </p>
                                </div>
                                <br/>                                  
                                <div class="row" *ngIf="completed">{{progress}}</div>   
                                <!-- <div class="progress blue">
                                    <span class="progress-left">
                                        <span class="progress-bar"></span>
                                    </span>
                                    <span class="progress-right">
                                        <span class="progress-bar"></span>
                                    </span>
                                <div class="progress-value">90%</div>
                                </div>-->
                            </div>
                        </div>
                    <h5>Total Earned Credits:{{credit}}</h5>
                    <h3 class="font-demi">Enrollments</h3>
                    <hr>
                    <div class="row" >
                        <!-- <div class="col-4">Paid Courses</div> -->
                        <div class="col-6">Free Courses</div>
                        <div class="col-6">Total Completed</div>
                    </div>
                    <div class="row m-20" >

                        <div class="col-4 d-none"> 
                            <span class="value">
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="value">
                                {{applied}}

                              
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="value">
                                {{free_courses}}
                            </span>
                        </div>
                    </div>
                    <h3 class="font-demi" >Certificates</h3>
                    <hr>
                    <div  *ngIf="freecourse_certificate.length">
                        <div class=" m-20" *ngFor="let certificate of freecourse_certificate"> 
                        
                            <div class="value" style="cursor: pointer;" (click)="open(certificate.certificate_issued)">
                                {{certificate?.freecourse_title}}
                            </div>
                        </div> 
                    </div>
                  
                    <div class=" m-20" *ngIf="!freecourse_certificate.length"></div>
                    No Certificates           
                    </div>
                </div>
                <div class="col-8">
                    <div class="row">
                        <div class="col-6">
                            <div class="block">
                                <h4 class="font-demi">Still Have Trouble</h4>
                                <h3 class="font-demi">Let's Discuss With Advisor</h3>
                                How would you like to be interacted?
                                <div class="row" >
                                    <div class="col-4">
                                        <input id="email" (change)="onChange($event.target.value)"  type="radio" value="1" name="message" [(ngModel)]="contact">
                                        <label for="email"> Email</label>
                                    </div>
                                    <div class="col-4">
                                        <input id="whatsapp" (change)="onChange($event.target.value)" type="radio" value="2" name="message" [(ngModel)]="contact">
                                        <label for="whatsapp"> Whatsapp</label>
                                    </div>
                                    <div class="col-4">
                                        <input id="num" (change)="onChange($event.target.value)"  type="radio" value="3" name="message" [(ngModel)]="contact">
                                        <label for="num"> Phone</label>
                                    </div>
                                </div>
                                <div class="row" *ngIf="contact">
                                    <div class="col-12">
                                        <input id="msg" type="test" class="text" name="txtField" [(ngModel)]="message">
                                        <button type="submit" class="btn join" (click)="sendData()">Submit</button>

                                    </div>
                                </div>
                                <div>                                   
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="block">
                                <h4>{{course_offer.title}}</h4>
                                <hr>
                                <b [innerHTML]="course_offer?.description"></b>
                                <b>Course fee:{{course_offer.fee}}</b>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="block" style="margin-top:20px;" >
                                <div class="container-fluid" >
                                    <div class="row">
                                        <div class="col-md-12">
                                            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                                                <li [ngbNavItem]="1">
                                                    <a ngbNavLink>Free Courses</a>
                                                    <ng-template ngbNavContent>
                                                        <div class="row">
                                                            <div class="col-4"  *ngFor="let course of free_list">
                                                                <div class="block1" (click)="goFreecourse(course)"  *ngIf="course">
                                                                    <div class="title p-10">
                                                                       {{course?.title}} 
                                                                    </div>
                                                                    <div class="content-course">
                                                                        <p class="p-10" [innerHTML]="course.description">
                                                                     
                                                                        </p>
                                                                    </div>
                                                                    
                                                                    <hr>
                                                                </div>
                                                                
                                                            </div>
                                                            
                                                        </div>                                                      
                                                    </ng-template>
                                                </li>
                                                <!-- <li [ngbNavItem]="2">
                                                    <a ngbNavLink>Paid Courses</a>
                                                    <ng-template ngbNavContent>
                                                        <div class="row">
                                                            <div class="col-4"  *ngFor="let course of paid_list">
                                                                <div class="block1">
                                                                    <div class="title p-10">
                                                                        {{course?.title}}                                                                    </div>
                                                                        <p class="p-10" [innerHTML]="course?.overview">
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                            <div class="col-4"></div>
                                                            <div class="col-4"></div>
                                                        </div>
                                                    </ng-template>
                                                </li>                                              -->
                                            </ul>
                                            
                                            <div [ngbNavOutlet]="nav" class="mt-2"></div>
                                        </div>
                                    </div>                                  
                                </div>
                            </div>
                        </div>
                    </div>               
                </div>              
            </div>
        </div>
    </div>
</div>