import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CourseService } from 'src/app/core/services/course/course.service';
import { LoginComponent } from '../login/login.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BootcampEnrollmentComponent } from '../bootcamp-enrollment/bootcamp-enrollment.component';
import { UserService } from 'src/app/core/services/user/user.service';
declare var bootbox


@Component({
  selector: 'app-bootcamp-detail',
  templateUrl: './bootcamp-detail.component.html',
  styleUrls: ['./bootcamp-detail.component.scss']
})
export class BootcampDetailComponent implements OnInit {

	slug;
bootcamp:any;
others:any=[];
baseUrl:any='';
email:any;
  termOptions: OwlOptions = {
		loop: false,
		autoplay:true,
		autoWidth:true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		navSpeed: 700,
		navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
		responsive: {
		0: {
			items: 1
		},
		400: {
			items:1
			// items:this.scheduled_courses.length<3?this.scheduled_courses.length:5
		},
		740: {
			items: 4
			// items: this.scheduled_courses.length<3?this.scheduled_courses.length:5,
		},
		940: {
			items: 4,
			loop:false
		}
		},
		nav: true
	  }
	  pathOptions: OwlOptions = {
		loop: false,
		autoplay:true,
		autoWidth:true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		navSpeed: 700,
		navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
		responsive: {
		0: {
			items: 1
		},
		400: {
			items:1
			// items:this.scheduled_courses.length<3?this.scheduled_courses.length:5
		},
		740: {
			items: 6
			// items: this.scheduled_courses.length<3?this.scheduled_courses.length:5,
		},
		940: {
			items: 6,
			loop:false
		}
		},
		nav: true
	  }
	  speakerOptions: OwlOptions = {
		loop: false,
		autoplay:true,
		autoWidth:true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		navSpeed: 700,
		navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
		responsive: {
		0: {
			items: 1
		},
		400: {
			items:1
			// items:this.scheduled_courses.length<3?this.scheduled_courses.length:5
		},
		740: {
			items: 1
			// items: this.scheduled_courses.length<3?this.scheduled_courses.length:5,
		},
		940: {
			items: 1,
			loop:false
		}
		},
		nav: true
	  }
  constructor( public router:Router,private _activatedRoute:ActivatedRoute,public userService:UserService, private courseService:CourseService,private modalService:NgbModal) {
	this.baseUrl=this.router['location']._platformLocation.location.origin;

   }

  ngOnInit(): void {
	this._activatedRoute.params.subscribe(params => {
		console.log("params--------------------------------",params);      
		this.slug = params.id
		this.courseService.getBootcampDetails({slug:this.slug}).subscribe((res) => {
			console.log('res', res);
			this.bootcamp=res.bootcamp;
			// this.bootcamp['date']=new Date(this.bootcamp.date)
			// this.allCategories = res.Dcategories;
			
	  
			});
			this.courseService.getBootcamps().subscribe((res) => {
				console.log('res', res);
				// this.allCategories = res.categories;
				let courses = res.bootcamp;
				this.others=courses.filter(course=>course.slug!==this.slug)
			});
		
	  })   
  }

  openModal(slug) {
    const modalRef = this.modalService.open(LoginComponent,
      {
      scrollable: true,
      windowClass: 'myModalClass',
      // keyboard: false,
      // backdrop: 'static'
      });
  
    let data = {
      prop1: 'bootcamp-details',
      prop2: slug,
      prop3: 'This Can be anything'
    }
  
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
    }
	openApplyModal(course) {
		const modalRef = this.modalService.open(BootcampEnrollmentComponent,
		  {
			scrollable: true,
			// windowClass: 'myCustomModalClass',
			// keyboard: false,
			// backdrop: 'static'
		  });
	
		let data = {
		  prop1: 'bootcamp-details',
		  prop2: course.slug,
		  course: course,
		}
	
		modalRef.componentInstance.fromParent = data;
		modalRef.result.then((result) => {
		}, (reason) => {
		});
	  }

	  sendEmail(form){
		  let that =this;
		bootbox.prompt({
		title: "Enter Email id",
        message:'Please enter email!',
        centerVertical: true,
        callback: function(result){
          if(result){
            console.log(result,form,that.bootcamp);
			let details={
				bootcamp_id:that.bootcamp.id,
				email:result
			};
            that.courseService.bootcampFollow(details).subscribe(
              res => {
                console.log("otpppppp",res)
				bootbox.dialog({ message:res.message})

                
	  })
	}
}
	})
}

   // Facebook share won't work if your shareUrl is localhost:port/abc, it should be genuine deployed url 
   shareOnFacebook(shareUrl: string) {
	shareUrl = encodeURIComponent(shareUrl);
	window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, 'sharer');
  }


  shareOnTwitter(shareUrl: string) {
	shareUrl = encodeURIComponent(shareUrl);
	// desc = encodeURIComponent(desc);
	window.open(`https://twitter.com/intent/tweet?url=${shareUrl}`, 'sharer');
  }

  shareOnInstagram(shareUrl: string) {
	shareUrl = encodeURIComponent(shareUrl);      
	window.open(`https://www.instagram.com/?url=${shareUrl}`, 'sharer');    
	// window.open(`https://plus.google.com/share?url=${shareUrl}`, 'sharer');
	// window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`, 'sharer');     

  }
  shareOnLinkedin(shareUrl: string) {
	shareUrl = encodeURIComponent(shareUrl);
	// window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`, 'sharer');     
	window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`, 'sharer');     

  }

}

