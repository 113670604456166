<div class="container-fluid">
    <div class="container">
        <div class="banner row">
            <div class="col-12">
                <h2>Explore insightful old webinars</h2>
                <div class="heading">{{webinar?.title}}</div> 
                <p>Recorded in, {{webinar?.date|date}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="addm-col">
                    <p [innerHTML]="webinar?.description"></p>
                   
                    
                </div>
                <h4>Agenda for the sessions</h4>
                <div [innerHTML]="webinar?.agenda">
                    <!-- <ul>
                        <li>Understanding the data analaytic domain</li>
                        <li>Understanding the data analaytic domain</li>
                        <li>Understanding the data analaytic domain</li>
            
                    </ul> -->
                </div>
            </div>
            <div class="col-6">
                <div class="card-sec " style="padding:20px">
                    <div class="card-body" style="border-radius: 6px;padding-top: 0px;">
                        <form class="form" #courseForm="ngForm" (ngSubmit)="courseForm.form.valid && onSubmit(courseForm)" novalidate>
                            <h4 class="card-title"><b>Fill the details to unlock the video</b></h4>
                            <div class="row">
                                <div class="col-md-12 ">
                                    <input type="text" class=" text" [(ngModel)]="model.first_name" name="first_name" #first_name="ngModel" placeholder="First Name" pattern="[a-zA-Z\s]*$" required (change)="model.first_name = model.first_name.trim()">
                                    <span class="text-danger" *ngIf="(first_name.touched || courseForm.submitted) && first_name.errors?.required">
                                    First Name is required
                                </span>
                                    <span class="text-danger" *ngIf="(first_name.touched || courseForm.submitted) && first_name.errors?.pattern">
                                Only Alphabets are allowed
                            </span>
                                </div>
                                <div class=" col-md-12 ">
                                    <input type="text" class=" text" [(ngModel)]="model.last_name" name="last_name" #last_name="ngModel" placeholder="Second Name" pattern="[a-zA-Z\s]*$" required (change)="model.last_name = model.last_name.trim()">
                                    <span class="text-danger" *ngIf="(last_name.touched || courseForm.submitted) && last_name.errors?.required">
                                    Second Name is required
                                </span>
                                    <span class="text-danger" *ngIf="(last_name.touched || courseForm.submitted) && last_name.errors?.pattern">
                                Only Alphabets are allowed
                            </span>
                                </div>
                                <div class=" col-md-12 ">
                                    <input type="text" class=" text name" [(ngModel)]="model.email" name="email" #email="ngModel" placeholder="Email" required email (change)="model.email = model.email.trim()">
                                    <span class="text-danger" *ngIf="(email.touched || courseForm.submitted) && email.errors?.required">
                                    Email is required
                                </span>
                                    <span class="text-danger" *ngIf="email.touched && email.errors?.email">
                                    Enter a valid email address
                                </span>
                                </div>
                                <div class=" col-md-12 " style="margin: 2px 0px;">
                                    <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                        [selectedCountryISO]="CountryISO.India" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National" name="phone" #phone="ngModel" required [(ngModel)]="model.phone">
                                    </ngx-intl-tel-input>
                                    <!-- <input type="text" class=" text"  pattern="^[- +()0-9]+$" [(ngModel)]="model.phone" name="phone" #phone="ngModel"
                                    placeholder="+91" required phone> -->
                                    <span class="text-danger" *ngIf="(phone.touched || courseForm.submitted) && phone.errors?.required">
                                    Phone is required
                                </span>
                                    <!-- <span class="text-danger"
                                *ngIf="(phone.touched || courseForm.submitted) && phone.errors?.pattern">
                                Only Numbers are allowed
                                </span> -->
                                    <span class="text-danger" *ngIf="(phone.touched || courseForm.submitted) &&!phone.errors?.required &&  phone.errors?.validatePhoneNumber?.valid===false">
                                            Invalid Phone number
                                </span>
                                </div>
                               
                                <div class=" col-md-12 ">
                                    <select class="text" name="exp_year" style="background-color: #fff;" [(ngModel)]="model.exp_year" #exp_year >
                                <option value="" disabled selected> Work Experience</option>
                                <option value="0-1 year">0-1 Year </option>
                                <option value="2 years">2 Years </option>
                                <option value="3 years">3 Years </option>
                                <option value="4 years">4 Years </option>
                                <option value="5 years">5+ Years </option>
                            </select>
                                    <!-- <input type="text" class=" text" [(ngModel)]="model.work_experience" name="work_experience"
                                    #work_experience="ngModel" placeholder=" Work Experience" required work_experience>
                                <span class="text-danger"
                                    *ngIf="(work_experience.touched || courseForm.submitted) && work_experience.errors?.required">
                                    Work Experience is required
                                </span> -->
                                </div>
                                <div class="col-md-12">
                                    <select class="text" name="industry" style="background-color: #fff;" [(ngModel)]="model.industries" #industries >
                                        <option value="" disabled selected> Select Industry<option>
                                        <option  *ngFor="let industry of webinar.industries" [value]="industry.id">{{industry.title}} </option>
                                       
                                    </select>
                                </div>
                            </div>
                            <div>
                                <p >By submitting the form, you agree to our Terms and Conditions
                                    and our Privacy Policy.
                                </p>
                                <div>
                                    <input type="submit" class="btn download" value="WATCH NOW">
                                    <!-- <a target="_blank" href="{{pdf}}" download="file_new_name.pdf">Download Brochure</a> -->
                
                                    <!--  <a href="#" class="btn download"></a>  -->
                                </div>
                               
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
     
     
        
          
    
       
            <h3 class="text-center learn">About Speakers</h3>
            <div class="underline"></div>
            <owl-carousel-o [options]="termOptions" class="customOwl">
                <ng-template carouselSlide *ngFor="let speaker of webinar?.speakers">
                    <div class="col-md-12 float-left ex-card">
                        <div class="card ">
                            <div class="row detail no-gutters">
                                <div class="col-sm-2" >
                                    <img [src]="speaker?.image" alt="">
                                    <!-- <div class="col-exp-img exp-img" style="background: url('');">
                                    
                                    </div> -->
                                </div>
                                <div class="col-sm-10">
                                    <div class="detail">
                                       <div><b>{{speaker?.name}}</b></div>
                                       <div>{{speaker?.designation.name}}</div>
                                       <div>{{speaker?.university.name}}</div>
                                       <div>
                                        <a target="_blank" href="{{speaker?.linkedin}}"><img  src="../../../../assets/images/linkedin.png" alt="" class="linkedin-icon"></a>

                                       </div>

                                    </div>
                                </div>
                            </div>
                         
                        </div>
                        <!-- <p [innerHTML]="short_desc"></p>
                        <div class="more" (click)="getLess()" *ngIf="short_desc===webinar?.description">Read Less </div>  -->
                        <div class="desc">
                            <span class="float-left" [innerHTML]="speaker?.desc">  </span><span class="float-right view"  *ngIf="!speaker.setDesc" (click)="viewMore(speaker)">View More..</span>
                            <span class="float-right view"  *ngIf="speaker.setDesc" (click)="viewLess(speaker)">View Less..</span>
                        </div>
                       
                    </div>
                </ng-template>
            </owl-carousel-o>
    </div>
</div>

